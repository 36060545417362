.cardContainer{
    height:80px
}

@media screen and (min-width: 600px) and (max-width: 999px) {
    .cardOuterSpacing {
        width: 49%;
    }
}

@media screen and (max-width: 599px) {
    .cardOuterSpacing {
        width: 100%;
    }

    .contentShortcutHeader-mobleView {
        background: #F2FBFF 0% 0% no-repeat padding-box;
        /* border: 1px solid #0074B0; */
        border: 1px solid #1DA1E5;
        border-radius: 7px;
        opacity: 1;
        /* padding: 1.5% 1% 1.5% 0%; */
        text-align: left;
        color: #333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: 1%;
    }

    .headerRightPart-mobileView {
        display: flex;
        float: right;
        cursor: pointer;
        border: 1px solid #1DA1E5;
        background: #fff;
        padding: 2px;
    }

    .viewMoreContainer-mobile {
        display: flex;
        justify-content: center;
    }

    .iMedicalInsightsReports {
        display: block !important;
        width: auto !important;
    }

    .cs-imir-modalList {
        width: auto !important;
    }

    .cs-fieldset-wrapper {
        width: auto !important;
        margin: 4% 1% !important;
    }

    .cs-fldep {
        padding: 0 10px !important;
    }

    .cs-preference-lists {
        padding: 0;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1229px) {
    .cardContainer {
        height: 110px;
    }

    .cs-tooltip-bottom {
        top: -47px;
    }

    .cs-tooltip-uparrow {
        top: -60px;
    }
}

@media screen and (min-width: 600px) and (max-width: 750px) {
    .cardContainer {
        height: 100px;
    }

    .cs-tooltip-bottom {
        top: -47px;
    }

    .cs-tooltip-uparrow {
        top: -60px;
    }
}

@media screen and (max-width: 350px) {
    .cardContainer {
        height: 100px;
    }

    .cs-tooltip-bottom {
        top: -47px;
    }

    .cs-tooltip-uparrow {
        top: -60px;
    }
}

@media screen and (min-width: 650px)and (max-width: 750px) {
    .headerRightPart {
        width: 25%;
    }

    .headerLeftPart {
        font-size: 18px;
    }
}

@media screen and (min-width: 500px)and (max-width: 650px) {
    .headerRightPart {
        width: 27%;
    }

    .headerLeftPart {
        font-size: 17px;
    }
}

@media screen and (max-width: 500px) {
    .headerRightPart {
        width: 30%;
    }

    .headerLeftPart {
        font-size: 15px;
    }
}

.cskp-wrapper {
    margin: 1% 2%;
    font-family: 'J&J CircularTT-Medium';
}

.cskp-heading {
    text-align: left;
}

.csep-container {
    margin: 1% 0%;
    padding: 1% 0%;
}

.csep-content {
    display: block;
    text-align: justify;
}

.csep-protocol-section {
    /* min-height: 250px; */
    min-height: 100px;
    overflow: auto;
}

.ddig-button-container {
    float: right;
}

.cskp-clinical-table {
    width: 225%;
}

.cskp-crs-table {
    min-width: auto;
    width: 100%;
}

.csdp-dataLink {
    background: lightgray;
    padding: 1%;
    text-align: left;
    margin: 2px 0;
    cursor: pointer;
    border-left: 15px solid #0074B0;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}

.cskp-link-container {
    width: 90%;
    display: flex;
    align-items: center;
}

.csep-leftPart {
    float: left;
    width: 50%;
    border-right: 1px solid #0074B0;
}

.csep-phases {
    color: #0074B0;
    text-align: justify;
    padding: 0% 0 0% 5%;
    display: flex;
    align-items: center;
}

.csep-phases-text {
    cursor: pointer;
    display: inline-block;
    /* width: 30%; */
    padding: 1%;
}

.csep-sub-phases {
    list-style: none;
    color: #0074B0;
}

.csep-protocol-text {
    text-align: left;
    /* width: 40%; */
    padding: 0 0 0 10%;
    cursor: pointer;
}

.csep-rightPart {
    float: left;
    width: 50%;
}

.csep-protocols {
    color: #0074B0;
    text-align: justify;
    padding: 1% 0 1% 10%;
    cursor: pointer;
}

.csep-noData-content {
    display: block;
    text-align: justify;
    padding: 0 0 0 10%;
}

.csdp-links {
    display: block;
    margin: 0 0 0 1%;
}

.cskp-dropdown-component {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #9D9D9D4F;
    border: 1px solid #0074B0;
    border-radius: 5px;
    opacity: 1;
    padding: 0.2% 0.5%;
    font-family: 'J&J CircularTT-Medium';
    cursor: pointer;
    /* width: 21%; */
}

.cskp-text {
    display: inline;
    /* margin: 0 6.5px 0 0; */
    color: #0074B0;
    margin: 0 2px 0 0;
}

.cskp-dropdown-wrapper {
    position: absolute;
    color: #333;
    width: 19%;
    z-index: 1;
}

.searchterm-name {
    color: #FF8000;
}

.clinicalStudies-container {
    width: 25%;
}

.dropdown-text {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cskp-lite-links:hover {
    text-decoration: underline;
    color: #0074B0;
}

.cs-input-search-box {
    font-size: 18px;
    font-weight: bold;
    height: 44px;
    background-color: rgba(255, 255, 255, 0.85);
    color: #333;
    width: 735px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 2px solid #d8d8d8 !important;
    font-family: "J&J CircularTT-Medium";
    outline: none;
    padding: 2px 5px;
    width: 100%;
    /* border-radius: 4px 0px 0px 4px; */
    border-radius: 4px;
}

.cs-search-icon-wrapper-webView {
    cursor: pointer;
    background: #0074B0 0% 0% no-repeat padding-box;
    /* border-radius: 0px 4px 4px 0px; */
    opacity: 1;
    padding: 2%;
    /* height: 100%; */
    margin-left: 2%;
    /* width: 40%; */
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    /* width: 20px; */
    /* height: 20px; */
    border-radius: 4px;
    height: inherit;
}

.cs-header-wrapper {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.cs-input-box-wrapper {
    width: 60%;
    position: relative;
}

.cs-icon-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    width: 50px;
    background-color: transparent;
    padding: 0;
    height: 30px;
    background: white;
    margin: 1%;
}

.cs-select-preference-wrapper {
    display: flex;
    margin: 0 0 5% 0;
}

.cs-fieldset-wrapper {
    margin: 0 2%;
    /* width: 40%; */
    text-align: left;
}

.cs-fldep {
    border: 2px solid #0074B0;
    padding: 0 20px 0 0;
}

.eplegend {
    background-color: #0074B0;
    color: #fff;
    padding: 5px 10px;
    font-size: large;
    display: block;
    width: 80%;
    margin: -20px 4% 2% 4%;
}

.cs-preference-lists {
    list-style: none;
}

.cs-preference-lists>li {
    /* width: max-content; */
    width: fit-content;
}

.cs-preference-lists>li>label {
    display: flex;
    cursor: pointer;
}

.cs-preference-lists>li>label>input {
    margin: 0 5px 0 0;
    cursor: pointer;
}

.cs-ep-notes {
    margin: 0 0 5% 0;
}

.iMedicalInsightsReports {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
}

.cs-imir-modalList {
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    font-size: 13px;
    list-style: none;
    width: 45%;
}

.cs-mir-modalListing {
    color: #0074B0;
    line-height: 30px;
    width: 100%;
    word-break: break-word;
    display: flex;
    align-items: center;
}

.cs-list-i-icon {
    margin: 0% 3% 0% 0%;
}

@media screen and (min-width: 600px) and (max-width: 655px) {
    .cs-imir-modalList {
        width: 50%;
    }
}

@media screen and (min-width: 1080px) and (max-width: 1150px) {
    .cs-fieldset-wrapper {
        margin: 0 1%;
    }
}

@media screen and

/* (min-width: 1150px) and */
(max-width: 1080px) {
    .cs-select-preference-wrapper {
        display: flex;
        margin: 0 0 5% 0;
        flex-direction: column;
    }

    .cs-fieldset-wrapper {
        margin: 2%;
        width: 70%;
        text-align: left;
    }
}

@media screen and (min-width: 600px) and (max-width: 999px) {
    .clinicalStudies-container {
        width: 50%;
        position: relative;
    }

    .cskp-dropdown-wrapper {
        width: 100%;
    }

    .dropdown-text {
        justify-content: space-between;
    }

    .cskp-dropdown-component {
        padding: 0.2% 2%;
    }
}

@media screen and (max-width: 599px) {
    .clinicalStudies-container {
        width: 100%;
        position: relative;
    }

    .cskp-dropdown-wrapper {
        width: 100%;
    }

    .dropdown-text {
        justify-content: space-between;
    }

    .cskp-dropdown-component {
        padding: 0.2% 2%;
    }
}