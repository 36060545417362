.tablet-helpContentWrapper h3{
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 22px;
    text-align: left;
    letter-spacing: 0.02px;
    color: #000;
    opacity: 1;
}
.tablet-trainingHeading{
    padding:25px 0 20px 0;
}
.tablet-helpContentWrapper .upperCard{
    padding: 10px 25px 20px 20px;
}
.mobile-helpContentWrapper h3{
    margin-bottom: 10px;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0.02px;
    color: #000;
    opacity: 1;
    font-size: 18px;
}
.mobile-helpContentWrapper .upperCard{
    padding: 10px 25px 20px 20px;
}
.mobile-helpContentList{
    display: flex;
    flex-flow: column wrap;
}
.mobile-helpContentList .linked-items{
    border:1px solid #1DA1E5;
    border-radius:4px;
    padding:10px 5px;
    margin:5px 0 15px 0;
    color: #333;
}
.contactus-lists {
    display: flex;
    align-items: baseline;
}
.listIcon-tablet{
    margin-right: 10px;
    padding-bottom: 2px;
}
.contactHeader-tablet{
    font-size: 22px;
    color: #000;
    text-align: left;
    letter-spacing: 0.02px;
    opacity: 1;
}
.contactContentPart-tablet{
    font-size: 14px;
    opacity: 1;
    margin: 20px 0px 0px 0px;
    color: #030506;
    /* width: 75%; */
}
.contactHeader-mobile{
    font-size: 22px;
    color: #000;
    text-align: left;
    letter-spacing: 0.02px;
    opacity: 1;
    display: inline-block;
    margin-bottom: 2%;
}
@media screen and (max-width: 600px){
    .upperCard p {
        margin: 0px;
    }
}