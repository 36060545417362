.actionItem-headerPart {
    font-family: 'J&J CircularTT-Medium';
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.actionItem-headerPart-single {
    font-family: 'J&J CircularTT-Medium';
    display: flex;
    align-items: center;
}

.gsfModalHeader {
    background: transparent linear-gradient(90deg, rgba(217, 224, 238, 0.22) 0%, rgba(154, 230, 224, 0.22) 100%) 0% 0% no-repeat padding-box;
}

.action-button-saperation-line {
    border-left: 1px solid #707070;
    opacity: 0.44;
    height: 20px;
}

.gsf-popup-inputHeading {
    font-size: 16px;
    color: #333;
}

.gsf-popup-inputContainer {
    margin: 1% 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.gsf-popup-inputPart {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.gsf-popup-radioInput {
    margin: 0 5px 0 0;
}

.gsf-popup-button-wrapper {
    padding: 2%;
    float: right;
    width: 25%;
    display: flex;
    justify-content: space-between;
}

.active-features-button {
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    background: linear-gradient(252deg, #7CC7BF, #61779C);
}

.partition-line {
    opacity: 0.12;
    background-color: #707070;
}

.gsf-dsclmr-link {
    text-decoration: underline;
    cursor: pointer;
    color: #F80000;
}

.gsf-dsclmr-link:hover {
    color: #F80000;
}

.savemodal-outer-wrapper {
    position: fixed;
    /* height: 375vh; */
    /* height: 350vh; */
    /* position: absolute; */
    background: #63666A;
    opacity: 0.78;
    border: 1px solid #707070;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    /* z-index: 1000; */
    padding: 1.2rem;
}

.saveModal-heading {
    font-size: 18px;
}

.saveModal-buttonsContainer {
    padding: 3% 0px;
    display: flex;
    flex-direction: row;
    width: 55%;
    justify-content: space-around;
}

.savePopUp {
    width: 700px;
}

/* input[type="radio"]:disabled{
    cursor: none;
} */
.popup-transition-mob-tab {
    min-width: 90%;
}

.active-features-button-tablet {
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    font-size: 14px;
    background: linear-gradient(252deg, #7CC7BF, #61779C);
}

.gsf-popup-button-wrapper-tablet {
    padding: 4%;
    float: right;
    display: flex;
    justify-content: space-between;
}

.active-features-button-mobile {
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    font-size: 14px;
    background: linear-gradient(252deg, #7CC7BF, #61779C);
    width: 100%;
}

.gsf-popup-inputContainer-mobile {
    margin: 1% 0 0 0;
    margin-bottom: 10px;
}

.email-popup-inputContainer-mobile {
    margin: 0% 2%;
}

.gsf-popup-inputPart-mobile {
    display: flex;
    align-items: center;
    font-size: 15px;
    margin: 0 2%;
    padding: 1% 0;
}

.gsf-popup-button-wrapper-mobile {
    display: flex;
    flex-direction: column;
    padding: 4%;
}

.welcomeNxtBtn-mobile {
    margin: 0 0 15px 0;
}

.downloadZip-wrapper {
    display: flex;
    width: 80%;
    margin-left: 30px;
}

.download-zip-link {
    color: #0074b0;
    cursor: pointer;
}

.download-zip-link:hover {
    text-decoration: underline;
}

.downloadAll-zip-link {
    color: #0074b0;
    cursor: pointer;
    font-size: 15px;
}

.downloadAll-zip-link:hover {
    text-decoration: underline;
}

.fileName-txt {
    text-decoration: underline;
    color: #333;
    display: block;
    margin-right: 1%;
}

.downloadZip-outer-wrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-start;
    justify-content: space-between;
    width: 98%;
}

.saveModalHyperlink {
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width:600px) {
    .savePopUp {
        width: auto;
    }
}