.recently-added-updated-bar-diagram {
    margin: 10px 30px 20px 30px;
    text-align: center;
    width: 300px;
}

.recently-added-updated-slider-content {
    margin: 0px 30px 15px 30px;
    text-align: center;
    width: 300px;
}

.recently-added-updated-slider-content .input-range__slider {
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid #F57F20;
    cursor: pointer;
    display: block;
    height: 1.75rem;
    margin-left: -4px;
    margin-top: -1rem;
    outline: none;
    position: absolute;
    top: 50%;
    touch-action: none;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    box-shadow: 0 0 0 1px white;
    width: 8px;
}

.input-range-container-marker-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* position: relative; */
    margin-top: -20px;
}

.input-range-container-marker {
    width: 10px;
    height: 10px;
    background-color: #F57F20;
    border-radius: 100%;
}
.input-range-container-marker-unselected{
    width: 10px;
    height: 10px;
    background-color: #eeeeee;
    border-radius: 100%;
}
.year-display-container {
    margin-top: 20px;
  }
.slider_extent_label_section
  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .year-display-container > .selected-year-range-display{
    text-align: center;
    display: table;
     margin-left: 0px;
  }