/* .cntryDiv-tablet{
    display: flex;
} */
.regionTxt-tablet {
    padding-left: 10px;
    margin: 0 !important;
    padding-left: 40px !important;
}

.customModal-tablet {
    overflow-y: auto !important;
    /* overflow-y: inherit !important; */
}

.modal-content {
    overflow: visible !important;
}

/* .countrylistpartion { */
/* width: 25%; */
/* float: left; */
/* padding: 0px 8px; */
/* width: 225px; */
/* } */
.countryPartDiv {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.russian-user-heading {
    margin: 0 0 0 15px;
}

@media screen

/* and (min-width: 600px) */
and (max-width: 999px) {

    /* .tab-text-wrap-inner {
        width: 95%;
    } */
    .tab-wrap-inner {
        width: 95%;
    }

    .tab-list-item {
        width: 7%;
    }

    .tab-line-wrapper {
        width: 12%;
    }

    button.tab-list-item.tab-list-visited {
        width: 7%;
    }

    button.tab-list-item.rwt__tab[aria-selected="true"] {
        width: 7%;
    }

    .locationTitle {
        color: #000000;
    }

    .regionContainer {
        margin: 0;
    }

    .locationContent {
        /* padding-bottom: 0px; */
        padding: 0 3.5%;
    }

    .locationTitlePart {
        margin: 0 0 0 2.5%;
    }

    .regionInnerWrapper {
        margin: 0 0 16px 2%;
    }

    .countryWrapper {
        width: 65%;
        margin: 10px 0px 25px 0px;
        /* background: transparent;
        box-shadow: none; */
    }

    /* .countryListWrapper{
        border-left: 1px solid #D9D9D9;
    } */
    .countriesSecondCol {
        margin: 0 0 0 5%;
    }

    .gdprTitle {
        color: #000;
    }

    .gdprAcceptanceContent {
        padding: 0 3.5%;
    }

    .userTrackingListing {
        color: #333;
    }

    .functionTitle {
        color: #000;
    }

    .functionSelectContent {
        padding: 0 3.5%;
    }

    .productSelectContent {
        padding: 0 3.5%;
    }

    .productTitle {
        color: #000;
    }

    .therapeuraticAreaInnerWrapper {
        width: 33%;
        /* width: 43%; */
    }

    .productWrapper {
        /* width: auto; */
        margin-left: 1%;
        /* background: transparent;
        box-shadow: none; */
        padding-top: 1%;
        /* width: 55%; */
        width: 65%;
    }

    /* .productListWrapper{
        max-height: fit-content;
        overflow: auto;
        //border-left: 1px solid #D9D9D9;
        padding-left: 4%;
    } */
    /* .productSelectContent{

    } */
    .product-section {
        /* padding: 0;
        padding-top: 7%; */
        /* padding: 1.5% 0 0 1%; */
        padding: 3% 0 0 1.5%;
    }

    .prd-val {
        border-left: none;
        align-items: flex-start;
        height: 25px;
        padding: 0;
    }

    .sub-productsName {
        margin: 0;
    }

    /* .productText-tablet{
        margin-left: 4%;
        display: inline-block;
    } */
    .welcomeMainHeader {
        color: #000000;
    }

    .welcomeSubHeader {
        color: #000000;
    }

    .documentSelectContent {
        padding: 0 3.5%;
    }

    .review-custom-modal-wrapper {
        min-width: 90%;
        height: 100vh !important;
        /* border-left: 7px solid #1DA1E5; */
    }

    .modal-content {
        overflow: visible !important;
        margin-top: auto;
    }

    .modal-review-user-details {
        padding: 0.5% 10px;
    }

    .review-value-span {
        color: #888B8D;
    }

    .modal-open .modal {
        overflow-y: visible !important;
        /* height: auto; */
    }
}

@media screen and (min-width: 1000px) and (max-width: 1250px) {
    .countryWrapper {
        width: 70%;
    }
}

@media screen and (min-width: 700px) and (max-width: 800px) {
    .countryWrapper {
        width: 60%;
    }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
    .tab-text-wrap-inner {
        /* width: 95%; */
        width: 100%;
    }

    .countryWrapper {
        width: 54%;
    }
}

@media screen and (min-width: 700px) and (max-width: 950px) {
    .tab-text-wrap-inner {
        /* width: 92%; */
        width: 96%;
    }
}

/* @media screen and (min-width: 650px) and (max-width: 720px){
    .therapeuraticAreaInnerWrapper{
        width: 26%;
        float: inherit;
    }
} */
@media screen and (min-width: 950px) and (max-width: 1000px) {
    .tab-text-wrap-inner {
        /* width: 90%; */
        width: 95%;
    }
}

@media screen and (min-width: 800px) and (max-width: 960px) {
    .countrylistpartion {
        width: 225px;
    }
}

@media screen and (min-width: 745px) and (max-width: 899px) {
    .countrylistpartion {
        width: 200px;
    }
}

@media screen and (min-width: 720px) and (max-width: 744px) {
    .countrylistpartion {
        width: 194px;
    }
}

@media screen and (min-width: 600px) and (max-width: 720px) {
    .countrylistpartion {
        width: 100%
    }
}

@media screen and (min-width: 1000px) {
    @media screen and (max-height: 510px) {
        .modal-open .modal {
            overflow-y: visible !important;
        }
    }
}

@media screen and (max-width: 599px) {
    .functionSelectContent {
        padding: 0px 20px 0px 20px;
    }

    .therapeuraticAreaInnerWrapper {
        width: 100%;
    }

    .ta-wrapper-responsive {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .productWrapper {
        width: 100%;
        box-shadow: none;
        min-height: auto;
    }

    .countryWrapper {
        width: 100%;
    }

    .regionContainer {
        display: flex;
        flex-direction: column;
    }

    .cntryDiv-tablet .regionTxt-tablet {
        padding-left: 20px !important;
    }

    .cntryDiv-tablet .k-checkbox {
        margin: 0px 0 0px 6px;
    }

    .regNav-dropdown {
        width: 100%;
        text-align: left;
        list-style: none;
        border-radius: 4px;
        box-shadow: 0px 3px 6px #00000029;
        padding: 2% 0;
        position: absolute;
        background-color: #fff;
        z-index: 1;
        top: 30px;
    }

    .regNavbar {
        width: 100%;
        color: #6CC24A;
        font-weight: bolder;
        position: relative;
    }

    .reg-item-sepration {
        border-color: rgba(112, 112, 112, 0.23);
    }

    .nav-tabs1 {
        border: 1px solid #0074B0;
        opacity: 1;
        cursor: pointer;
        padding-left: 0%;
        padding-right: 0%;
    }

    .regDropdown-text {
        padding: 0 0 0 1.5rem;
        cursor: pointer;
        color: #333;
    }

    .regDropdown-text-active {
        padding: 0 0 0 1.5rem;
        cursor: pointer;
        color: #6CC24A;
        font-weight: 600;
    }
}

/* @media screen and (min-width: 1220px) and (max-width: 1280px){
    .countrylistpartion{
        width: 200px;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1040px){
    .countrylistpartion{
        width: 200px;
    }
} */