.imsResultsMenuWrapper-mobile {
    width: 100%;
    font-family: 'J&J CircularTT-Medium';
    color: #333;
    font-size: 14px;
}

.imsResultsMenuHeader-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-direction: column; */
    width: 98%;
    height: 30px;
    color: #fff;
    border: 2px solid #2D86B5;
    background-color: #2D86B5;
    border-radius: 2px;
    /* justify-content: center; */
    text-align: left;
    /* padding-left:3%; */
    margin: 0 1%;
    padding: 0% 5% 0% 3%;
    position: relative;
}

.resultsContentWrapper-mobile {
    padding-top: 3%;
}

.resultsContentWrapper-tablet {
    padding-top: 2%;
}

.imsResultsMenuWrapper-tablet {
    width: 100%;
    font-family: 'J&J CircularTT-Medium';
    color: #333;
    font-size: 14px;
}

.imsResultsMenuHeader-tablet {
    display: flex;
    width: 100%;
    height: 30px;
    border-radius: 3px;
    justify-content: space-between;
    align-items: center;
    padding-left: 1%;
}

.imsResultsMenuHeader-tablet span {
    width: 49%;
    background: #fff;
    /* color: #B4B4B4; */
    /* border: 1px solid #B4B4B4; */
    font-size: 17px;
}

.imsResultsMenuHeader-tablet-active {
    color: #fff !important;
    background: #2D86B5 !important;
    border: 1px solid "#2D86B5" !important
}

.clearSearchText {
    letter-spacing: 0.02px;
    color: #63666A;
    opacity: 1;
    font-size: 17px;
}

.clearSearchIcon {
    width: 35px;
    height: 35px;
}

.clearSearchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin: 0;
}

.resultListCount-container {
    display: flex;
    flex-direction: row;
    /* justify-content: flex-end; */
}

.iMSPubMedResults-dropdownIcon-mobile {
    /* width: 10px;
    height: 6px; */
    width: 12px;
}

.iMSPubMedResults-dropdownContainer-mobile {
    position: absolute;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    background: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    left: 0px;
    top: 29px;
    padding: 0% 3%;
    list-style: none;
    color: #333;
    border-top: 1px solid #000;
}

.iMSPubMedResults-dropdownText-mobile {
    padding: 2% 0%;
}

.paginationArrowIcon-mobile {
    width: 10px;
    height: 15px;
}

.paginationContainer-mobile {
    display: flex;
    width: 125px;
    justify-content: space-evenly;
    align-items: center;
}

.startEndIndexText-mobile {
    font-size: 17px;
    color: #B4B4B4;
    cursor: pointer;
}

.currentResultText-mobile {
    font-size: 17px;
    color: #333;
}

.fs-parent-field-wrapper {
    color: #FFFFFF;
    background: #B2B2B2;
    padding: 5px 8px;
    vertical-align: middle;
    border-left: solid 7px #fa9546;
}

.fs-parent-field-wrapper-mobile {
    padding: 5px 8px;
    vertical-align: middle;
    border-left: solid 7px #fa9546;
    background: #FFF;
    color: #fa9546;
}

.resultsDivLeftWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* align-items: center; */
    /* margin: 23% 0 0 0; */
}

.activePage-mobile {
    background: #0074B0;
    color: #FFFFFF;
    margin: 0% 2%;
    padding: 0% 10%;
    font-size: 17px;
}

.inactivePage-mobile {
    color: #FFFFFF;
    background: #B4B4B4;
    margin: 0% 2%;
    padding: 0% 10%;
    font-size: 17px;
}

.paginationArrow-mobile {
    /* margin: 0% 2%; */
    cursor: pointer;
}

@media screen and (min-width:600px) and (max-width:999px) {
    .results-count-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .resultListCount-container {
        width: 78%;
    }
}

@media screen and (max-width:599px) {
    .results-count-wrapper {
        /* justify-content: space-between; */
        width: 99%;
        align-items: center;
        /* display: inline; */
    }

    .results-contents-wrapper {
        margin: 1% 0% 0 0%;
    }

    .resultsDivLeftWrapper {
        flex-direction: column;
    }

    .pagination-region {
        margin: 0px;
        margin-left: 0.5%;
        float: left;
        width: 85%;
    }

    .pagination-region .pagination-container {
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
        /* flex-wrap: nowrap;
        justify-content: flex-start; */
    }

    .inactivePage-mobile {
        /* padding: 0% 9%; */
        padding: 0% 2%;
        margin: 0.5% 1%;
    }

    .activePage-mobile {
        /* padding: 0% 8%; */
        padding: 0% 2%;
        margin: 0%;
    }

    .clearSearchContainer {
        float: right;
        display: block;
        margin: 0 1% 0 0;
    }
}

@media screen and (min-width:640px) and (max-width:659px) {
    .clearSearchText {
        font-size: 16px;
    }
}

@media screen and (min-width:632px) and (max-width:640px) {
    .clearSearchText {
        font-size: 14px;
    }

    .resultListCount-container {
        width: 80%;
    }
}

@media screen and (min-width:600px) and (max-width:631px) {
    .clearSearchText {
        font-size: 10px;
        font-weight: 900;
    }

    .resultListCount-container {
        width: 83%;
    }
}

.imsResultsMenuHeader-tablet-deactive,
.imsResultsMenuHeader-deactive {
    /* color: #2D86B5 !important; */
    cursor: pointer;
    /* text-decoration: underline; */

}

.pubmedResultsMenuHeader-tablet-active {
    color: #fff !important;
    background: #2D86B5 !important;
    border: 1px solid "#2D86B5" !important
}

.global-feature-pubmed {
    display: flex;
    width: 325px;
    height: 55px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 5px 10px;
}

.global-feature-pubmedicon-wrapper {
    /* width: 113px;
    height: 54px; */
    border-right: 1px solid rgba(102, 102, 102, 0.5);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    padding-right: 15px;
    /* opacity: 0.28; */
}

.global-feature-pubmedicon-wrapper>.global-feature-pubmedicon {
    width: 79px;
    height: 28px;
    text-align: left;
}

.global-feature-pubmedicon-wrapper>.global-feature-viaIMedical {
    width: 105px;
}

.global-feature-pubmedicon-wrapper>p {
    font-size: 11px;
    text-align: left;
    margin: 0px;
    line-height: 10px;
}

.global-feature-pubmednih-wrapper {
    padding-left: 5px;
}

.global-feature-pubmednih-wrapper>img {
    margin-top: 5px;
    width: 204px;
    height: 28px;
}

.global-feature-pubmednih-wrapper>p {
    font-size: 11px;
    text-align: left;
    margin: 0px;
    line-height: 10px;
}
.actionItem-data-pubmed{
    width: 55px;
}

.pubmed-tooltip-uparrow {
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 13px solid #0074B0;
    position: absolute;
    top: 3px;
    right: 24px;
    /* bottom: 60px; */
}
.pubmed-tile-wrapper {
    position: relative;
    /* display: flex; */
    z-index: 10000;
}
.pubmed-tooltip-bottom {
    position: absolute;
    padding: 6px 4px 3px 11px;
    color: #fff;
    background: #0074B0 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    top: 15px;
    /* bottom: -35px; */
}
.global-features-inner-pubmed-wrapper{
    justify-content: center;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.global-features-inner-pubmed-wrapper > .global-feature-tile,.global-features-inner-pubmed-wrapper > .global-feature-pubmed{
margin: 0 45px;
}
.imsResultsMenuHeader-mobile, .iMSPubMedResults-dropdownContainer-mobile{
    font-size: 16px;
}
.please-note-content{
    margin: 4px 0px;
    color: red;
}

@media screen and (min-width:1000px) and (max-width:1270px) {
    .global-features-inner-pubmed-wrapper > .global-feature-tile,.global-features-inner-pubmed-wrapper > .global-feature-pubmed{
        margin: 0 26px;
        }
}

@media screen and (max-width:1000px) {
    .please-note-content{
        display: flex;
    }
    .pn-text{
        margin: auto;
    }
    .global-feature-pubmedicon-wrapper>.global-feature-pubmedicon {
        margin: auto 0;
    }
    .global-feature-pubmednih-wrapper>img {
        margin: auto 0;
    }
    .global-feature-pubmed{
        height: 40px;
        justify-content: flex-end;
    }
    .global-feature-pubmedicon-wrapper{
        padding-right: 5px;
    }

}