@media screen and (min-width:600px) and (max-width:1000px) {
    .recommended-contents-wrapper{
      display:flex;
      /* text-align: left; */
      padding: 1% 0;
      flex-direction: column;
      min-height: 60vh;
      margin: 0% 0% 0 0%;
      width: 100%;
      padding-top:0;
    }
  }
  @media screen and (max-width:600px) {
    .recommended-contents-wrapper{
      display:flex;
      /* text-align: left; */
      padding: 1% 0;
      flex-direction: column;
      min-height: 60vh;
      margin: 1% 0% 0 0%;
      width: 100%;
    }
  }