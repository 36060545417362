.productsAlertsHeader{
    /* background-color: #1b8dee; */
    padding-left: 12px;
    text-align: left;
    color: #fff;
    line-height: 38px;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 0px;
    padding: 0;
    padding-left: 20px;
    background: #0074b0 0 0 no-repeat padding-box;
    border-radius: inherit;
}
.paModalWrapper{
    max-height: 90vh;
    overflow: auto;
    font-family: 'J&J CircularTT-Medium';
}
.productAlertsTable{
    width: 100%;
}
.prodAlertsTableHeading{
    background: #eee none repeat scroll 0 0;
}
.productAlertsTable th, .productAlertsTable td{
    padding: 4px 3px;
}
select:focus-visible{
    outline: none;
}
.prodAlertsButtonContainer{
    padding: 1% 0% 3% 2%;
}
.prodAlertsEvevRow{
    background: #fafafa none repeat scroll 0 0;
}
.popup-transition-prodAlerts{
    height: 100%;
}
.prodAlertHeadingText{
    font-size: 16px;
}
.subscribe{
    margin: 0;
}
.prodAlertsData:nth-child(even) {background:#fafafa none repeat scroll 0 0  }
.prodAlertsData:nth-child(odd) {background: #FFF}
.prod-alerts-data-loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
}