.manage-alerts-wrapper{
    text-align: left;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    padding: 2%;
    color:#333;
    font-family: 'J&J CircularTT-Medium';
    font-size: 20px;
    line-height: 1;
}
.manage-alerts-link{
    color: #0074B0;
    text-decoration: none;
    font-size: 16px;
    line-height: 1.5;
    /* display: block; */
}
.manage-alerts-link:hover{
    color: #0074B0;
    text-decoration: underline;
}
li.manage-alerts-list{
    margin: 20px 0;
}
.manage-alerts-list span{
    font-size: 16px;
}
.manage-alerts-list-mobile{
    border: 1px solid #1DA1E5;
    border-radius: 4px;
    padding: 10px 3%;
    /* padding: 10px 5px; */
    margin: 23px 0 15px 0;
    color: #333;
    font-size: 16px;
    display: grid;
}
.manage-alerts-list-mobile span{
    text-align: justify;
}
@media screen and (max-width:999px){
    .manage-alerts-wrapper{
        margin: 1% 1.5%;
    }
}