.root-507 {
    font-size: 14px;
    font-weight: 400;
    line-height: 2.25rem;
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    color: rgb(0, 120, 212);
    white-space: nowrap;
    border-bottom: 1px solid #edebe9;
}
.linkIsSelected-517 {
    outline: transparent;
    position: relative;
    font-size: 0.75rem;
    font-weight: 600;
    border: 0px;
    border-radius: 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 0px 1.25rem;
    text-decoration: none;
    text-align: center;
    height: 50px;
    color: rgb(50, 49, 48);
    background-color: transparent;
    line-height: 44px;
    margin: 0px;
    user-select: none;
}
.linkContent-513 {
    flex: 0 1 100%;
    display: flex;
    flex-direction: column;
}
.text-514 {
    display: inline-block;
    vertical-align: top;
    margin: 0px;
    line-height: 1;
}
.linkContent-513 > :first-child {
    margin-left: 0px;
}
.icon-516 {
    margin: 3px 0px;
    line-height: 1rem;
    font-size: 1rem;
    color: #0078d4;
}
.panel-frame-jn5c3tzmdb {
    padding: .5rem;
    margin-top: 2px;
}
.flexiblePane-505 {
    font-size: 14px;
    font-weight: 400;
    height: auto;
    width: auto;
    flex: 1 1 0px;
    overflow: auto hidden;
    min-width: 1px;
}
.tuning-control-wrapper-rq1cjbhtkv,.header1 {
    line-height: 1.5rem;
    margin-bottom: .375rem;
    display: flex;
    font-weight: 600;
}
.collapse-btn-wrapper1 {
    color: #707070;
    cursor: pointer;
    font-size: .75rem;
    padding-right: .5rem;
    line-height: 1.5rem;
}
.root-309{
    margin-left: 10px;
    background-color: white;
}
.body1{
font-size: 14px;
font-weight: 600;
height: auto;
width: auto;
margin-left: 30px;
}
.flex-row{
    display: flex;
    flex-direction: row !important;
}
.tuning-control-wrapper-rq1cjbhtkv>.body>.children {
    flex-grow: 1;
    max-width: 100%;
}
.select-box-container{
    font-size: 14px
}
.menu-item{
    line-height: 1rem;
    font-size: 14px;
}
.selected123{
    background-color: #c7e0f4 !important;
}
.underLinetunning{
    border-bottom: 2px solid #0078d4;
    margin-left: 2px;
    outline: transparent;
    position: relative;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 0px 1.25rem;
    text-decoration: none;
    text-align: center;
    height: 50px;
    color: rgb(50, 49, 48);
    background-color: transparent;
    line-height: 44px;
    margin: 0px;
    user-select: none;}
.underlineComment {
    border-bottom:2px solid #0078d4;
    outline: transparent;
    position: relative;
    font-size: 0.75rem;
    font-weight: 600;
    border-radius: 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 0px 1.25rem;
    text-decoration: none;
    text-align: center;
    height: 50px;
    color: rgb(50, 49, 48);
    background-color: transparent;
    line-height: 44px;
    margin: 0px;
    user-select: none;
    margin-left: 2px;
}
.underline{
    border-bottom:2px solid #0078d4;
}
.css-bqyj1u-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    margin-top: 5px;
}
.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-15k6ek6-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 10px !important;;
}
.input-alias{
    width: 230px;
}
.buttonTick-active{
    background-color:#0078d4;
    margin-left: 3px;
    height: 30.5px;
    width: 35px;
}
.buttonTick{
    background-color: #ccc8c8;
    margin-left: 3px;
    height: 30.5px;
    width: 35px;
}
.rangeVal{
    margin-bottom: 4px;
}
.disabledButton{
    line-height: 2.25rem;
    color: #767676 !important;
}
.content-621 {
    display: flex;
    width: 100%;
    line-height: normal;
}
.iconContainer-622 {
    font-size: 16px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    flex-shrink: 0;
    margin: 8px;
    margin-left: 215px
}
.text-624 {
    min-width: 0px;
    display: flex;
    flex-grow: 1;
    margin: 10px 0px;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 400;
}
.innerText-625 {
    line-height: 16px;
}
.root-620 {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    background: rgb(253, 231, 233);
    box-sizing: border-box;
    color: rgb(50, 49, 48);
    min-height: 32px;
    width: 80%;
    display: flex;
    word-break: break-word;
    flex-direction: column;
    margin-top: 5px;
}
.Mui-focused{
    margin-bottom: 2px;
}