.filter-selection-display-wrapper-mobile{
    display: flex;
    flex-direction: column;
    margin:10px 0 0 0;
}
.applied-filter-header-section-mobile{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:1% 1%;
}
.applied-filter-header-text-mobile{
    font-size: 15px;
    color: #0074B0;
    font-weight: 600;
}
.applied-filters-header-icons-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-width:55px;
    cursor: pointer;
}
.clear-all-button-mobile{
    width: 20px;
    height: 20px;
    border: 1px solid #0074B0;
    border-radius: 18px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.filter-display-item-wrapper-mobile{
    border-top: 1px solid #00000029;
    background-color:#F4F4F4;
}
.parentFilter-mobile{
    color:#0074B0;
    background: #fff;
    font-style: italic;
    font-weight: 500;
}
@media screen and (max-width:600px){
    .subfilter{
        background: #fff;
    }
    .subfilter-text {
        padding: 0 4px;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
        display: inline-block;
        box-sizing: border-box;
        max-width: 223px;
    }
}