.chatBotSectionInnerWrapper{
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    z-index: 80;
}

.chat-bot-icon-wrapper{
   
    padding: 10px;
    background: #2a498a;
    border: 1px solid rgba(112, 112, 112, 0.21);
    border-radius: 5px;
}
.chat-bot-icon-wrapper >.chat-bot-icon{
    width: 50px;
    cursor: pointer;
}

.chatBot-sidebox-launcher .vis, .chatBot-sidebox-launcher.vis {
    display: block;
}
.chatBot-msg-preview-visual-indicator-container {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 18px 0 rgb(219 219 219 / 50%), 0 2px 4px 0 rgb(176 174 174 / 50%);
    position: fixed;
    bottom: 35px;
    right: 130px;
    transform: translateY(-50%);
    max-height: 66px;
    padding-bottom: 2px;
}
.chat-popup-widget-container .chat-popup-widget-close-btn-container, .chatBot-msg-preview-visual-indicator-container .chatBot-close-btn-container {
    position: absolute;
    top: 50%;
    /* display: none; */
    transition: all .3s ease-in-out;
    transform: translateY(-50%);
    height: 100%;
    width: 26px;
    left: -53px;
    padding: 14px;
}
.chatBot-msg-preview-visual-indicator-container .chatBot-close-btn {
    border-radius: 100px;
    background-color: #a19e9e;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 28%;
    transform: translateY(-50%);
    left: 59px;
}
.chatBot-msg-preview-visual-indicator-container .chatBot-close-btn .chatBot-close-icon-svg svg {
    vertical-align: middle;
    margin-bottom: 4px;
}
.chatBot-msg-preview-visual-indicator-container .chatBot-close-btn .chatBot-close-text {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .4px;
    color: #fff;
    display: none;
}
.chatBot-msg-preview-visual-indicator-container .chatBot-msg-preview-visual-indicator-text {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: .4px;
    color: #646262;
    padding: 5px 10px;
    min-width: 60px;
    max-width: 260px;
    white-space: normal;
    cursor: pointer;
    box-sizing: content-box;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 38px;
    display: flex;
    align-items: center;
}
.chatBot-msg-preview-visual-indicator-text.applozic-launcher .message-preview-text {
    white-space: nowrap;
}
.chatBot-msg-preview-visual-indicator-text.applozic-launcher * {
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}
.Typewriter__cursor{
    display: none;
}