.filter-modal-wrapper-tablet {
  background-color: white;
  border: 1px solid #dcdcdc;
  font-family: 'J&J CircularTT-Medium';
}

.filter-modal-wrapper-tablet .filter-category {
  background-color: #fff;
  color: #0074B0;
  height: 42px;
  padding: 20px 0px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.filter-container-tablet {
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  text-align: left;
  display: flex;
  flex-direction: row;
  width: 210%;
}

.filter-modal-wrapper-tablet .filter-category-text {
  max-width: 227px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  display: inline-block;
}

.filter-modal-wrapper-tablet .filter-category {
  background-color: #fff;
  color: #0074B0;
  height: 42px;
  padding: 20px 0px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
}

.filter-modal-wrapper-tablet .filter-icon-times {
  font-size: 15px;
}

.filter-modal-wrapper-tablet .filter-modal-body {
  padding: 25px 0px 25px 20px;
}

.filter-modal-wrapper-tablet .filter-modal-body-inner-wrapper {
  max-height: 300px;
  overflow-y: auto;
  min-height: 140px;
  /* padding-top:15px; */
}

.filter-modal-wrapper-tablet .apply-filter {
  border: 1px solid #dadada;
  border-radius: 7px;
  padding: 5px 5px;
  color: #fff;
  font-size: 12px;
  margin-bottom: 10px;
  background: #FF8000;
  transition: 0.5s;
}

.apply-filter:disabled {
  cursor: not-allowed;
}

.filter-modal-wrapper-tablet .apply-filter:hover:enabled {
  background: #fff;
  color: #FF8000;
  border: 1px solid #FF8000;
}

/* .filter-modal-wrapper .apply-filter:disabled{
    border: 1px solid #dadada;
    color:#888B8D;
    background: #fff;
  } */
.filter-modal-wrapper-tablet .filter-popup-item {
  font-size: 13px;
  font-size: 13px;
  /* border-left: 1px solid #C5C5C5;
    display: flex; */
  margin: 0 0 0 2px;
  flex-direction: column;
}

.filter-modal-wrapper-tablet .filter-popup-item-button {
  display: flex;
  align-items: flex-start;
  /*  border-left: 1px solid #C5C5C5; */
  /* height: 25px;
    padding-top: 10px; */
}

.filter-modal-wrapper-tablet .filter-popup-subitem {
  margin-left: 15px;
  /* border-left: 1px solid #C5C5C5; */
}

.filter-modal-wrapper-tablet .filter-popup-item-caret {
  margin-top: 2px;
  padding: 5px 5px 5px 0;
  border: none;
  background: #fff;
  /* margin-left: -9px; */
}

.filter-modal-wrapper-tablet .filter-popup-item-label {
  display: flex;
  padding: 5px;
  align-items: flex-start;
}

.filter-modal-wrapper-tablet .filter-popup-item-label input {
  margin-top: 3px;
  margin-right: 7px;
}

.filter-modal-wrapper-tablet .filter-popup-item-caret.hide-caret {
  visibility: hidden;
  pointer-events: none;
  /* margin-left: -9px; */
}

.filter-modal-wrapper-tablet .filter-popup-item-label input[type="checkbox"] {
  cursor: pointer;
}

.ignored-filter-wrapper {
  margin-top: 10px;
  text-align: left;
}

.ignored-text {
  color: #a5a5a5;
  text-decoration: line-through;
  margin-top: 10px;
  word-break: break-word;
  -ms-word-break: break-all;
}

.ignored-ims-filter-wrapper {
  color: #a5a5a5;
  padding: 10px 20px 0px 18px;
  text-align: center;
}

.ignored-header-text {
  color: #2D86B5;
  font-size: 18px;
}

@media screen and (min-width: 600px) and (max-width: 860px) {
  .reset-select-filter-wrapper {
    align-items: center;
    /* margin-top: 1rem; */
    margin-bottom: 0;
    border: 1px solid #0074B0;
    border-radius: 7px;
    padding: 0rem 0.1rem;
    color: #0074B0;
    font-size: 13px;
    background-color: #fff;
    /* margin-left:115px; */
  }

  .ignored-header-text {
    color: #2D86B5;
    font-size: 16px;
  }
}

@media screen and (max-width: 599px) {
  .ignored-header-text {
    color: #2D86B5;
    font-size: 16px;
  }
}

.filter-container-mobile {
  font-family: 'J&J CircularTT-Medium';
  font-size: 14px;
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.reset-select-filter-image-wrapper {
  width: 20px;
  height: 20px;
  border: 1px solid;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterSubPopUp {
  position: absolute;
  left: 100%;
  top: 0px;
}

.recentluUsed-container {
  align-items: center;
  align-content: center;
}