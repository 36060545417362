
.container-337 {
    display: flex;
    flex-flow: row;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}
.root-504 {
    font-size: 14px;
    font-weight: 400;
    box-shadow: none;
    margin: 0px;
    padding: 0.25rem 0px;
    box-sizing: border-box;
    position: relative;
    color: rgb(0, 120, 212);
    white-space: nowrap;
}

.flexiblePane-340 {
    font-size: 14px;
    font-weight: 400;
    height: auto;
    width: auto;
    flex: 1 1 0px;
    /* overflow: auto hidden; */
    min-width: 1px;
}
.css-343 {
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 400;
        flex: 1 1 auto;
        height: auto;
        width: auto;
        padding: 0px 1.25rem;
        overflow-y: auto;
        min-width: 35rem;
    }
.css-262 {
    display: flex;
    flex-flow: row;
    width: auto;
    height: auto;
    box-sizing: border-box;
}
.audio-player{
    border-top: 1px solid #edebe9;
    display: flex;
    margin: auto -1.25rem;
}
.audio-player .audio-player-wrapper{
    padding: .5rem 1rem;
    width: 100%;
}
.synthesis-audio-player-container {
    display: inline-block;
    vertical-align: middle;
}
.circlebutton{
    background-color: transparent;
    border: 2px solid;
    border-radius: 50%;
    display: inline;
    float: left;
    height: 1.75rem;
    margin: 0 6px !important;
    width: 1.75rem !important;
    color: rgb(0, 120, 212);
}
.flexContainer-177{
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
.play-bar-wrap{
    width: auto;
    display: flex;
    margin: 0 0.4rem;
}
.tuning-command-bar-ur8d2yznta {
    display: flex;
    justify-content: space-between;
    margin-bottom: -1px;
    margin-top: .25rem;
}
.flex-row {
    flex-direction: row !important;
}
.button-bar{
    flex-grow: 1;
    margin-left: -.5rem;
}
.ms-FocusZone {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0px;
    height: 44px;
    border-bottom: 1px solid rgb(237, 235, 233);
}
.primarySet-192 {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 1;
    align-items: stretch;
}
.item-193{
    flex-shrink: 0;
    display: inherit;
}
.textContainer-178 {
    flex-grow: 1;
    display: block;
}
.label-346 {
    margin: 0px 4px;
    line-height: 100%;
    display: block;
    font-weight: normal;
    white-space: nowrap;
}
.icon-345 {
    font-size: 16px;
    margin: 0px 4px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    flex-shrink: 0;
    color: #0078d4;
}
.css-534 {
    position: absolute;
    width: 1px;
    /*right: 31px;*/
    top: 8px;
    bottom: 8px;
    background-color: rgb(200, 198, 196);
    margin-left: 25px;
}
.css-338 {
    position: relative;
    cursor: ew-resize;
    width: 1px;
    background-color: rgb(237, 235, 233);
}
.file-tree-panel-pnpi7ihwoh {
    height: 100%;
}
.root-300::-webkit-scrollbar {
    height: 10px !important
}
.root-300{
    font-size: 14px;
    font-weight: 400;
    overflow-y: auto;
    user-select: none;
}
.chevronButton-304 {
    outline: transparent;
    position: relative;
    font-size: 18px;
    font-weight: 400;
    display: block;
    text-align: left;
    line-height: 44px;
    margin: 5px 0px;
    padding-left: 27px;
    padding: auto;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    color:  #0078d4;
    background-color: transparent;
    width: 100%;
    height: 44px;
    border-bottom: 1px solid rgb(237, 235, 233);
}
.Icon-482 {
   margin-right: 8px;
   margin-bottom: inherit
}
.editor-panel-w2d8kyu6gu .content-editor-wrapper {
    height: 100%;
    margin: 0 -1.25rem;
    min-height: 5rem;
    overflow-x: hidden;
    overflow-y: auto;
}
.container-394 {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
}
.flexiblePane-396 {
    font-size: 14px;
    font-weight: 400;
    height: auto;
    width: auto;
    flex: 1 1 0px;
    overflow: hidden;
    min-height: 1px;
}
.content-editor {
    background-color: #fff;
    border-top: 1px solid #edebe9;
    color: #707070;
    height: 100%;
    overflow: auto;
}
 .content-editor>.editor-view-text {
    line-height: 3;
}
.css-517 {
    position: relative;
    cursor: ns-resize;
    width: 100%;
    height: 1px;
    bottom: 50%;
    transition: height 100ms, bottom 100ms;
    background-color: rgb(237, 235, 233);
}
.editor-panel-w2d8kyu6gu, .audio-player, .audio-player-wrapper, .synthesis-audio-player-container, .progress-control-container, .play-bar-wrap {
   /* width: calc(100vh - 13rem);*/
    width:200px;
}
.button-123{
    font-size: 14px;
    font-weight: 400;
    color: rgb(0, 120, 212);
    outline: transparent;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 2px;
    line-height: 36px;
    background-color: #fff;
}
.container-top{
    margin:2px;
    display: flex;
    align-items: center;
    overflow-y: hidden;
    
}
.groupContent-310 {
    display: block;
    margin-bottom: 40px;
    animation-name: css-0, css-13;
    animation-duration: 0.367s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
    animation-fill-mode: both;
    overflow-y: auto;
    max-height: 85vh;
    scrollbar-width: thin;
}
.groupContent-310::-webkit-scrollbar {
    height: 10px !important
}
.control-panel-cxz21dnd6r {
    background-color: #fff;
    border-left: 1px solid #edebe9;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 22rem;
    min-width: 22rem;
}
.tuning-footer-98cfl5vjew {
    border-top: 1px solid var(--color-border);
    margin: 0 -1.25rem;
    padding-right: 1.25rem;
}
.css-344 > * {
    text-overflow: ellipsis;
}
.consoleWrapper-164 {
    padding: 0px 0.5rem;
}
.fixedPaneContainer-162 {
    overflow: hidden;
    scrollbar-width: thin;
}
.fixedPaneContainer-162::-webkit-scrollbar {
    height: 10px !important
}

.itemContainer-505 {
    margin-right: -0.5rem;
    padding-right: 0.5rem;
    overflow: auto;
}
.linkIsSelected-507 {
    outline: transparent;
    position: relative;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    font-weight: 600;
    border: 0px;
    border-radius: 0px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 0px 8px;
    text-decoration: none;
    text-align: center;
    height: 1.75rem;
    color: rgb(50, 49, 48);
    background-color: transparent;
    line-height: 44px;
    margin-right: 8px;
    user-select: none;
}
.flexContainer-320 {
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.linkContent-431 {
    flex: 0 1 100%;
}
.linkContent-431 > :first-child {
    margin-left: 0px;
}
.text-432 {
    display: inline-block;
    vertical-align: top;
}
.css-395 {
    font-size: 14px;
    font-weight: 400;
    flex: 1 1 auto;
    height: auto;
    width: auto;
}
.content-509 {
    display: flex;
    width: 100%;
    line-height: normal;
}
.iconContainer-510 {
    font-size: 16px;
    min-width: 16px;
    min-height: 16px;
    display: flex;
    flex-shrink: 0;
    margin: 10px;
}
.text-512 {
    min-width: 0px;
    display: flex;
    flex-grow: 1;
    margin: 10px 0px;
    font-size: 12px;
    font-weight: 400;
}
.acc-tuning-page .format-checking-message-wrapper .ms-MessageBar-innerText .message-wrapper {
    display: flex;
    justify-content: space-between;
}
.check{
    font-size: 14px;
    font-weight: 400;
    background: rgb(255, 244, 206);
    box-sizing: border-box;
    color: rgb(50, 49, 48);
    min-height: 32px;
    width: 100%;
    display: flex;
    word-break: break-word;
    flex-direction: column;
    margin-top: 5px;
}
.editor-panel-w2d8kyu6gu .content-editor {
    background-color: #fff;
    border-top: 1px solid #edebe9;
    color: #707070;
    height: 100%;
    overflow: auto;
}
.content-editor .editor-view-text {
    border: 0;
    font-size: .875rem;
    height: 100%;
    line-height: 2;
    overflow-y: auto;
    padding: 0.5rem;
    resize: none;
    width: 100%;
    word-break: break-word;
}
.control-panel-cxz21dnd6r {
    background-color: #fff;
    border-left: 1px solid #edebe9;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 22rem;
    min-width: 22rem;
}
.voice-text{
    text-align: left;
    font-weight: 700;
}
.Relative-Range-Container{
    margin-top: 10px;
}
.Constant-Range-Container{
    margin-top: 10px;
}
.highlight{
    background-color:rgb(255, 244, 206);
    width: 100px;
    margin-left: 40px;
}
.highlightText{
    text-decoration: underline red 2px;
}
.play-mode{
    border: 0;
    font-size: .875rem;
    line-height: 2;
    overflow-y: auto;
    padding: 1rem;
    resize: none;
    width: 100%;
    word-break: break-word;
    text-align: left;
    min-height: 270px;
    margin-top: 3px;
}