.reading-list-button-wrapper {
    padding: 2%;
    float: right;
    width: 14%;
    display: flex;
    justify-content: space-between;
}

.reading-list-input-box {
    width: 90%;
    height: 33px;
    border-radius: 5px;
    border: none;
    color: #333;
    padding-left: 1%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
}

.reading-list-textarea-box {
    width: 90%;
    height: 75px;
    border-radius: 5px;
    border: none;
    color: #333;
    padding-left: 1%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    resize: none;
}

.reading-list-wrapper {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    padding-left: 0;
    max-height: 100px;
    overflow-y: auto;
}

.reading-list-wrapper li {
    padding: 0 5px;
    word-break: break-all;
    width: 100%;
}

.reading-list-checkbox-wrapper {
    margin-right: 5px;
}

.reading-list-view-wrapper {
    list-style: none;
    /* display: flex;
    flex-flow: column wrap; */
    padding: 0 10px;
    width: 100%;
    border-spacing: 0 5px;
    border-collapse: separate;
}

.reading-list-view-wrapper tr {
    /* display: flex;
    flex-flow: row wrap; */
    justify-content: space-between;
}

.reading-list-view-wrapper tbody tr {
    /* display: flex;
    flex-flow: row wrap; */
    justify-content: space-between;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    margin: 5px 5px;
    padding: 0 5px;
}

.reading-list-view-wrapper tbody tr td {
    width: 30%;
    word-break: break-word;
}

.reading-list-view-wrapper th {
    color: black;
    background: none;
    width: 30%;
}

.action-items-separate-divider {
    border-left: 1px solid #707070;
    opacity: 0.5;
    height: 14px;
    margin: 0 5px;
}

.reading-list-action-text:hover {
    text-decoration: underline;
    color: #0074B0;
}

.pin-popup-button-wrapper {
    padding: 2%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
}

.pin-pop-up-button-cancel {
    color: rgb(0, 116, 176);
    border: 1px solid rgb(0, 116, 176);
    background: rgb(255, 255, 255);
    min-width: 70px;
    border-radius: 5px;
    margin: 1% 0;
}

.pin-pop-up-button-cancel:hover {
    color: rgb(255, 255, 255);
    background: rgb(0, 116, 176);
}

.pin-pop-up-button-proceed {
    color: #2EB000;
    border: 1px solid #2EB000;
    background: rgb(255, 255, 255);
    min-width: 70px;
    border-radius: 5px;
    margin: 1% 0;
}

.pin-pop-up-button-proceed:hover {
    color: rgb(255, 255, 255);
    background: #2EB000;
}

.reading-list-button-tablet-wrapper {
    padding: 2%;
    display: flex;
    justify-content: center;
}

.active-pin-features-button {
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    background: #7fb1da;
}

.pin-features-buttons {
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    background: white;
    padding: 1% 4%;
    color: #0074B0;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}

.pin-features-buttons:hover {
    color: #fff;
    background: #7fb1da;
}

.command-list-button-wrapper {
    width: 25%;
}

@media screen and (min-width:600px) and (max-width:1000px) {
    .reading-list-wrapper {
        max-height: 300px;
    }

    .collection-list-wrapper {
        max-height: 300px;
    }

}

@media screen and (max-width:600px) {
    .reading-list-wrapper {
        max-height: 250px;
    }

    .collection-list-wrapper {
        max-height: 250px;
    }

    .actionItem-headerPart {
        font-family: 'J&J CircularTT-Medium';
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
    }
}

@media screen and (min-width:1001px) and (max-width:1200px) {
    .command-list-button-wrapper {
        width: 30%;
    }
}

.prompt-templete-list-search-title-actionItem-headerPart {
    font-family: 'J&J CircularTT-Medium';
}

.prompt-templete-list-search-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}



.prompt-templete-list-header-input-box-wrapper {
    position: relative;
    display: inline-block;
    width: 350px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: left;
    margin-right: 25px;
}

.prompt-templete-list-header-search-box {
    width: 100%;
    height: 35px;
    border: 2px solid #d8d8d8 !important;
    font-family: 'J&J CircularTT-Medium';
    outline: none;
    font-size: 15px;
    float: left;
    position: relative;
    color: #333;
    /* box-shadow: 0px 3px 6px #00000029; */
    opacity: 1;
    /* border-radius: 4px; */
    /* border-radius: 30px; */
    padding: 1% 2% 1% 6%;
    letter-spacing: 0.02px;
    /* border: none; */
    /* margin-top: 5px; */
    /* padding-right: 50px; */
    border-radius: 4px;
    padding-left: 30px;
}

.prompt-templete-list-header-icon-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    top: 0;
    width: 70px;
    background-color: transparent;
    padding: 0;
    height: 35px;
    z-index: 5555;
}

.prompt-templete-list-header-input-search-cancel-icon {
    cursor: pointer;
    width: 12px;
    height: 12px;
    margin: 0 6%;
}

.prompt-templete-list-header-search-icon-wrapper-webView {
    cursor: pointer;
    /* background: #0074B0 0% 0% no-repeat padding-box; */
    /* border-radius: 4px; */
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    opacity: 1;
    padding: 5%;
    height: 100%;
    margin-left: 10%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #000000;
}

.prompt-templete-list-header-magnifyingGlass-mobileView {
    width: 65%;
}

.prompt-template-name-section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.prompt-template-name-section-text {
    width: 500px;
}

.prompt-template-name-section-label {
    margin-top: 5px;
    margin-right: 5px;
    color: #0074B0;
}

.prompt-templete-list-active-features-button {
    width: 280px;
    padding: 0.5% 5px;
    text-align: left;
    color: #0074B0;
    /* font-weight: 800; */
    font-size: 18px;
}
.prompt-templete-list-popup-transition{
    max-width: calc(100vw - 20px) !important;
    max-height: calc(100vh - 20px) !important;
}
.prompt-templete-list-popup-transition>.modal-content {
    height: calc(100vh - 20px);
    bottom: 20px;
    top: 0px;
}

.prompt-templete-list-head-body {
    /* padding-top: 1%; */
}

.prompt-templete-list-gsfModalHeader {
    padding: 1%;
    border: none !important;
}

.manage-prompt-template-create-prompt {
    display: flex;
    justify-content: space-between;
}
.manage-prompt-template-upload-prompt
{
    color: rgb(0, 116, 176);
    border: 1px solid rgb(0, 116, 176);
    min-width: 70px;
    border-radius: 5px;
    cursor: pointer;
    padding: 2px 3px;
    height: 27px;
}
.manage-prompt-template-upload-prompt:hover{
    color: rgb(255, 255, 255);
    background:  rgb(0, 116, 176);
}
.prompt-templete-modal-body{
    height: 65vh
}
.prompt-templete-list-gsf-popup-button-wrapper > button{
    height: 27px;
}