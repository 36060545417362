.user-prompt-template-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.user-prompt-template-label {
    width: 50px;
    color: #0074B0;
}

.user-prompt-template-expand {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.user-prompt-template-textArea {
    width: calc(100% - 110px);
}

.user-prompt-template-list-textarea-box {
    width: 100%;
    height: 75px;
    border-radius: 5px;

    color: #333;
    padding-left: 1%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    resize: none;
    border: 1px solid #000000;
}

.user-prompt-template-wrapper {
    border-width: 1px;
    border-radius: 5px;
    /* border: 1px solid #000000; */
    padding: 10px;
}

.user-prompt-template-add-remove-box {
    display: flex;
    justify-content: flex-end;
}

.user-prompt-template-add-remove-box>.reading-list-action-text {
    cursor: pointer;
}

.user-prompt-template-prompt-area {
    overflow-y: auto;
    max-height: 50vh;
    border: 1px solid #000000;
    padding: 2px;
    border-radius: 5px;
}

.upload-prompt-template-button {
    width: 180px;
}


.save-prompt-template-button,
.upload-prompt-template-button,
.apply-prompt-template-button {
    cursor: pointer;
    background: #0074B0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #FFFFFF;
    margin: 0 3px;
}

.cancel-prompt-template-button {
    margin: 0 3px;
    cursor: pointer;
    border-radius: 4px;
    opacity: 1;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.prompt-template-search-and-nav {

    height: 100%;
    display: flex;
    align-items: center;
}

@media screen and (min-width: 1001px) and (max-width: 1050px) {
    .prompt-templete-list-search {
        font-size: 12px;
    }

    .prompt-templete-list-header-input-box-wrapper {
        width: 283px;
    }

    .prompt-templete-modal-body {
        font-size: 12px;
    }

    .reading-list-input-box,
    .user-prompt-template-list-textarea-box {
        font-size: 12px;
    }
}

@media screen and (min-height: 440px) and (max-height: 494px) {
    .user-prompt-template-prompt-area {
        max-height: 46vh;
    }

    .prompt-templete-list-gsfModalHeader {
        padding: 2px 5px;
    }


    .prompt-templete-list-gsf-popup-button-wrapper {
        padding: 5px;
        font-size: 12px;
    }

    .prompt-templete-list-search {
        font-size: 12px;
    }

    .prompt-templete-modal-body {
        font-size: 12px;
    }

    .reading-list-input-box,
    .user-prompt-template-list-textarea-box {
        font-size: 12px;
    }
}

@media screen and (min-height: 495px) and (max-height: 550px) {
    .user-prompt-template-prompt-area {
        max-height: 46vh;
    }

    .prompt-templete-list-gsfModalHeader {
        padding: 0.5%;
    }


    .prompt-templete-list-gsf-popup-button-wrapper {
        padding: 5px;
        font-size: 12px;
    }

    .prompt-templete-list-search {
        font-size: 12px;
    }

    .prompt-templete-modal-body {
        font-size: 12px;
    }

    .reading-list-input-box,
    .user-prompt-template-list-textarea-box {
        font-size: 12px;
    }
}

@media screen and (min-height: 551px) and (max-height: 600px) {
    .user-prompt-template-prompt-area {
        max-height: 48vh;
    }

    .prompt-templete-list-gsfModalHeader {
        padding: 0.5%;
    }

    .prompt-templete-list-gsf-popup-button-wrapper {
        padding: 10px;
    }

    .upload-prompt-template-button {
        margin-left: 20px;
    }
}

@media screen and (min-height: 601px) {

    .prompt-templete-modal-body {
        font-size: 15px;
    }

    .prompt-templete-list-search-title {
        font-size: 15px;
    }
}