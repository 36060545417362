@media only screen and (max-width : 1366px){
    .wnfy-view-more-wrapper{
      width: 21%;
    }
    .rv-view-more-wrapper{
      width: 5%;
    }
  }
  @media screen and (min-width: 1000px)and (max-width: 1100px){
    .wnfy-view-more-wrapper{
      width: 30%;
    }
  }
  @media screen and (min-width: 1101px)and (max-width: 1200px){
    .wnfy-view-more-wrapper{
      width: 28%;
    }
  }
  @media screen and (min-width: 1201px)and (max-width: 1300px){
    .wnfy-view-more-wrapper{
      width: 26%;
    }
  }
  @media screen and (min-width: 1301px)and (max-width: 1365px){
    .wnfy-view-more-wrapper{
      width: 24%;
    }
  }
  @media screen and (min-width: 650px)and (max-width: 750px){
  .wnfy-view-more-wrapper {
      width: 25%;
  }
  .wnfy-header-text {
    font-size: 12px;
  }
  .wnfy-main-header{
    font-size: 18px;
  }
  .wnfy-view-more-header-text{
    font-size: 15px;
  }
  }
  @media screen and (min-width: 500px)and (max-width: 650px){
    .wnfy-view-more-wrapper {
        width: 27%;
    }
    .wnfy-header-text {
      font-size: 11px;
    }
    .wnfy-main-header{
      font-size: 17px;
    }
    .wnfy-view-more-header-text{
      font-size: 14px;
    }
    }
  @media screen and (max-width: 500px){
    .wnfy-view-more-wrapper {
        width: 30%;
    }
    .wnfy-header-text {
      font-size: 8px;
    }
    .wnfy-main-header{
      font-size: 15px;
    }
    .wnfy-view-more-header-text{
      font-size: 13px;
    }
  }
  @media screen and (min-width: 1000px) and (max-width: 1200px){
    /* .wnfy-tile-wrapper {
    
      height: 360px;

  } */
  .rv-qh-header-section{
    height: 62px;
  }
  }
  @media screen and (min-width: 1201px) and (max-width: 1300px){
    .rv-qh-header-section{
      height: 64px;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 1000px){
    .wnfy-content-section{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1%;
    }
    .wnfy-tile-wrapper {
        background: #FBFBFB 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #9d9d9d4f;
        opacity: 1;
        margin:0.5% 0.5%;
        width: 99%;
        height: 160px;
        display: flex;
        flex-direction: row-reverse;
        font-size: 13px;
        font-family: 'J&J CircularTT-Medium';
    }
    .wnfy-preview-wrapper {
      float: right;
      padding: 1% 2%;
      height: 100%;
      width: 40%;
    }
    .wnfy-citation-wrapper{
      float: left;
      padding: 0.5% 4%;
      height: 100%;
      width: 60%;
    }
    .wnfy-results-time-cal-wrapper{
      font-family: 'J&J CircularTT-Medium';
      color:#FF4747;
      font-size:13px;
      background: #F6F6F6;
      height: 20px;
      width:18%;
      text-align: left;
      padding: 0 0 0 1%;
    }
    .wnfy-sort-by-text{
      color: #0074B0;
      font-weight: 600;
      font-size: 15px;
      display: flex;
      align-items: center;
      margin: 0 15px 0 0;
    }
  }
  @media screen and (max-width: 600px){
    .wnfy-content-section{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1%;
    }
    .wnfy-tile-wrapper {
      background: #FBFBFB 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #9d9d9d4f;
      opacity: 1;
      margin:0.5% 0.5%;
      width: 99%;
      height: 160px;
      display: flex;
      flex-direction: row-reverse;
      font-size: 13px;
      font-family: 'J&J CircularTT-Medium';
    }
    .wnfy-preview-wrapper {
    float: right;
    padding: 1% 2%;
    height: 100%;
    width: 40%;
    }
    .wnfy-citation-wrapper{
    float: left;
    padding: 0.5% 4%;
    height: 100%;
    width: 100%;
    }
    .wnfy-results-time-cal-wrapper{
      font-family: 'J&J CircularTT-Medium';
      color:#FF4747;
      font-size:13px;
      background: #F6F6F6;
      height: 20px;
      width:27%;
      text-align: left;
      padding: 0 0 0 1%;
    }
  }
  @media screen and (max-width: 400px){
    .wnfy-results-time-cal-wrapper{
      font-family: 'J&J CircularTT-Medium';
      color:#FF4747;
      font-size:13px;
      background: #F6F6F6;
      height: 20px;
      width:35%;
      text-align: left;
      padding: 0 0 0 1%;
    }
  }
  .wnfy-footer-section{
    padding:0.2% 1%;
    align-self: center;
    border:1px solid #0074B0;
    margin:1.5% 0.5%;
    width: 60%;
    cursor: pointer;
  }
  .wnfy-expand-wrapper{
    display: flex;
    float: right;
    cursor: pointer;
    border:1px solid #1DA1E5;
    background: #fff;
    padding: 2px;
  }
.wnfy-header-section-mobile{
    border: 1px solid #1DA1E5;
    padding: 0.5% 1%;
    text-align: left;
    background-color:#F2FBFF;
    color: #333;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
@media screen and (max-width:599px) {
  .wnfy-results-title-text{
    width: 100%;
  }
}
@media screen and (min-width:600px) and (max-width:999px) {
  .wnfy-results-title-text{
    width: auto;
  }
  .wnfy-results-count-wrapper{
    /* width: auto; */
    width: 68%;
  }
  .wnfy-results-title-wrapper{
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
}
@media screen and (min-width:692px) and (max-width:704px) {
  .wnfy-results-title-text{
    font-size: 18px;
  }
}
@media screen and (min-width:680px) and (max-width:692px) {
  .wnfy-results-title-text{
    font-size: 18px;
  }
}
@media screen and (min-width:668px) and (max-width:680px) {
  .wnfy-results-title-text{
    font-size: 17px;
  }
}
@media screen and (min-width:654px) and (max-width:668px) {
  .wnfy-results-title-text{
    font-size: 16px;
  }
}
@media screen and (min-width:643px) and (max-width:654px) {
  .wnfy-results-title-text{
    font-size: 15px;
  }
}
@media screen and (min-width:631px) and (max-width:642px) {
  .wnfy-results-title-text{
    font-size: 14px;
  }
}
@media screen and (min-width:620px) and (max-width:630px) {
  .wnfy-results-title-text{
    font-size: 13px;
  }
  .wnfy-results-count-wrapper{
    font-size: 13px;
  }
}
@media screen and (min-width:606px) and (max-width:620px) {
  .wnfy-results-title-text{
    font-size: 12px;
  }
  .wnfy-results-count-wrapper{
    font-size: 12px;
  }
}
@media screen and (min-width:600px) and (max-width:606px) {
  .wnfy-results-title-text{
    font-size: 11px;
  }
  .wnfy-results-count-wrapper{
    font-size: 11px;
  }
}
@media screen and (min-width:781px) and (max-width:810px) {
  .wnfy-results-title-text{
    font-size: 18px;
  }
  .wnfy-results-count-wrapper{
    width: 70%;
  }
}
@media screen and (min-width:755px) and (max-width:781px) {
  .wnfy-results-title-text{
    font-size: 16px;
  }
  .wnfy-results-count-wrapper{
    width: 72%;
  }
}
@media screen and (min-width:715px) and (max-width:755px) {
  .wnfy-results-title-text{
    font-size: 14px;
  }
  .wnfy-results-count-wrapper{
    width: 74%;
  }
  .wnfy-sort-by-text{
    font-size: 12px !important;
  }
}
@media screen and (min-width:683px) and (max-width:715px) {
  .wnfy-results-title-text{
    font-size: 14px;
  }
  .wnfy-results-count-wrapper{
    width: 73%;
    font-size: 13px;
  }
  .wnfy-sort-by-text{
    font-size: 12px !important;
    margin: 0 5px 0 0;
  }
  .showingResultsCountContainer{
    margin: 0 5px 0 0;
  }
  .sort-by-wrapper{
    margin: 0 0 0 1%;
  }
}
@media screen and (min-width:636px) and (max-width:683px) {
  .wnfy-results-title-text{
    font-size: 14px;
  }
  .wnfy-results-count-wrapper{
    width: 70.9%;
    font-size: 11px;
  }
  .wnfy-sort-by-text{
    font-size: 12px !important;
    /* margin: 0; */
    margin: 0 5px 0 0;
  }
  .showingResultsCountContainer{
    /* margin: 0; */
    margin: 0 5px 0 0;
  }
}
@media screen and (min-width:600px) and (max-width:636px) {
  .wnfy-results-count-wrapper{
    width: 71%;
    font-size: 11px;
  }
  .wnfy-sort-by-text{
    font-size: 11px !important;
    margin: 0 5px 0 0;
  }
  .showingResultsCountContainer{
    margin: 0 5px 0 0;
  }
}
/* @media screen and (min-width:630px) and (max-width:650px) {
  .wnfy-results-title-text{
    font-size: 18px;
  }
}
@media screen and (min-width:612px) and (max-width:630px) {
  .wnfy-results-title-text{
    font-size: 16px;
  }
}
@media screen and (min-width:600px) and (max-width:612px) {
  .wnfy-results-title-text{
    font-size: 14px;
  }
} */
.wnfy-button-wrapper-preview {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media screen and  (max-width:1000px) {
  .wnfy-button-wrapper-preview {
    justify-content: space-between;
  }

}