.actvUsrVal {
    color: green;
    font-weight: bold;
}
.activeusers {
    float: right;
}
.rptTitleLeft {
    float: left;
}
.rptTableMPD{
    width: 100%;
    contain: layout;
  }
.rptTableMPD .react-bootstrap-table table{
    table-layout: fixed;
    overflow: auto;
    min-width: 90%;
  }
.dtPanelLeft {
    float: left;
    width:30%;
}
.rptPanelLeft {
    float: left;
    width: 30%;
}
.rptPanelRight {
    /* float: right;
    margin-right: 15%;
    margin-top: 10px; */
    width: 98%;
}
.rptPanelRight > .reportLinks {
    margin-top: 0.5%;
}
.divSD {
    float: left;
}
.divED {
    float: left;
}