.fielded-Search-wrapper{
    color:#333;
    font-size:14px;
    font-family: 'J&J CircularTT-Medium';
}
.fielded-search-first-div{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    height: 323px;
    margin: 1% 2% 1% 2%;
    padding:1% 2%;
}
.fielded-search-first-div-tablet{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    height: 304px;
    margin: 1% 2% 1% 2%;
    padding:1% 2%;
}
.fielded-search-title{
    font-size:22px;
    color:#197DA9;
    text-align: left;
    letter-spacing: 0.02px;
}
.field-values-container{
    padding:2% 4%;
    text-align: left;
    display: flex;
    flex-flow: column wrap;
}
.field-values-container-wrapper{
    display:flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width:85%;
    padding:5px 0;
}
.field-values-container-tablet{
    padding:2% 2%;
    text-align: left;
    display: flex;
    flex-flow: column wrap;
}
.field-values-container-wrapper-tablet{
    display:flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width:100%;
    padding:5px 0;
}
.field-value-input-box{
    height: 35px;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border:none;
    outline: none;
    border-radius:4px;
    width:70%;
    padding:0 5px;
}
.field-value-input-box-tablet{
    width: 60%;
}
.field-value-dropdown-wrapper{
    height: 30px;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border:none;
    outline: none;
    border-radius:4px;
    width:13%;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.field-value-dropdown-wrapper-tablet{
    height: 30px;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border:none;
    outline: none;
    border-radius:4px;
    width:14%;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.field-value-dropdown-wrapper-tab-large{
    width:20%;
}
.field-value-dropdown-inner-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    top:30px;
    padding:0 5px;
}
.fielded-search-boolean-list-wrapper li{
    list-style: none;
    padding:2px 5px;
}
.fielded-search-boolean-list-wrapper{
    position: absolute;
    z-index: 100;
    text-align: left;
    width: 9%;
    background-color: #fff;
    list-style: none;
    margin-bottom:0 ;
    padding-left: 3px;
    margin-top:27px;
    border:1px solid #d9d9d9;
    border-radius:5px;
    cursor: pointer;
}
.fielded-search-second-div{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    height: 500px;
    margin: 1% 2% 1% 2%;
    padding:1% 2%;
    display: flex;
    flex-direction: column;
}
.fs-second-div-tablet{
    height: 550px;
}
.fielded-search-limits-wrapper{
    display: flex;
    flex-flow: row wrap;
    padding: 15px 4%;
    align-items: center;
    justify-content: space-between;
    width:85%;
}
.fielded-search-limits-wrapper-tablet{
    width: 100%;
}
.limit-input-text-box{
    height: 35px;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    border:none;
    outline: none;
    border-radius:4px;
    padding:0 5px;
    width: 100%;
}
.limit-dropdown-wrapper{
    width:75%;
    display: flex;
    flex-flow: column wrap;
}
.limit-dropdown-inner-wrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.fielded-search-limits-list-wrapper{
    position: absolute;
    z-index: 100;
    text-align: left;
    width: 53%;
    background-color: #fff;
    list-style: none;
    margin-bottom: 0;
    padding-left: 3px;
    padding-bottom: 8px;
    margin-top: 36px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    max-height: 182px;
    overflow-y: auto;
    cursor: pointer;
}
.fielded-search-limits-list-wrapper-protocol{
    max-height: 110px;
}
.publication-year-limit-wrapper{
    display: flex;
    /* width: 50%; */
    align-items: center;
    flex-direction: column;
}
.publication-limit-dropdown-wrapper{
    display: flex;
    width: 75%;
    justify-content: space-between;
}
.limit-py-text-wrapper{
    padding-right: 15px;
}
.fielded-search-py-limits-list-wrapper{
    position: absolute;
    z-index: 100;
    text-align: left;
    width: 11%;
    background-color: #fff;
    list-style: none;
    margin-bottom: 0;
    padding-left: 3px;
    padding-bottom: 8px;
    margin-left:3%;
    margin-top: 36px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    max-height: 182px;
    overflow-y: auto;
    cursor: pointer;
}
.fielded-search-button-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width:96%;
    padding:15px 0;
}
.fielded-search-button-inner-wrapper{
    display: flex;
    justify-content: space-between;
    /* width:180px; */
}
/* .fs-button-inner-wrapper-tablet{
    width:22%;
} */
.fielded-search-select-wrapper{
    height: 30px;
    outline: none;
    border: none;
    width: 100%;
    background: url('../../assets/dropdownArrow.svg') no-repeat right 3px #f8f8f8;
    position: relative;
}
.fielded-search-select-wrapper select{
    height: 100%;
    position: absolute;
    border:none;
    background:transparent;
    color:#333;
    outline: none;
    width:100%;
    -webkit-appearance: none;
    -moz-appearance: none;
}
option{
    background-color: #f8f8f8;
}
.field-value-input-box-mobile{
    width:100%;
}
.field-value-dropdown-wrapper-mobile{
    width: 100%;
    margin: 3% 0px;
}
.add-field-button-wrapper{
    width: 97%;
    border: 1px solid rgb(25, 125, 169);
    border-radius: 5px;
    margin: 0px 2%;
    height: 30px;
    background: #fff;
    text-align: center;
    color: #197DA9;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fielded-search-first-div-mobile{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    /* height: 323px; */
    margin: 1% 2% 1% 2%;
    padding: 1% 2%;
}
.fielded-search-button-wrapper-mobile{
    display:flex;
    flex-direction: column-reverse;
    width:100%;
    cursor: pointer;
}
.field-search-button-mobile{
    border-radius: 5px;
    border : 1px solid #2EB000;
    color: #2EB000;
    background-color: #fff;
    height: 30px;
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 2%;
}
.field-clear-button-mobile{
    border-radius: 5px;
    border : 1px solid #f44336;
    color: #f44336;
    background-color: #fff;
    height: 30px;
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 2%;
}
.field-cancel-button-mobile{
    border-radius: 5px;
    border : 1px solid #0074B0;
    color: #0074B0;
    background-color: #fff;
    height: 30px;
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2% 2%;
}
.fielded-search-limits-wrapper-mobile{
    width:100%;
    align-items:flex-start;
    flex-flow:column wrap;
}
.limit-dropdown-wrapper-mobile{
    width: 100%;
    position: relative;
}
.fielded-search-second-div-mobile{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    /* height: 500px; */
    margin: 1% 2% 1% 2%;
    padding:1% 2%;
    display: flex;
    flex-direction: column;
}
.publication-year-mobile{
    position: relative;
}
.fielded-search-py-limits-list-wrapper-mobile{
    z-index: 100;
    text-align: left;
    width: 100%;
    background-color: #fff;
    list-style: none;
    margin-bottom: 0;
    padding-left: 3px;
    margin-left: 0%;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    max-height: 182px;
    overflow-y: auto;
    cursor: pointer;
    position: absolute;
    right: 0;
}
@media screen and (min-width:600px) and (max-width:999px) {
    .fielded-search-limits-list-wrapper{
        width: 63%;
    }
}
@media screen and (max-width:599px) {
    .fielded-search-limits-list-wrapper{
        width: 100%;
    }
}