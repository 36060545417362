.phoneme-lib-button{
    font-size: .875rem;
    height: 2rem;
    line-height: 1;
    margin: 3px;
    min-width: 59px;
    padding: 0;
    text-align: center;
    outline: transparent;
    position: relative;
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid rgb(138, 136, 134);
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    padding: 0px 16px;
    text-decoration: none;
    text-align: center;
    min-width: 80px;
    height: 32px;
    background-color: rgb(255, 255, 255);
    color: rgb(50, 49, 48);
}
.margin-top_15{
    max-height: 30vh;
    overflow: auto;
    margin-bottom: 0;
    margin-top: .9375rem;
}
.display {
    display: flex;
    margin-left: 31px;
    margin-top: 5px;
}
.ms-TooltipHost{
    display: inline;
}
.note-button{
        display: flex;
        flex-flow: wrap;
        margin-left: 31px;
        margin-top: 5px;
}
