.no-bookmark-text {
    color: #333;
    font-size: 14px;
    /* margin: 0 15px;
    display: block; */
}

.no-bookmark-text-mobile {
    color: #333;
    font-size: 14px;
    margin: 0 15px;
    display: block;
    text-align: justify;
}

.create-bookmark-field {
    font-size: 15px;
}

.create-bookmark-input-box {
    width: 96%;
    height: 33px;
    border-radius: 5px;
    border: none;
    color: #333;
    padding-left: 1%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
}

.errorInvalidName {
    color: red;
    font-size: 14px;
    margin: 5px 0 0 0;
}

.bookmark-popup-button-wrapper {
    padding: 2%;
    float: right;
    width: 32%;
    display: flex;
    justify-content: space-between;
}

.no-bookmark-heading {
    color: #0074B0;
    font-size: 16px;
    display: block;
    /* margin: 0 0 0 15px; */
    font-weight: 500;
}

.saved-bookmark-text-mobile {
    color: #333;
    font-size: 14px;
    text-align: center;
    display: block;
}

.actionItems-td-mobile {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    width: 100% !important;
    align-content: center;
}

.actionItems-mobile {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.bookmarkModal-buttonsContainer {
    padding: 3% 0px;
    text-align: center;
}

.dublicateModal-text {
    font-size: 15px;
}

.expandContentPopUp.modal-lg {
    max-width: 80vw;
}

.contentTextarea {
    width: 100%;
    height: 80vh;
    margin: 0;
    font-size: 14px;
    resize: none;
    border: 1px solid #bacef3;
    border-radius: 5px;
}

.expandContentCancel {
    display: flex;
    flex-direction: row-reverse;
    margin-top: -21px;
    margin-right: -21px;
}

.expandContentCancel>img {
    cursor: pointer;
}

.comment-list-view-wrapper>tbody>tr>td,
.comment-list-view-wrapper>tr>th {
    padding: 0 10px;
}

.comment-list-view-wrapper>tbody>tr>td:last-child,
.comment-list-view-wrapper>tr>th:last-child {
    padding-right: 0px;
}

.comment-list-view-wrapper>tbody>tr>td:first-child,
.comment-list-view-wrapper>tr>th:first-child {
    padding-left: 0px;
}
.reading-list-button-wrapper1 {
    padding: 2%;
    display: flex;
    width: 100%;
    justify-content: end;
    
}