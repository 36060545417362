.groupContent-310 {
    display: block;
    margin-bottom: 40px;
    animation-name: css-0, css-13;
    animation-duration: 0.367s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
    animation-fill-mode: both;
}
.navItems-309 {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
}
.navItem-308 {
    padding: 0px;
}
.compositeLink-304 {
    display: flex;
    position: relative;
    color: rgb(50, 49, 48);
    margin: 5px;
}
.chevronButton-314 {
    outline: transparent;
    position: absolute;
    font-size: 12px;
    font-weight: 400;
    display: block;
    text-align: left;
    line-height: 44px;
    margin: 0px;
    padding: 0px;
    border: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    color: rgb(50, 49, 48);
    background-color: transparent;
    width: 26px;
    height: 42px;
    top: 1px;
    left: 1px;
    z-index: 1;
}
.link-486 {
    outline: transparent;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 0px 20px 0px 27px;
    text-decoration: none;
    text-align: center;
    height: 44px;
    color: rgb(0, 120, 212);
    background-color: transparent;
    width: 100%;
    line-height: 44px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
}
.flexContainer-320 {
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.compositeLink-304-selected{
  background-color:rgb(237, 235, 233);
  display: flex;
  margin: 5px;
}
.subfolder{
    margin-left: 0px;
}
.Mui-expanded{
    color:rgb(0, 120, 212);
    background-color: none;
}
.Mui-selected{
    background-color: none;
}
.MuiTreeItem-label{
    font-size: 14px !important;
    font-weight: 400;
    text-align: center;
    line-height: 44px;
    white-space: nowrap;
    text-align: justify;
}
.Mui-selected{
    background-color: #ffff !important;
}