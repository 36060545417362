.gen-ai-image-container-1 {
    display: flex;
    justify-content: center;
}

.gen-ai-image-container-2 {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    cursor: grab;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 4%;
}

.gen-ai-authoring-img {
    max-width: 100%;
    max-height: calc(100vh - 170px);
    ;
}

.tools {
    display: flex;
    justify-content: end;
    margin-top: 2%;
    width: 100%;
}

.tools-container {
    display: flex;
    justify-content: space-evenly;
    /* width: 26%; */
}

.tools-container>button {
    margin: 2px;
}