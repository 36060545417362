.authoring-gpt-preview-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.authoring-gpt-preview-label {
    color: #203864;
    font-size: 15px;
    font-weight: 600;
}

.authoring-gpt-preview-label {
    height: 35px;
}

.authoring-gpt-preview-wrapper {
    height: 100%;
}

.authoring-gpt-preview-panel {
    height: calc(100% - 26px);
}

.authoring-gpt-excel-preview-panel {
    height: calc(100% - 63px);
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-width: thin;
}

.authoring-gpt-excel-preview-panel ::-webkit-scrollbar {
    height: 10px !important
}

.authoring-summery-text-to-edit-wrapper-gpt {
    height: 15%;
}

.authoring-summery-text-to-edit-wrapper-gpt,
.authoring-summery-result-wrapper-gpt {
    margin-bottom: 2px;
}

.authoring-text-to-edit-label-gpt>span,
.authoring-text-to-edit-label-gpt>.authoring-all-text-select-gpt>span,
.authoring-command-label-gpt>span,
.authoring-result-label-gpt>span {
    color: #203864;
    font-size: 15px;
}

.authoring-text-to-edit-label-gpt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.authoring-all-text-select-gpt {
    display: flex;
    margin-left: 15px;
    margin-right: auto;
    margin-bottom: 1px;
}

.authoring-all-text-select-gpt>span {
    margin-left: 3px
}

.authoring-all-text-select-gpt>input {
    margin-bottom: 3.5px;
}

.authoring-text-to-edit-panel-gpt {
    height: calc(100% - 25px);
}

.authoring-text-to-edit-panel-gpt>.txt-authoring-summarize {
    width: 100%;
    margin: 0;
    height: 100%;
    font-size: 14px;
    resize: none;
    border-width: 1px;
    border-radius: 5px;
    border-color: #bacef3;
    ;
}

.authoring-summery-comment-send-save-notebook-wrapper-gpt {
    height: 20%;
}

.authoring-summery-comment-send-save-notebook-wrapper-gpt {
    margin-bottom: 2px;
}

.authoring-command-wrapper-panel-gpt {
    background-color: #E7E6E6;
    padding: 1%;
    height: calc(100% - 25px);
    border-radius: 5px;
    border: 1px solid #bacef3;
}

.authoring-command-dropdown-gpt {
    width: 100%;
    border-radius: 3px;
    padding: 3px;
    font-size: 14px;
    margin: 0px 0px 5px 0px;
    border: none;
}

.authoring-summery-comment-send-gpt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    height: calc(100% - 28px);
}

.authoring-comment-panel-gpt {
    width: 73%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
}

.authoring-comment-panel-gpt>textarea {
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 14px;
    resize: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: none;
}

.authoring-send-panel-gpt {
    width: 27%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.authoring-send-panel-gpt>button {
    width: 90%;
    padding: 3px;
}

.authoring-summery-result-wrapper-gpt {
    height: 43%;
}

.authoring-result-panel-gpt {
    height: calc(100% - 25px);
    border: 1px solid #bacef3;
    border-radius: 5px;
}

.authoring-summery-citation-wrapper-gpt {
    height: 15%;
}

.authoring-summery-comment-save-notebook-gpt {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 6.5%;
}

.ai-authoring-summery-btn-expand-notebook-gpt {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.authoring-text-to-edit-label-gpt,
.authoring-all-text-select-gpt,
.authoring-command-label-gpt,
.authoring-result-label-gpt {
    color: #203864;
    font-size: 15px;
    font-weight: 600;
}

.authoring-comment-panel-gpt>span,
.message-show-more-less,
.authoring-send-panel-gpt>span,
.add-to-notebook-action-text-gpt {
    font-weight: 600;
    font-size: 14px;
}

.authoring-summery-comment-save-notebook-gpt>.authoring-summery-btn-restart>button,
.authoring-summery-comment-save-notebook-gpt>.ai-authoring-summery-btn-expand-notebook-gpt>button,
.authoring-send-panel-gpt>button {
    width: 150px;
    height: 24px;
    font-weight: 600;
}

.authoring-gpt-file-upload {
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 98%;
    min-height: 30px;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.summarize-count {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.summarize-count-font {
    font-size: 15px;
    font-weight: 600;
}

.summarize-count-color {
    color: red;
}

@media screen and (min-height: 495px) and (max-height: 599px) {
    .authoring-summery-result-wrapper-gpt {
        height: 35.5%;
    }

    .authoring-summery-comment-send-save-notebook-wrapper-gpt {
        height: 27%;
    }

    div.authoring-summery-comment-save-notebook-gpt>button.btnReset>svg {
        width: 13px;
    }
}

@media screen and (min-height: 600px) and (max-height: 699px) {
    .authoring-summery-result-wrapper-gpt {
        height: 35.5%;
    }

    .authoring-summery-comment-send-save-notebook-wrapper-gpt {
        height: 27%;
    }

    .authoring-send-panel-gpt>button {
        width: 95%;
    }
}

@media screen and (min-height: 700px) and (max-height: 799px) {
    .authoring-summery-result-wrapper-gpt {
        height: 35.5%;
    }

    .authoring-summery-comment-send-save-notebook-wrapper-gpt {
        height: 27%;
    }

}

@media screen and (min-width: 1001px) and (max-width: 1100px) {

    .authoring-comment-panel-gpt>span,
    .message-show-more-less,
    .authoring-send-panel-gpt>span,
    .add-to-notebook-action-text-gpt {
        font-weight: 600;
        font-size: 10px;
    }

    .authoring-summery-comment-save-notebook-gpt>.authoring-summery-btn-restart>button,
    .authoring-summery-comment-save-notebook-gpt>.ai-authoring-summery-btn-expand-notebook-gpt>button,
    .authoring-send-panel-gpt>button {
        width: 120px;
        height: 24px;
        font-weight: 600;
        font-size: 10px;
    }

    .summarize-count-font {
        font-size: 10px;
        font-weight: 600;
    }

}

@media screen and (min-width: 1101px) and (max-width: 1200px) {

    .authoring-comment-panel-gpt>span,
    .message-show-more-less,
    .authoring-send-panel-gpt>span,
    .add-to-notebook-action-text-gpt {
        font-weight: 600;
        font-size: 12px;
    }

    .authoring-summery-comment-save-notebook-gpt>.authoring-summery-btn-restart>button,
    .authoring-summery-comment-save-notebook-gpt>.ai-authoring-summery-btn-expand-notebook-gpt>button,
    .authoring-send-panel-gpt>button {
        width: 130px;
        height: 22px;
        font-weight: 600;
        font-size: 12px;
    }

    .summarize-count-font {
        font-size: 12px;
        font-weight: 600;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {

    .authoring-comment-panel-gpt>span,
    .message-show-more-less,
    .authoring-send-panel-gpt>span,
    .add-to-notebook-action-text-gpt {
        font-weight: 600;
        font-size: 12px;
    }

    .authoring-summery-comment-save-notebook-gpt>.authoring-summery-btn-restart>button,
    .authoring-summery-comment-save-notebook-gpt>.ai-authoring-summery-btn-expand-notebook-gpt>button,
    .authoring-send-panel-gpt>button {
        width: 140px;
        height: 22px;
        font-weight: 600;
        font-size: 12px;
    }

    .summarize-count-font {
        font-size: 12px;
        font-weight: 600;
    }
}


.previewExcelTBodyTD {
    background-color: #ffffff !important;
}

.previewExcelTBodyTD>span {
    color: #000000;
}

.previewExcelTHeaderTh {
    background: #f5f5f5 !important;
}

.previewExcelTHeaderTh>span {
    color: #999 !important;
}

.data-grid-container .data-grid .cell.selected {
    border: 1px double rgb(33, 133, 208);
    transition: none;
    box-shadow: inset 0 -1000px 0 rgba(33, 133, 208, 0.15);
}


.react-tabs__tab-list {
    border-top: 1px solid #aaa !important;
    margin: 0 0 0px !important;
    padding: 0;
    border-bottom: none !important;
    font-size: 14px;
}

.react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 0px 0px 5px 5px !important;
    border-bottom: 1px solid #aaa !important;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-top: none !important;
    top: -1px;
    bottom: 0px !important;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.authoring-gpt-command-label,
.authoring-command-label-gpt,
.authoring-command-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.authoring-model-body-disclaimer-cross{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.authoring-model-body-disclaimer-cross > img{
    cursor: pointer;
}
.authoring-model-body-content{
    padding: 5px;
}
.authoring-gpt-result-label,.authoring-result-label-gpt{
    display: flex;
    justify-content: space-between;
}
.authoring-disclaimer-model > .modal-dialog{
    width: 440px !important;
}
.authoring-model-body-content-link{
    font-weight: 600;
    font-size: 15px;
}