.ai-notebook-doc-preview-wrapper,
.answering-doc-preview-wrapper {
    display: flex;
    flex-flow: column;
    border: 1px solid #d3d3d3;
    padding: 10px 15px;
    /* margin: 0 10px 10px; */
    /* z-index: 900; */
    z-index: 1001;
    /* position: absolute; */
    right: 0;
    background: #fff;
    /* width: 800px; */
    /* height: 590px; */
    height: 650px;
    overflow-y: auto;
    /* position: fixed; */
    top: 65px;
    /* top: 15%; */
    flex-direction: row;
    /* width: 85%; */
    width: 100%;
}

.doc-preview-title-wrapper {
    font-size: 14px;
    color: #333;
    font-family: 'J&J CircularTT-Medium';
    padding: 0px 5px 5px;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.doc-preview-loader-wrapper {
    height: 634px;
    /* width: 490px; */
    align-items: center;
    justify-content: center;
    display: flex;
}

.doc-preview-pagination-wrapper {
    font-size: 14px;
    color: #333;
    font-family: 'J&J CircularTT-Medium';
    display: flex;
    width: 30%;
    justify-content: space-evenly;
    margin: 15px 0;
    cursor: pointer;
    align-items: center;
}

.doc-preview-inner-wrapper-notebook-ppt {
    border: 1px solid #d3d3d3;
    overflow-y: auto;
    height: 390px;
}

.doc-preview-inner-wrapper-notebook {
    border: 1px solid #d3d3d3;
    /* overflow-y: scroll; */
    overflow-y: auto;
    /* height: 475px; */
    height: 525px;
    /* width: 530px; */
    scrollbar-width: thin;
}

.doc-preview-inner-wrapper-notebook-ppt::-webkit-scrollbar,
.doc-preview-inner-wrapper-notebook::-webkit-scrollbar,
.doc-preview-inner-wrapper::-webkit-scrollbar {
    height: 10px !important
}


.ai-notebook-doc-preview-wrapper-left {
    /* width: 37%; */

    width: calc(50% - 10px);
    /* width: 235px; */
    margin-left: 10px;
}

.ai-notebook-doc-preview-wrapper-right {
    /* width: 63%; */
    width: 50%;
    /* width: 530px; */
}

.doc-preview-input-box-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: left;
}

.doc-preview-input-search-box {
    width: 100%;
    height: 30px;
    border: 2px solid #d8d8d8 !important;
    font-family: 'J&J CircularTT-Medium';
    outline: none;
    font-size: 15px;
    float: left;
    position: relative;
    color: #333;
    /* box-shadow: 0px 3px 6px #00000029; */
    opacity: 1;
    border-radius: 4px;
    padding: 1% 12% 1% 1%;
    letter-spacing: 0.02px;
    /* border: none; */
    margin-top: 5px;
    padding-right: 50px;
}

.doc-preview-icon-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: 0;
    width: 70px;
    background-color: transparent;
    padding: 0;
    height: 30px;
    margin-top: 5px;
    /* justify-content: space-around; */
    /* padding-left: 7px; */
    /* right: 1px; */
}

.doc-preview-input-search-cancel-icon {
    cursor: pointer;
    width: 12px;
    height: 12px;
    margin: 0 6%;
}

.doc-preview-input-box-title-wrapper {
    text-align: left;
}

.doc-preview-search-icon-wrapper-webView {
    cursor: pointer;
    background: #0074B0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    padding: 5%;
    height: 100%;
    margin-left: 10%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.doc-preview-warning-msg {
    text-align: left;
    color: red;
    margin-left: 2px;
    font-size: 14px;
}

.doc-preview-search-results-title {
    margin-top: 10px;
    text-align: left;
    display: flex;
    flex-direction: row;
}

.doc-preview-clear-search {
    z-index: 100000000;
    /*width:100%;*/
    display: flex;
    justify-content: flex-end;
    /*margin-top: 5px;*/
}

.doc-preview-clear-search>button,
.doc-preview-clear-search-bar>button {
    color: #FFFFFF;
    background: #0074B0 0% 0% no-repeat padding-box;
    border-radius: 4px;
}

.doc-preview-keword-title {
    margin: 5px 0px;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.doc-preview-keword-title>img {
    margin-bottom: 4px;
    margin-right: 10px;
}

.doc-preview-search-results-split {
    border-bottom: 2px solid #d8d8d8;
    width: 98%;
    margin-top: 10px;
}

.apexcharts-menu-icon {
    display: none;
}

#chart>div>div>svg>foreignObject {
    display: none;
}

.doc-preview-page-count-results-wrapper {
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
}

.doc-preview-keword-color-box {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 3px;

}

.doc-preview-OrderBy-wrapper {
    display: flex;
    font-size: 14px;
}

.doc-preview-OrderBy-wrapper>label {
    margin: 0 5px;
}

.doc-preview-OrderBy-wrapper>label>input[type="radio"] {
    margin: 1px 2px;
    vertical-align: middle;
}

.showSearch {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -72px -24px;
    /* margin-right: 50px !important; */
}

.documentAiViewerbutton:hover,
.documentzoomPercentagebutton:hover {
    background-color: #F0F0F0;

}

.documentAiViewerbutton:active {
    background-color: #E0E0E0;
}

.documentAiViewerbutton {
    width: 25px;
    height: 25px;
    margin: auto 1px;
    background-color: #fff;
}

.doc-preview-controle-wrapper {
    display: flex;
    justify-content: space-between;
}

.doc-preview-search-zoom-wrapper {
    margin: auto 0;
}

.collapse-button {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: 0px -72px;
}

.doc-preview-zoom-wrapper {
    display: flex;
    margin: auto 0;
}

.zoomIn {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -24px -24px;
}

.zoomOut {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -48px -24px;
}


.doc-preview-zoom-inner {
    width: 100px;
}

.documentzoomPercentagebutton {
    padding: 5px;
    background-color: #fff;
    text-align: center;
}




.slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
}

.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 7px;
    /* Specified height */
    background: #e6e6e6;
    /* Grey background */
    outline: none;
    /* Remove outline */
    /* opacity: 0.7;  Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border: 1px solid #ccc;
    /* box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%); */
    border-radius: 30px;
    margin-top: 11px;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 15px;
    /* Set a specific slider handle width */
    height: 15px;
    /* Slider handle height */
    border-radius: 30px;

    cursor: pointer;
    /* Cursor on hover */
    background: #fff;
    border: 1px solid #ccc;
    /* transform: translate3d(-50%, -50%, 0); */
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%);
}


.slider::-moz-range-thumb {
    width: 15px;
    /* Set a specific slider handle width */
    height: 15px;
    /* Slider handle height */
    border-radius: 30px;

    cursor: pointer;
    /* Cursor on hover */
    background: #fff;
    border: 1px solid #ccc;
    /* transform: translate3d(-50%, -50%, 0); */
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%);
}


.zoomFitWidth {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -48px -72px;
}

.zoomFitPage {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -72px -72px;
}

.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
    margin: 0 auto;
}

.txtSummarize,
.txtNotbook {
    width: 100%;
    margin: 0;
    height: 200px;
    font-size: 14px;
    resize: none;
    border-width: 1px;
}

@media screen and (min-height: 450px) and (max-height: 500px) {

    .doc-preview-loader-wrapper {
        align-items: baseline;
    }
}

@media screen and (min-height: 385px) and (max-height: 450px) {
    .doc-preview-loader-wrapper {
        align-items: baseline;
    }
}

@media screen and (min-height: 355px) and (max-height: 385px) {
    .doc-preview-loader-wrapper {
        align-items: baseline;
    }
}


@media screen and (min-width: 1240px) and (max-width: 1300px) {
    .doc-preview-OrderBy-wrapper {
        font-size: 13px;
    }
}

@media screen and (min-width: 1180px) and (max-width: 1240px) {
    .doc-preview-OrderBy-wrapper {
        font-size: 12px;
    }
}

@media screen and (min-width: 1125px) and (max-width: 1180px) {
    .doc-preview-OrderBy-wrapper {
        font-size: 11px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1125px) {
    .doc-preview-OrderBy-wrapper {
        font-size: 10px;
    }
}

@media screen and (min-height: 400px) and (max-height: 499px) {

    .txtSummarize,
    .txtNotbook {
        width: 100%;
        margin: 0;
        height: 100px;
        font-size: 14px;
        resize: none;
        border-width: 1px;
    }
}

@media screen and (min-height: 500px) and (max-height: 600px) {

    .txtSummarize,
    .txtNotbook {
        width: 100%;
        margin: 0;
        height: 140px;
        font-size: 14px;
        resize: none;
        border-width: 1px;
    }
}

/* @media screen and (min-height: 651px) and (max-height: 750px) {
    .txtSummarize, .txtNotbook {
        width: 100%;
        margin: 0;
        height: 160px;
        font-size: 14px;
        resize: none;
        border-width: 1px;
    }
}
@media screen and (min-height: 751px) {
    .txtSummarize, .txtNotbook {
        width: 100%;
        margin: 0;
        height: 200px;
        font-size: 14px;
        resize: none;
        border-width: 1px;
    }
} */
.nlp-doc-preview-loader-wrapper {
    /* height: 634px; */
    /* width: 490px; */
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

/* .react-pdf__Page__canvas{
    margin: 0 auto;
}
.react-pdf__Page__textContent{
    margin: 0 auto;
}
.react-pdf__Page__textContent{
    top: 49% !important;
} 

.react-pdf__Page__svg {
    margin: 0 auto;
  }*/

/* .react-pdf__Page__canvas {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, 0%) !important;
}

.react-pdf__Page__textContent {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, 0%) !important;
} */
.btnSelectNotebook,
.btnNotebook,
.btnSummarize,
.btnAddText,
.btnReplaceText,
.btnExpandAllFields,
.btnClearAllSearches {

    margin: 0 auto;
    cursor: pointer;
    background: #0074B0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    padding: 4px 5px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 24px;
    color: #FFFFFF;
    font-size: 14px;
}

.btnClearAllSearches {
    height: 24px !important;
}

/* .btnExpandAllFields {
    margin-left: 31%;
} */

/* .btnAddText {
    margin-right: 20px;
} */

.notebook-notbook-btn,
.notebook-summery-btn {
    margin-top: 8px;
    margin-bottom: 10px;
}

.actionItem-headerPart-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.actionItem-headerPart-wrapper>.actionItem-headerPart {
    /* width: 90%; */
    width: 100%;
}

.actionItem-headerPart-wrapper>.doc-preview-input-box-title-wrapper {
    width: 10%;
}

.notebook-wrapper {
    width: 100%;
}

.notebook-summery-notbook-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.notebook-summery-wrapper,
.notebook-notbook-wrapper {
    /* width: 49%; */
}

.ai-notebook-doc-preview-wrapper-left>hr {
    margin: 0.25rem 0;
}

.active-notebook-tab,
.unactive-notebook-tab {
    text-align: center;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    cursor: pointer;
    font-size: 14px;
    width: 180px;
    text-align: center;
}

.active-notebook-tab {

    color: #fff;
    background: #fa9546;

}

.unactive-notebook-tab {
    border: 1px solid #2a498a;
    color: #2a498a;
}

@media screen and (max-width: 1360px) {
    @media screen and (min-height: 400px) and (max-height: 499px) {
        .btnAddText {
            padding: 2px 2px;
            width: 100px;
            margin-right: 0;
        }

        .doc-preview-zoom-inner {
            width: 85px;
        }
    }

    @media screen and (min-height: 500px) and (max-height: 599px) {

        .txtSummarize,
        .txtNotbook {
            width: 100%;
            margin: 0;
            height: 120px;
            font-size: 14px;
            resize: none;
            border-width: 1px;
        }
    }

    @media screen and (min-height: 600px) and (max-height: 649px) {

        .txtSummarize,
        .txtNotbook {
            width: 100%;
            margin: 0;
            height: 140px;
            font-size: 14px;
            resize: none;
            border-width: 1px;
        }
    }
}

@media screen and (max-width: 1203px) {
    @media screen and (min-height: 400px) and (max-height: 499px) {
        .btnAddText {
            padding: 2px 2px;
            width: 100px;
            margin-right: 0;
        }

        .doc-preview-zoom-inner {
            width: 85px;
        }
    }

    @media screen and (min-height: 500px) and (max-height: 599px) {
        .btnAddText {
            padding: 2px 2px;
            width: 100px;
            margin-right: 0;
        }

        .doc-preview-zoom-inner {
            width: 85px;
        }
    }

    @media screen and (min-height: 600px) and (max-height: 699px) {
        .btnAddText {
            padding: 2px 2px;
            width: 100px;
            margin-right: 0;
        }

        .doc-preview-zoom-inner {
            width: 85px;
        }
    }
}

@media screen and (min-width: 1000px) and (max-width: 1300px) {
    .doc-preview-zoom-inner {
        width: 85px;
    }

}

.txtPageNumber {
    margin-right: 5px;
    font-size: 13px;
    text-align: center;
    width: 100%;
    height: 30px;
    border: 2px solid #d8d8d8 !important;
    font-family: 'J&J CircularTT-Medium';
    outline: none;
    font-size: 15px;
    float: left;
    position: relative;
    color: #333;
    /* box-shadow: 0px 3px 6px #00000029; */
    opacity: 1;
    border-radius: 4px;
    padding: 1% 12% 1% 1%;
    letter-spacing: 0.02px;
    padding-right: 30px;
    /* border: none; */
    /* margin-top: 5px; */
    /* padding-right: 50px; */

    user-select: none;
    /* supported by Chrome and Opera */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */

}

.pagenumber-input-box-wrapper {
    position: relative;
    display: inline-block;
    width: 58px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: left;
}

.pagenumber-icon-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: 0;
    width: 30px;
    background-color: transparent;
    padding: 0;
    height: 30px;
    /* margin-top: 5px; */
    /* justify-content: space-around; */
    /* padding-left: 7px; */
    /* right: 1px; */
}

.pagenumber-icon-wrapper-webView {
    cursor: pointer;
    background: #0074B0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    padding: 5%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.ai-doc-preview-title-wrapper {
    font-size: 14px;
    color: #333;
    font-family: 'J&J CircularTT-Medium';
    padding: 0px 5px 5px;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    height: 25px;
}

.ai-doc-preview-controle-wrapper {
    display: flex;
    justify-content: space-between;
    height: 50px;
}

.doc-preview-inner-wrapper-notebook {
    height: calc(100% - 70px);
}

.doc-preview-inner-wrapper-notebook-ppt {
    height: calc(100% - 70px);
}

.ai-notebook-doc-preview-wrapper,
.answering-doc-preview-wrapper {
    height: calc(100vh - 10px);
}

.authoring-popup-transition>.modal-content {
    height: calc(100vh - 60px);
}

.answering-popup-transition>.modal-content {
    height: calc(100vh - 60px);
}
.authoring-gpt-popup-transition>.modal-content {
    height: calc(100vh - 10px);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
    width: 80%;
}

.authoring-summery-select-notebook {
    margin-top: 15px;
}

.btn-authoring-gpt-btn-expandall,
.authoring-summery-citation-link {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.btn-authoring-gpt-btn-expandall {
    justify-content: center;
}

.authoring-summery-citation-link>a {
    text-decoration: none;
    font-weight: 600;
    color: #0074B0;
}

.authoring-summery-citation-link>a:hover {
    text-decoration: underline;
}

.btnSelectNotebook {
    font-weight: 600;
}

.add-to-command-action-gpt,
.authoring-summery-gpt-citation-link {
    font-weight: 600;
    font-size: 14px;
}

.authoring-summery-select-notebook>.authoring-summery-select-notebook-txt>.txtSelectNotebook,
.authoring-gpt-command-dropdown {
    height: 24px;
}

@media screen and (min-width: 1701px) {
    .doc-preview-pagination-page-number {
        text-align: center;
    }

    .btnAddText {
        margin-right: 0px;
        width: 120px;
        padding: 5px 2px;
        font-size: 13px;
        margin: 0 2px;
    }

    .btnReplaceText {
        width: 90px;
        padding: 5px 2px;
        font-size: 13px;
        margin: 0 2px;
    }

    .btnAddText {
        margin-right: 20px;
    }

    div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
        width: 80%;
    }

    /* .authoring-gpt-summery-result-wrapper {
        height: 80%;
    } */
    /* .btn-authoring-gpt-btn-clearall,
    .authoring-summery-select-notebook{
        margin-top: 25px;
    }
    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link{
        margin-top:35px;
    } */
}


@media screen and (min-width: 1701px) {
    .doc-preview-pagination-page-number {
        text-align: center;
    }

    .btnAddText {
        margin-right: 0px;
        width: 110px;
        padding: 5px 2px;
        font-size: 11px;
        margin: 0 2px;
    }

    .btnReplaceText {
        width: 85px;
        padding: 5px 2px;
        font-size: 11px;
        margin: 0 2px;
    }

    .btnAddText {
        margin-right: 20px;
    }

    div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
        width: 80%;
    }

    /* .authoring-gpt-summery-result-wrapper {
        height: 80%;
    } */
    /* .btn-authoring-gpt-btn-clearall,
    .authoring-summery-select-notebook{
        margin-top: 25px;
    }
    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link{
        margin-top:35px;
    } */
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
    .doc-preview-pagination-wrapper {
        width: 35%;
    }

    .doc-preview-pagination-page-number {
        text-align: center;
    }

    .btnAddText {
        margin-right: 0px;
        width: 110px;
        padding: 5px 2px;
        font-size: 11px;
        margin: 0 2px;
    }

    .btnReplaceText {
        width: 85px;
        padding: 5px 2px;
        font-size: 11px;
        margin: 0 2px;
    }

    div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
        width: 80%;
    }

    .authoring-gtp-add-to-command {
        width: 150px;
    }

    .authoring-gpt-command-dropdown,
    .authoring-summery-select-notebook-txt {
        width: 380px;
    }

    .btn-authoring-summery-gpt,
    .btnSelectNotebook,
    .btn-authoring-gpt-btn-clearall,
    .btn-authoring-gpt-btn-expandall {
        width: 180px !important
    }
}

@media screen and (min-width: 1501px) and (max-width: 1600px) {
    .doc-preview-pagination-page-number {
        text-align: center;
    }

    .btnAddText {
        margin-right: 0px;
        width: 105px;
        padding: 5px 2px;
        font-size: 11px;
        margin: 0 2px;
    }

    .btnReplaceText {
        width: 80px;
        padding: 5px 2px;
        font-size: 11px;
        margin: 0 2px;
    }

    div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
        width: 80%;
    }

    .authoring-gtp-add-to-command {
        width: 150px;
    }

    .authoring-gpt-command-dropdown,
    .authoring-summery-select-notebook-txt {
        width: 350px;
    }

    .btn-authoring-summery-gpt,
    .btnSelectNotebook,
    .btn-authoring-gpt-btn-clearall,
    .btn-authoring-gpt-btn-expandall {
        width: 170px !important
    }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
    .doc-preview-pagination-wrapper {
        width: 35%;
    }

    .doc-preview-pagination-page-number {
        text-align: center;
    }

    .documentAiViewerbutton {
        width: 18px;
        height: 25px;
        margin: auto 1px;
        background-color: #fff;
    }

    .btnAddText {
        margin-right: 0px;
        width: 105px;
        padding: 5px 2px;
        font-size: 11px;
        margin: 0 2px;
    }

    .btnReplaceText {
        width: 76px;
        padding: 5px 2px;
        font-size: 11px;
        margin: 0 2px;
    }

    .doc-preview-zoom-inner {
        width: 80px;
    }

    div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
        width: 80%;
    }

    .authoring-gtp-add-to-command {
        width: 160px;
    }

    .authoring-gpt-command-dropdown,
    .authoring-summery-select-notebook-txt {
        width: 330px;
    }

    .btn-authoring-summery-gpt,
    .btnSelectNotebook,
    .btn-authoring-gpt-btn-clearall,
    .btn-authoring-gpt-btn-expandall {
        width: 160px !important
    }
}

@media screen and (min-width: 1301px) and (max-width: 1400px) {
    .doc-preview-pagination-wrapper {
        width: 35%;
    }

    .doc-preview-pagination-page-number {
        text-align: center;
    }

    .documentAiViewerbutton {
        width: 18px;
        height: 25px;
        margin: auto 1px;
        background-color: #fff;
    }

    .btnAddText {
        margin-right: 0px;
        width: 95px;
        padding: 5px 2px;
        font-size: 9px;
        margin: 0 2px;
    }

    .btnReplaceText {
        width: 60px;
        padding: 5px 2px;
        font-size: 9px;
        margin: 0 2px;
    }

    .doc-preview-zoom-inner {
        width: 80px;
    }

    div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
        width: 80%;
    }

    .authoring-gtp-add-to-command {
        width: 150px;
    }

    .authoring-gpt-command-dropdown,
    .authoring-summery-select-notebook-txt {
        width: 300px;
    }

    .btn-authoring-summery-gpt,
    .btnSelectNotebook,
    .btn-authoring-gpt-btn-clearall,
    .btn-authoring-gpt-btn-expandall {
        width: 150px !important
    }
}




@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .doc-preview-pagination-wrapper {
        width: 35%;
    }

    .doc-preview-pagination-page-number {
        text-align: center;
    }

    .btnAddText {
        width: 75px;
        margin-left: 2px;
        padding: 5px 2px;
        font-size: 10px;
        margin: 0 2px;
    }

    .documentAiViewerbutton {
        width: 18px;
        height: 25px;
        margin: auto 1px;
        background-color: #fff;
    }

    .btnReplaceText {
        width: 45px;
        padding: 5px 2px;
        font-size: 10px;
        margin: 0 2px;
    }

    .doc-preview-zoom-inner {
        width: 80px;
    }

    div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
        width: 70%;
    }

    .btn-authoring-gpt,
    .btnSelectNotebook,
    .btn-authoring-summery-gpt,
    .add-to-command-action-gpt,
    .authoring-gpt-command-dropdown,
    .authoring-summery-gpt-citation-link,
    .authoring-summery-select-notebook>.authoring-summery-select-notebook-txt>.txtSelectNotebook {
        font-size: 12px;
    }

    .btnSelectNotebook {
        width: 105px;
    }

    .authoring-gtp-add-to-command {
        width: 140px;
    }

    .authoring-gpt-command-dropdown,
    .authoring-summery-select-notebook-txt {
        width: 280px;
    }

    .btn-authoring-summery-gpt,
    .btnSelectNotebook,
    .btn-authoring-gpt-btn-clearall,
    .btn-authoring-gpt-btn-expandall {
        width: 140px !important
    }
}

@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .doc-preview-pagination-wrapper {
        width: 35%;
    }

    .doc-preview-pagination-page-number {
        text-align: center;
    }


    .btnAddText {
        width: 60px;
        margin-left: 2px;
        font-size: 9px;
    }

    .btnReplaceText {
        width: 41px;
        font-size: 9px;
    }

    .documentAiViewerbutton {
        width: 18px;
        height: 22px;
    }

    .btnNotebook,
    .btnSummarize,
    .btnAddText,
    .btnReplaceText,
    .btnExpandAllFields {
        margin: 0 2px;
    }

    .doc-preview-zoom-inner {
        width: 55px;
    }

    div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
        width: 70%
    }

    .btn-authoring-gpt,
    .btnSelectNotebook,
    .btn-authoring-summery-gpt,
    .add-to-command-action-gpt,
    .authoring-gpt-command-dropdown,
    .authoring-summery-gpt-citation-link,
    .authoring-summery-select-notebook>.authoring-summery-select-notebook-txt>.txtSelectNotebook {
        font-size: 12px;
    }

    .authoring-gtp-add-to-command {
        width: 120px;
    }

    .authoring-gpt-command-dropdown,
    .authoring-summery-select-notebook-txt {
        width: 220px;
    }

    .btn-authoring-summery-gpt,
    .btnSelectNotebook,
    .btn-authoring-gpt-btn-clearall,
    .btn-authoring-gpt-btn-expandall {
        width: 140px !important
    }

}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
    .doc-preview-pagination-wrapper {
        width: 35%;
    }

    .doc-preview-pagination-page-number {
        text-align: center;
    }

    .btnAddText {
        width: 60px;
        margin-left: 1px;
        font-size: 9px;
    }

    .btnReplaceText {
        width: 43px;
        font-size: 9px;
        margin-left: 1px;
    }

    .doc-preview-zoom-inner {
        width: 40px;
    }

    .documentAiViewerbutton {
        width: 18px;
        height: 22px;
    }

    .documentzoomPercentagebutton {
        padding: 5px 2px;
    }

    /* .btnExpandAllFields {
        margin-left: 10%;
    } */
    .pagenumber-input-box-wrapper {
        width: 35px !important;

    }

    .pagenumber-icon-wrapper {
        width: 18px !important;
    }

    .txtPageNumber {
        padding-right: 15px !important;
    }

    .btn-authoring-gpt,
    .btnSelectNotebook,
    .btn-authoring-summery-gpt,
    .add-to-command-action-gpt,
    .authoring-gpt-command-dropdown,
    .authoring-summery-gpt-citation-link,
    .authoring-summery-select-notebook>.authoring-summery-select-notebook-txt>.txtSelectNotebook {
        font-size: 10px;
    }

    .authoring-gtp-add-to-command {
        width: 120px;
    }

    .authoring-gpt-command-dropdown,
    .authoring-summery-select-notebook-txt {
        width: 200px;
    }

    .btn-authoring-summery-gpt,
    .btnSelectNotebook,
    .btn-authoring-gpt-btn-clearall,
    .btn-authoring-gpt-btn-expandall {
        width: 130px !important
    }

}

.doc-preview-inner-wrapper-notebook-ppt>.react-pdf__Document {
    margin-top: 15%;
}

div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
    width: 80%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 20px;
    color: #0074b0;
    text-decoration: none;
}

div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a:hover {
    text-decoration: underline;
}

.tab-list-item-notebook {
    width: 20%;
    padding: 2px;
    margin-right: 10px;
    background-color: #ddd;
    border: 1px solid;
    border-color: #ddd #ddd transparent;
    color: #5A5A5A;
    border-radius: 4px 4px 0 0;
}

.tab-list-item-notebook-active {
    background-color: #fa9546;
    color: #ffffff;
    border-color: #d8d8d8 #d8d8d8 transparent;
}

.doc-tab-list-item-notebook-split {
    border-bottom: 2px solid #d8d8d8;
    width: 100%;
    margin-top: -1.5px;
}

.tabpanel-list-item-notebook {
    height: calc(100vh - 65px);
}

.tablist-item-notebook {
    border-bottom: 2px solid #d8d8d8;
}

.doc-preview-page-count-results-wrapper-max-height {
    max-height: 440px;
}

@media screen and (min-height: 1300px) {
    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 20px;
    }

    .authoring-gtp-add-to-command {
        width: 160px;
    }

    .authoring-gpt-summery-comment-save-notebook {
        height: 10%;
    }

    .authoring-gpt-summery-result-wrapper {
        height: 69.5%;
    }
}

@media screen and (min-height: 1200px) and (max-height: 1299px) {
    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 20px;
    }

    .doc-preview-page-count-results-wrapper-max-height {
        max-height: 950px;
    }

    .authoring-gtp-add-to-command {
        width: 160px;
    }

    .authoring-gpt-summery-comment-save-notebook {
        height: 10%;
    }

    .authoring-gpt-summery-result-wrapper {
        height: 69.7%;
    }
}

@media screen and (min-height: 1100px) and (max-height: 1199px) {
    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 20px;
    }

    .doc-preview-page-count-results-wrapper-max-height {
        max-height: 850px;
    }

    .authoring-gtp-add-to-command {
        width: 160px;
    }

    .authoring-gpt-summery-comment-save-notebook {
        height: 10%;
    }

    .authoring-gpt-summery-result-wrapper {
        height: 69.7%;
    }
}

@media screen and (min-height: 1000px) and (max-height: 1099px) {
    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 20px;
    }

    .doc-preview-page-count-results-wrapper-max-height {
        max-height: 750px;
    }

    .authoring-gtp-add-to-command {
        width: 160px;
    }

    .authoring-gpt-summery-result-wrapper {
        height: 69.5%;
    }

    .authoring-gpt-summery-comment-save-notebook {
        height: 10%;
    }
}

@media screen and (min-height: 900px) and (max-height: 999px) {
    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 20px;
    }

    .doc-preview-page-count-results-wrapper-max-height {
        max-height: 650px;
    }

    .authoring-gtp-add-to-command {
        width: 160px;
    }

    .authoring-gpt-summery-result-wrapper {
        height: 67.5%;
    }

    .authoring-gpt-summery-comment-save-notebook {
        height: 12%;
    }

}

@media screen and (min-height: 800px) and (max-height: 899px) {
    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 15px;
    }

    .doc-preview-page-count-results-wrapper-max-height {
        max-height: 570px;
    }

    .authoring-gpt-summery-comment-save-notebook {
        height: 11%;
    }

    .authoring-gpt-summery-result-wrapper {
        height: 71%;
    }

    .authoring-gpt-summery-text-to-edit-wrapper {
        height: 74.5%;
    }



}

@media screen and (min-height: 700px) and (max-height: 799px) {
    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 10px;
    }

    .doc-preview-page-count-results-wrapper-max-height {
        max-height: 470px;
    }

    .authoring-gpt-summery-comment-save-notebook {
        height: 13%;
    }

    .authoring-gpt-summery-result-wrapper {
        height: 69.5%;
    }

}

@media screen and (min-height: 600px) and (max-height: 699px) {
    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 5px;
    }

    .doc-preview-page-count-results-wrapper-max-height {
        max-height: 370px;
    }

    .authoring-gpt-summery-comment-save-notebook {
        height: 14%;
    }

    .authoring-gpt-summery-result-wrapper {
        height: 69%;
    }
}

@media screen and (min-height: 550px) and (max-height: 599px) {
    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 5px;
    }

    .doc-preview-page-count-results-wrapper-max-height {
        max-height: 320px;
    }
}

@media screen and (min-height: 495px) and (max-height: 549px) {

    .btnNotebook,
    .btnSummarize,
    button.btnReset,
    .btnExpandAllFields,
    .btnClearAllSearches,
    .btn-authoring-gpt-btn-clearall,
    .btnSelectNotebook,
    .txtSelectNotebook {
        height: 24px;
        font-size: 12px;
    }

    div.authoring-summery-comment-save-notebook>button.btnReset>svg {
        width: 13px;
    }

    /* .z-index-authoring>div.modal-dialog>div.modal-content {
        margin-top: 45px;
    } */

    div.ai-notebook-doc-preview-wrapper-right>div.ai-doc-preview-title-wrapper>a {
        width: 60%;
    }

    .authoring-summery-select-notebook {
        margin-top: 5px;
    }

    .btn-authoring-gpt-btn-expandall,
    .authoring-summery-citation-link {
        margin-top: 5px;
    }

    .authoring-gpt-summery-comment-save-notebook {
        height: 20%;
    }

    .doc-preview-page-count-results-wrapper-max-height {
        max-height: 270px;
    }
}

.ai-authoring-summery-btn-expand-notebook {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.authoring-summery-btn-expand-notebook {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.authoring-summery-gpt-btn-expand-notebook {
    width: 100%;
    height: 100%;
    padding: 5px;

}

.btnExpandAllFields {
    margin-right: 10px;
}

.authoring-summery-select-notebook {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

/* .authoring-summery-select-notebook>.authoring-summery-select-notebook-btn {
    width: 25%;
} */

.authoring-summery-select-notebook>.authoring-summery-select-notebook-btn>button {
    margin: 0px !important;
}

/* .authoring-summery-select-notebook>.authoring-summery-select-notebook-txt {
    width: 45%;
} */

.authoring-summery-select-notebook>.authoring-summery-select-notebook-txt>.txtSelectNotebook {
    width: 100%;
    border-radius: 5px;
    font-weight: 600;
    background-color: #fff;
}

.notebook-preview-toast {
    top: 49% !important;
}

.btn-authoring-gpt-btn-clearall:disabled,
button.btnReset:disabled {
    background-color: gray;
    cursor: not-allowed;
}