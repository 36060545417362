.TTS-home-contents-wrapper,.TTS-mytask-contents-wrapper {
    display: flex;
    padding: 1%;
    flex-direction: row;
    min-height: 97.5vh;
}

.TSS-label-text {
    margin: 0px 4px;
    line-height: 100%;
    display: block;
    font-weight: normal;
    white-space: nowrap;
}

.TSS-left-pannel-button-style-active {
    background-color: rgb(255, 255, 255);
    color: rgb(50, 49, 48);
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 8px;
}

.TSS-left-pannel-button-style-active:hover {
    color: #333;
    background: #dbf7fd;
}

.TSS-button-style-active {
    background-color: rgb(255, 255, 255);
    color: rgb(50, 49, 48);
    padding: 5px 8px;
    height: 30px;
}

.TSS-button-style-deactive {
    background: #fff;
    color: #B4B4B4;
    padding: 5px 8px;
    height: 30px;
}

.TSS-button-style-active:hover {
    color: #333;
    background: #dbf7fd;
}

.TSS-button-style-selected {
    background: #FFE2CB;
    color: #333;
    font-weight: bold;
    padding: 5px 8px;
    height: 30px;
}

.TSS-container-header {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex-grow: "1";
    align-items: stretch;
    margin-left: 8px;
}

.intro-text {
    text-align: left;
    /*background-color: rgb(42, 73, 138);*/
    font-size: 24px;
    font-weight: 600;
    /*margin: 0 0 0 0px;*/
    color: black;
    padding: 4px;
}

.TSS-sub-wrapper-menu {
    list-style: none;
    position: absolute;
    z-index: 100;
    text-align: left;
    font-family: 'J&J CircularTT-Medium';
    font-size: 14px;
    color: #333;
    background-color: #fff;
    border-radius: 5px;
    padding: 0.7% 0 1.2% 0;
    border: 1px solid #1DA1E5;
    width: 254px;
}

.TSS-sub-wrapper-menu li {
    color: #7d7a7a;
    padding-left: 6%;
}

.TSS-sub-wrapper-menu li:hover {
    background-color: #f2f2f2;
}

.Folder-Name-Container {
    white-space: nowrap;
    padding: 0px;
    margin: 5px 0px 0px 18px;
    display: flex;
    align-items: stretch;
}

.Folder-Name-Container>span {
    color: rgb(0, 120, 212);
    /*font-size: 16px;*/
    font-weight: 400;
    outline: transparent;
    text-decoration: none;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0px;
    line-height: 36px;
}

.TSS-container-header-span {
    flex-direction: row;
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.TSS-file-or-folder-name-cloumn {
    display: flex;
    align-items: center;
}

div.Table-CXonatainer>div.react-bootstrap-table>table>thead>tr {
    border-top: 2px solid #edebe9;
    border-bottom: 2px solid #edebe9;
}

.Table-CXonatainer>.react-bootstrap-table>table>thead>tr>th {
    border: none;
}

div.Table-CXonatainer>div.react-bootstrap-table>table>tbody>tr>td {
    border: none;
}

div.Table-CXonatainer>div.react-bootstrap-table>table>tbody>tr {
    border-bottom: 1px solid #edebe9;
}

.TSS-filterMenuWrapper {
    min-height: 25%;
    font-family: 'J&J CircularTT-Medium';
    border-bottom: none;
    transition: ease 0.15s all;
    transform: initial;
    float: left;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgb(237, 235, 233);
    width: 15%;
}

.TSS-filterMenuWrapper>.left-pannel-header {
    padding: 7%;
    background-color: #2a498a;
    border: solid 1px #dcdcdc;
    cursor: pointer;
}

.TSS-result-filter-expand-wrapper {
    cursor: pointer;
}

.TSS-result-filter-expand-wrapper img {
    border: solid 1px #dcdcdc;
    padding: 7%;
    background: #2a498a
}

.TSS-left-pannel-ul {
    padding-left: 0rem;
}
.TSS-sidebar {
    height: 100%;
    min-width: 191px !important;
    width:100px !important;
    background-color: #ffffff;
    height: 95vh;
}
.TSS-sidebar>.ps-sidebar-container
{
    height: 100%;
    background-color: #ffffff;
}
.TSS-sidebar.ps-collapsed {
    width: 80px !important;
    min-width: 80px !important;
}
.TSS-menuitem-header {
    display: flex;
    flex-direction: row;
    
    align-items: center;
    
    background-color: #2a498a;
    /* border: 1px solid #dcdcdc; */
    cursor: pointer;
    padding: 10px;
    
}
.TSS-menuitem-header-collapsed{
    justify-content: center;
}
.TSS-menuitem-header-expand{
  
    justify-content: flex-end;
}
.TSS-menuitem-header>img,
.TSS-menuitem-arrow-left-to-line {
    cursor: pointer;
}

.TSS-menuitem-arrow-left-to-line {
    padding: 5px;
    margin-left: 2px;
}
.TSS-menuitem-selected,
.TSS-menuitem-selected>.ps-menu-button:hover {
    background-color: #f8f7f5 !important;
   
}
.tableElement{
    box-sizing: border-box;
    min-width: 0;
    position: relative;
    /*font-family: "Roboto", "Helvetica", "Arial", sans-serif;*/
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}