.view-selected-wrapper {
    text-align: left;
    margin: 1% 2%;
    font-family: 'J&J CircularTT-Medium';
}
.viewSelected-resultsContainer {
    display: flex;
    align-items: baseline;
    border-bottom: 1px solid grey;
    padding: 1% 0%;
}
.viewSelected-checkboxContainer {
    margin: 0 1% 0 0;
}
.view-selected-top-bottom-container {
    display: flex;
    justify-content: space-between;
    background: transparent linear-gradient(90deg, rgba(217, 224, 238, 0.22) 0%, rgba(154, 230, 224, 0.22) 100%) 0% 0% no-repeat padding-box;
    padding: 1% 2%;
    font-family: 'J&J CircularTT-Medium';
}
.buttons-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    /* width: 480px; */
    width: 50%;
    justify-content: space-evenly;
}
.links-container{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    width: 50%;
    /* width: 30%; */
    color: #0074B0;
}
.vs-links{
    color: #0074B0;
    text-decoration: none;
    cursor: pointer;
}
.vs-links:hover{
    text-decoration: underline;
}
.links-sepration-blue{
    border-left: 1px solid #707070;
    opacity: 0.44;
    margin-left: 1%;
    /* border-left: 1px solid #0074B0;
    opacity: 0.44;
    height: 10px; */
}
.features-buttons{
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    background: white;
    padding: 1% 4%;
    color: #0074B0;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}
.features-buttons:hover{
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    background: linear-gradient(252deg,#7CC7BF,#61779C);
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}
img.vs-img {
    margin: 0 10px 0 0;
}
.vs-img-hov{
    margin: 0 10px 0 0;
    display: none;
}
.features-buttons:hover .vs-img-hov{
    display: inline;
}
.features-buttons:hover .vs-img{
    display: none;
}
.features-buttons-mobile{
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    background: white;
    padding: 1% 4%;
    color: #0074B0;
    cursor: pointer;
    font-size: 14px;
    margin: 1%;
}
/* .features-buttons-mobile:hover{
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    background: linear-gradient(252deg,#7CC7BF,#61779C);
}
.features-buttons-mobile:hover .vs-img-hov{
    display: inline;
}
.features-buttons-mobile:hover .vs-img{
    display: none;
} */
.view-selected-top-bottom-container-mobile{
    display: flex;
    background: transparent linear-gradient(90deg, rgba(217, 224, 238, 0.22) 0%, rgba(154, 230, 224, 0.22) 100%) 0% 0% no-repeat padding-box;
    padding: 1% 2%;
    font-family: 'J&J CircularTT-Medium';
    flex-direction: column;
}
@media screen and (min-width:650px) and (max-width:700px){
    .features-buttons{
        font-size: 13px;
    }
    .features-buttons:hover{
        font-size: 13px;
    }
}
@media screen and (min-width:620px) and (max-width:649px){
    .features-buttons{
        font-size: 12px;
    }
    .features-buttons:hover{
        font-size: 12px;
    }
}
@media screen and (min-width:600px) and (max-width:619px){
    .features-buttons{
        font-size: 11px;
    }
    .features-buttons:hover{
        font-size: 11px;
    }
}