/*Lower Resolution Screen CSS Start*/
.navigation-menu-wrapper-smaller-resolution{
    width: 100%;
    height: 35px; 
    background: #fff;
    border-color: #f8f8f8;
    box-shadow: 0px 3px 6px #00000029;
    font-family: 'J&J CircularTT-Medium';
    display:flex;
    position:sticky;
    top:0;
    z-index: 1000;
    /* margin:0 1%; */
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .navigation-main-sub-menu-wrapper{
    list-style: none;
    position: absolute;
    z-index: 100;
    text-align: left;
    font-family: 'J&J CircularTT-Medium';
    font-size: 14px;
    color: #333;
    border-radius: 0 0 5px 5px;
    padding:0.2% 0 1.2% 0;
    width: 100%;
    height: 80vh;
    /* margin-left:-8px; */
  }
  .navigation-main-sub-menu-wrapper ul{
    background-color: #fff;
    box-shadow: 0px 3px 6px #00000029;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    height: 100%;
  }
  .navigation-main-sub-menu-wrapper li{
    list-style: none;
    margin: 1% 1%;
    padding:0.5% 0;
  }
  .navigation-main-sub-menu-wrapper a{
    color: #333;
    text-decoration: none;
  }
  .navigation-main-sub-menu-wrapper li a:hover{
    color :#FF8000;
  }
  .navigation-sub-menu-wrapper-tablet{
    list-style: none;
    position: absolute;
    z-index: 1000;
    text-align: left;
    font-family: 'J&J CircularTT-Medium';
    font-size: 14px;
    color: #333;
    border-radius: 0 0 5px 5px;
    padding:0 0 1.2% 0;
    width: 40%;
    height: 210px;
    right:0;
  }
  .navigation-sub-menu-wrapper-tablet ul{
    box-shadow: 0px 3px 6px #00000029;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    padding:3% 1%;
    height: 90%;
  }
  .navigation-sub-menu-wrapper-tablet li{
    height: 20px;
    list-style: none;
    margin: 1% 1%;
    padding:1% 0;
  }
  .navigation-sub-menu-wrapper-tablet li:hover{
    color :#FF8000;
  }
  .navigation-sub-menu-wrapper-tablet a{
    color: #333;
    text-decoration: none;
  }
  .navigation-sub-menu-wrapper-tablet li a:hover{
    color :#FF8000;
  }
  .navigation-menu-wrapper-mobile{
      background: url(../../assets/bg-img-mobile-view.png) no-repeat 0 0;
      background-size: 100% 100%;
      height: 60px;
      display: flex;
      position: sticky;
      top: 0;
      z-index: 1000;
      cursor: pointer;
  }
  .navigation-menu-inner-wrapper-mobile{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
  }
  .navigation-sub-menu-wrapper-mobile{
    list-style: none;
    position: absolute;
    z-index: 100;
    text-align: left;
    font-family: 'J&J CircularTT-Medium';
    font-size: 14px;
    color: #333;
    border-radius: 0 0 5px 5px;
    padding:0 0 1.2% 0;
    width: 100%;
    height: 100vh;
    right:0;
  }
  .navigation-sub-menu-wrapper-mobile ul{
    /* box-shadow: 0px 3px 6px #00000029; */
    background: #F8F8F8 ;
    padding:1% 1%;
    height: 100%;
  }
  .navigation-sub-menu-wrapper-mobile li{
    list-style: none;
    /* margin: 1% 1%; */
    padding:3% 1%;
    border-bottom: 1px solid #EFEFEF;
  }
  .navigation-sub-menu-wrapper-mobile li:hover{
    color :#FF8000;
  }
  .navigation-sub-menu-wrapper-mobile a{
    color: #333;
    text-decoration: none;
  }
  .navigation-sub-menu-wrapper-mobile li a:hover{
    color :#FF8000;
  }
  .navigation-sub-menu-main-header-mobile{
    display: flex;
    padding: 3% 2%;
    background: #f8f8f8;
    border-bottom: 1px solid #EFEFEF;
    justify-content: space-between;
  }
  .navigation-sub-menu-username-mobile{
    display: flex;
    padding: 2% 1%;
    background: #f8f8f8;
    border-bottom: 1px solid #EFEFEF;
    align-items: center;
  }
  .navigation-alerts-sub-menu-wrapper-tablet{
    padding:0% 3%;
  }
  .navigation-alerts-sub-menu-wrapper-tablet li:hover{
    color :#FF8000;
  }
  .navigation-alerts-sub-menu-wrapper-tablet li a:hover{
    color :#FF8000;
  }
  .nav-alerts-menu-wrapper-tablet{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 98%;
  }