.resultHistorysli a, .resultHistorysDiv a{
    text-decoration: none;
    color: #0074B0;
    font-size: 13px;
    font-family: 'J&J CircularTT-Medium';

}

.resultHistorysli a:visited, .resultHistorysDiv a:visited{
    color: #0074B0;
}

.resultHistorysli a:hover, .resultHistorysDiv a:hover {
    cursor: pointer;
    text-decoration: underline;
    color:#2a498a;
}

.resultHistorysul{
    /* list-style-type:none; */
    text-align: left;
}
@media only screen and (min-width: 1001px){
    .popup-transition-qh-rv{
        margin: 2.75rem auto !important;
    }
}
@media only screen and (min-width: 600px) and (max-width: 1000px){
    .rv-qh-outer-wrapper-tablet > .qh-outer-wrapper, .rv-qh-outer-wrapper-tablet > .rv-outer-wrapper{
        width: calc(50% - 1%);
        margin-bottom: 1%;
    }
    .popup-transition-qh-rv{
        margin: 0rem auto !important;
        height: 500px !important;
        min-height: 600px !important;
    }
}


.rv-qh-header-section-mobile{
    border: 1px solid #1DA1E5;
    padding: 1%;
    text-align: left;
    background-color:#F2FBFF;
    color: #333;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.ul-query-history, .ul-recently-viewed{
    height: 150px;
    overflow-y: auto;
}