.nlp-navigation-feature-wrapper {
    /* top: 342px;
    margin-left: 270px;
    width: 132px;
    height: 16px;
    opacity: 1;
    letter-spacing: 0.02px;
    color: #3F4444;
    text-align: left;
    font-size: 11px
    margin-bottom: 20px; */
    /* width: 115px;
    height: 16px; */
    /* margin-left: 258px; */
    /* text-align: left;
    font: normal normal normal 16px/20px Medium;
    letter-spacing: 0.02px;
    color: #3F4444;
    opacity: 1; */
    /* height: 20px; */
    text-align: left;
    display: flex;
    justify-content: flex-start;
    margin-top: -13px;
    align-items: center;
    position: relative;
}
.nlp-drp-btn {
    transform: matrix(0, -1, 1, 0, 0, 0);
}
.nlp-nav-text {
    text-align: left;
    font: normal normal normal 18px/20px Medium;
    font-family: 'J&J CircularTT-Medium';
    letter-spacing: 0.02px;
    /* color: #0074B0; */
    opacity: 1;
    white-space: nowrap;
}

.nlp-showingDetailsOfSearch {
    margin-top: 2px;
    /*margin-left: 258px;
    margin-right: 38px;*/
    margin-bottom: 6px;
    width: 100%;
    height: 239px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.5px solid rgba(112, 112, 112, 0.5);
    opacity: 1;
    position: relative;
}

.nlp-feature-text-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 170px;
    border: solid 1px #dcdcdc;
    box-shadow: 0px 3px 6px #00000029;
    padding: 4px 8px;
    color: #0074B0;
}
.nlp-feature-text-btn-desktop{
    background: #2a498a;
    color: #ffffff;
}
.nlp-feature-dropdown-btn-1 {
    height: 29px;
    margin-left: 6px;
}

.nlp-feature-expand-icon-wrapper {
    /* width: 22px;
    height: 19px;
    background: rgba(217, 217, 217, 0.35) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    margin-left: 15px;
    padding: 0px 6px;
    cursor: pointer; */
    background: rgba(217, 217, 217, 0.35) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    margin-left: 15px;
    padding: 0px 6px;
    cursor: pointer;
}

/* .results-title-action-outer-wrapper{
    text-align: left;
} */

/* .result-feature-icon-wrapper {
    margin-left: 950px;

} */
.nlp-resultsDevFooter-1 {
    border-bottom: 1px solid #dcdcdc;
    width: 125%;
    height: 15px;
    margin-left: -10px;
}

.nlp-navDoc-seprate {
    border-left: 2px solid rgba(112, 112, 112, 0.5);
    height: 21px;
    position: absolute;
    right: -10px;
    margin-top: -21px;
}

.nlp-doctype-nav-filed {
    margin-top: 3px;
    letter-spacing: 0.02px;
    margin-right: -10px;
    position: relative;

}


.nlp-action-items-title {
    margin-top: -22px;
    margin-right: -86px;
    padding-left: 26px;
    text-align: left;
    font-family: 'J&J CircularTT-Medium';
}
.npl-document-content-wrapper{
    word-break: break-all;
    margin-bottom: 15px;
}

@media screen and (min-width: 999px) {
    .nlp-action-items-title:hover {
        background-color: #C7C7C7;
        border-radius: 3px;
    }
    .nlp-navDoc-seprate{
        border-color: #2a498a;
    }
}


.nlp-doc-title-link {
    text-decoration: none;
    display: inline-flex
}

.nlp-doc-title-link:hover {
    text-decoration: underline;
}

.nlp-result-feature-icon-wrapper-pin {
    margin-top: 16px;
}

.nlp-parent-of-result-feature-icon-wrapper {
    margin-top: 30px;
    margin-left: 30px;
}

/* .action-items-inner-wrapper {
    width: 80px;
    height: 20px;
    margin-left: 850px;
    margin-top: -45px;
    opacity: 1;
} */
/* .feature-expand-icon-wrapper:hover{
background-color:#F57F20;
} */

.nlp-full-wrapper {
    display: flex;
    text-align: left;
    padding: 2% 0 0% 1%;
    flex-direction: column;
    width: 100%;
}

.nlp-left-for-data {
    width: 100%;
    /* display: flex; */
    float: left;
}

.nlp-citation-details {
    display: flex;
}

.nlp-PHM-MetaDataProperty-1 {
    /* width: 913px;
    height: 56px;
    margin-left: -358px;
    margin-top: 15px; */
    color: #333;
    text-align: left;
    font-family: 'J&J CircularTT-Medium';
}

.nlp-right-for-icon {
    float: right;
    cursor: pointer;
}

.nlp-right-corner-btn-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    width: auto;
}

span.answer,
span.answer>h6,
span.answer>h1 {
    text-decoration: underline;
}


.nlp-for-left-right-wapper {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 221px;
    max-height: 221px;
    padding: 2px 0px 2px 10px;
    scrollbar-width: thin;
}

.nlp-for-left-right-wapper::-webkit-scrollbar {
    width: 15px;
    /* width of the entire scrollbar */
}

.nlp-img-style {
    margin-right: -9px;
    width: 29px;
    margin-bottom: -15px;
    height: 32px;
}

.nlp-dynamic-data-filed-wrapper {
    display: contents;
    font-family: 'J&J CircularTT-Medium';
    margin-left: 20px;
    display: inline-flex;
    column-gap: 30px;
    width: 75%;
}

.nlp-dynamic-data-left {
    text-align: left;
}

.nlp-results-title-action-outer-wrapper-1 {
    flex-direction: row;
    padding-top: 0.5%;
    width: 85%;
}

.nlp-data-dy:hover {
    color: #F57F20;
    cursor: pointer;
}

.nlp-citation-results-meta-property-wrapper {
    padding-left: 35px;
}

.nlp-horizontal-line-abovenav {
    border: 1px solid rgb(112, 112, 112, 0.5);
    opacity: 1;
    width: 126.5%;
    margin-top: -8px;
    margin-bottom: 5px;
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .nlp-feature-expand-icon-wrapper {
        position: absolute;
        top: 6px;
        right: 3px;
    }
}

@media screen and (min-width: 600px) and (max-width: 704px) {
    .nlp-navDoc-seprate {
        height: 28px;
        margin-top: -37px;
        right: 15px;
    }
}

/* @media screen and (min-width: 900px) and (max-width: 1000px)
{
    .nlp-feature-expand-icon-wrapper{
        top: 258px;
        right: 10px;
    }
}
@media screen and (min-width: 800px) and (max-width: 900px)
{
    .nlp-feature-expand-icon-wrapper{
        top: 251px;
        right: 10px;
    }
} */
/* @media screen and (min-width: 718px) and (max-width: 780px)
{
    .nlp-feature-expand-icon-wrapper{
        top: 240px;
        right: 10px;
    }
} */

/* @media screen and (min-width: 783px) and (max-width: 799px)
{
    .feature-expand-icon-wrapper{
        top: 243px;
        right: 10px;
    }
} */
@media screen and (min-width: 600px) and (max-width: 999px) {
    .nlp-action-items-title {
        padding-left: 5px;
        padding-top: 15px;
        text-align: left;
    }

    .nlp-pin-img {
        width: 35px;
        height: 35px;
        font-family: 'J&J CircularTT-Medium';
    }
}

@media screen and (min-width: 600px) and (max-width: 999px) {
    .nlp-navigation-feature-wrapper {
        position: relative;
    }

    .nlp-full-wrapper {
        width: 100%;
    }
}

.nlp-show-more-less {
    color: #0074B0;
    font-size: "14px";
    font-weight: "bold";
}

.nlp-show-more-less:hover {
    cursor: pointer;
    text-decoration: underline;
}

.nlp-feature-expand-icon-wrapper-highlight:hover, .nlp-feature-expand-icon-wrapper-active{
background-color: #F57F20;
cursor: pointer;
}
.nlp-feature-expand-icon-wrapper-disable{
    cursor: not-allowed;
}