.clinical-haeder{
    display: flex;
    justify-content: center;
    margin: 1%
}
.clinical-search{
    margin-bottom: 7%;
    width: 50%;
}
.clinal-alternate-record{
    /*margin-left: 2%;*/
    margin-top: 1%;
    
}
.clinal-alternate-record-table{
max-width: 100%;
overflow-x: auto;
}
.clinal-alternate-record-table::-webkit-scrollbar{
    height: 10px;
}
.ct-wrapper{
    width: 100%;
    margin-bottom: 1%;
}
.ct-rows{
    background-color: #0074b0;
    color: #fff;
}
.ct-cells{
    height: 48px;
    padding: 8px;
    text-align: center;
}
.ct-cells ul li{
    width: max-content;
}
.nh{
    border: 1px solid var(--bs-border-color);;
    word-wrap: break-word;
    font-size: 14px;
    /*text-align: left !important;*/
}
td>.ct-container{
    min-width: 100%;
    min-height: 100%;
    margin-top: 30px;
}