.recommended-home-filter-wrapper-mobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: 'J&J CircularTT-Medium';
    color:#333;
    padding:2% 1%;
    margin:1% 0;
  }
  .results-page-filter-wrapper-mobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: 'J&J CircularTT-Medium';
    color:#333;
    padding:2% 1%;
    margin:1% 0;
    height: 110px;
  }
  .recommended-home-filter-wrapper-tablet{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: 'J&J CircularTT-Medium';
    color:#333;
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding:2% 1.5%;
    margin:0 0 1% 0;
    background: #fff 0% 0% no-repeat padding-box;
  }
  .recommended-home-filter-button-tablet{
    border-radius: 4px;
    background: #F4F4F4;
    height: 30px;
    width: 100%; 
    border:1px solid #F4F4F4;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding:0 2%;
  }
  .recommended-home-quick-action-button-tablet{
    border-radius: 4px;
    background: #F4F4F4;
    height: 30px;
    width: 100%;
    /* text-align: center;   */
    border:1px solid #F4F4F4;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    position: relative;
    text-align: left;
    padding-left: 2%;
    display: flex;
    align-items: center;
  }
  .recommended-home-filter-button-mobile{
      width: 100%;
      height: 35px;
      border: 1px solid #1DA1E5;
      background-color:#F2FBFF;
      border-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding:0 2%;
  }
  .recommended-home-filter-button-mobile span{
      width:85%;
      text-align: left;
  }
  .results-page-action-button-mobile{
    width: 100%;
    height: 35px;
    border: 1px solid #1DA1E5;
    background-color:#F2FBFF;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    padding:0 0%;
    cursor: pointer;
    position: relative;
  }
  .results-page-filter-button-tablet{
    width: 100%;
    border-radius: 4px;
    background: #F4F4F4;
    height: 30px;
    border:1px solid #F4F4F4;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding:0 2%;
  }
  .results-page-action-button-tablet{
    width:48%;
    border-radius: 4px;
    background: #F4F4F4;
    height: 30px;
    border:1px solid #F4F4F4;
    box-shadow: 0px 3px 6px #00000029;
    align-items: center;
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .filter-menu-content-mobile{
    position: absolute;
    background: #fff;
    width: 98%;
    z-index: 100;
    /* max-height: 90vh; */
    max-height: 110vh;
    overflow-y: auto;
    margin-top:40px;
  }
  .filter-menu-content-tablet{
    position: absolute;
    background: #fff;
    width: 100%;
    z-index: 100;
    /* height: 450px;
    overflow-y: auto; */
    margin-top:30px;
    min-height: 0;
  }
  .recommended-home-filter-menu-wrapper-tablet{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    position:relative;
    width:48%;
  }
  .results-home-filter-menu-wrapper-tablet{
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    position:relative;
    width:48%;
  }
.quickActions-dropdownIcon-tablet{
  position: absolute;
  /* top: 30%; */
  right: 5%;
  width: 11px;
}
.quickLinksList{
  font-family: 'J&J CircularTT-Medium';
  position: absolute;
  list-style: none;
  top: 30px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  opacity: 1;
  background: white;
  left: 0;
  padding: 4% 6%;
  z-index: 1;
  font-size: 14px;
  text-align: left;
}
.quickLinksItem{
  border-bottom: 1px solid rgba(112, 112, 112, 0.19);
  margin: 0px 0 2% 0;
  padding: 0 0 2.5% 0;
  /* margin: 4% 0%; */
}
.quickLinkText{
  color: #333;
  text-decoration: none;
}
.recommended-home-quick-action-menu-wrapper{
  display: flex;
  flex-direction:column;
  justify-content:space-between;
  position:relative;
  width: 48%;
}
.actionItems-dropdownContainer{
  position: absolute;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  top: 30px;
  width: 100%;
  z-index: 100;
}
.actionItems-table{
  width: 100%;
}
.actionItem-rows{
  vertical-align: baseline;
}
.actionItem-data{
  padding: 2% 1%;
  cursor: pointer;
}
.actionItems-icons{
  width: 40px;
  height: 40px;
}
.actionItems-text{
  font-size: 14px;
  color: #63666A;
  letter-spacing: 0.02px;
}
.quickActions-dropdownIcon-mobile{
  position: absolute;
  right: 5%;
  width: 12px;
}
@media screen and (max-width:599px) {
  .actionItems-dropdownContainer{
    top: 34px;
  }
}
/* .actionItems-dropdownContainer-mobile{
  position: absolute;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  top: 40px;
  width: 100%;
} */
.popup-authoring-img-wrapper{
  display: flex;
  margin-left: 20px;
  /* margin-top: -14px; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.popup-authoring-img-wrapper > .popup-authoring-img{
  width:300px
}
.authoring-gpt-popup-transition>.modal-content {
  height: calc(100vh - 60px);
}
.authoring-gpt-popup-transition.modal-xl {
  max-width: calc(100vw - 20px);
}
div.authoring-gpt-popup-transition > div.modal-content > div > div.modal-body{
  padding: 5px;
}
