.expand-all-fields-ContentPopUp.modal-lg {
    max-width: 90vw;
}

.expand-all-fields-ContentPopUp>.modal-content {
    background: none;
    border: none;
}

.expand-all-fields-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.result-fields-wrapper,
.sourcetext-fields-wrapper,
.notes-fields-wrapper {
    width: 32%;
    /* padding: 10px; */
    border: 1px solid #203864;
    border-radius: 5px;
    border-top: none;
}

.ResultsTextarea,
.SourceTextarea,
.NotesTextarea {
    width: 100%;
    height: 80vh;
    margin: 0;
    font-size: 14px;
    resize: none;
    border: none;
    padding: 10px;

}
.SourceTextarea{
    background: #ffffff;
}
.notes-fields-label,
.sourcetext-fields-label,
.result-fields-label {
    background-color: #203864;
    text-align: center;
    color: #fff;
    /* font-weight: bold; */
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px;
}

.sourcetext-fields-textarea,
.notes-fields-textarea,
.result-fields-textarea {
    height: 80vh;
}

.z-index-expand-all-fields-ContentPopUp {
    z-index: 10009 !important;
}

.z-index-expand-all-fields-ContentPopUp.modal-backdrop.show {
    opacity: 0.9;
}

.expand-all-content-cancel {
    display: flex;
    flex-direction: row-reverse;
    margin-top: -21px;
    margin-right: -18px;
}

.expand-all-content-cancel>button {
    background-color: #555555;
    margin-right: -6px;
    border-radius: 5px;
}

.expand-all-content-cancel>button:hover {
    background: #a8a7a7;
}
.message-text-expand-all-fields{
    overflow-x: auto;
    padding-top: 10px;
    background-color: #ffffff;
    height: 80vh;
    padding: 10px;
}
.message-text-expand-all-fields > table{
    margin-top: 0px;
}
