@charset "UTF-8";

.cbm-box {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1200;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
    text-align: left;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .5);
    font-size: 12px;
    line-height: 20px;
    color: #333
}

.cbm-sidebox {
    position: fixed !important;
    right: 29px !important;
    height: 600px;
    max-height: calc(100% - 10px) !important;
    left: auto !important;
    margin: 0 !important;
    border: 0 !important;
    padding-left: 0 !important;
    z-index: 1100 !important;
    border-radius: 0 !important;
    line-height: 18px;
    min-width: 300px;
    bottom: 38px;
    border-radius: 6px !important;
    text-rendering: optimizeLegibility;
    -webkit-box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, .3);
    box-shadow: 0 1.5rem 2rem rgba(0, 0, 0, .3)
}


.cbm-box .cbm-row {
    margin-right: -15px;
    margin-left: -15px
}

.cbm-box * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit
}

.cbm-box :after,
.cbm-box :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

.cbm-box {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent
}

.cbm-sidebox {
    border-top: 8px solid #7b7b7b
}

.cbm-bg-white {
    background-color: #fff
}

.cbm-m-b {
    margin-bottom: 3px;
    margin-top: 3px;
    position: relative;
    overflow: visible;
    min-height: 1px
}

.cbm-sidebox .cbm-clear {
    display: block;
    overflow: hidden
}


.cbm-sidebox .cbm-box-title {
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: .8px;
    color: #fff;
    font-weight: 400 !important
}

.cbm-box .move-right {
    float: right !important
}

.cbm-msg-preview.n-vis,
.cbm-sidebox .n-vis,
.cbm-sidebox-launcher .n-vis,
.cbm-sidebox-launcher.n-vis,
.n-vis {
    display: none !important
}

.cbm-box .show,
.cbm-box .vis,
.cbm-msg-preview.vis,
.cbm-sidebox-launcher .vis,
.cbm-sidebox-launcher.vis {
    display: block
}

.cbm-box-body .cbm-tab-cell.vis {
    display: table !important
}

.cbm-sidebox p {
    margin: 0 0 10px
}


.cbm-sidebox .cbm-msg-content p {
    margin: 0
}

.cbm-sidebox b,
.cbm-sidebox strong {
    font-weight: 700
}

.cbm-sidebox a {
    color: #5553b7
}

.cbm-sidebox a {
    background-color: transparent
}

.cbm-sidebox a:hover {
    color: #323085;
    text-decoration: underline
}

.cbm-sidebox a:focus {
    outline: 0 !important
}

.cbm-form-group {
    margin-bottom: 10px;
    float: left;
    width: 100%
}

.cbm-form-group.last-child {
    margin-bottom: 0
}

.cbm-sidebox button,
.cbm-sidebox input,
.cbm-sidebox textarea {
    margin: 0;
    font: inherit;
    color: inherit
}

.cbm-sidebox button {
    overflow: visible
}

.cbm-sidebox button,
.cbm-sidebox select {
    text-transform: none
}

.cbm-sidebox button,
.cbm-sidebox input[type=button],
.cbm-sidebox input[type=reset],
.cbm-sidebox input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

.cbm-sidebox input {
    line-height: normal
}

.cbm-box input,
.cbm-box select,
.cbm-box textarea,
.cbm-sidebox button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}


.cbm-btn {
    display: inline-block;
    padding: 5px 6px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px
}

.cbm-box-footer .cbm-btn {
    font-size: 12px
}


@keyframes animslideup {
    from {
        opacity: 0;
        margin-bottom: -70px
    }

    to {
        opacity: 1;
        margin-bottom: -15px
    }
}

@keyframes animslidedown {
    from {
        opacity: 1;
        margin-bottom: -15px
    }

    to {
        opacity: 0;
        margin-bottom: -70px
    }
}

@-webkit-keyframes animslideup {
    from {
        opacity: 0;
        margin-bottom: -70px
    }

    to {
        opacity: 1;
        margin-bottom: -15px
    }
}

@-webkit-keyframes animslidedown {
    from {
        opacity: 1;
        margin-bottom: -15px
    }

    to {
        opacity: 0;
        margin-bottom: -70px
    }
}

@-moz-keyframes animslideup {
    from {
        opacity: 0;
        margin-bottom: -70px
    }

    to {
        opacity: 1;
        margin-bottom: -15px
    }
}

@-moz-keyframes animslidedown {
    from {
        opacity: 1;
        margin-bottom: -15px
    }

    to {
        opacity: 0;
        margin-bottom: -70px
    }
}

@-o-keyframes animslideup {
    from {
        opacity: 0;
        margin-bottom: -70px
    }

    to {
        opacity: 1;
        margin-bottom: -15px
    }
}

@-o-keyframes animslidedown {
    from {
        opacity: 1;
        margin-bottom: -15px
    }

    to {
        opacity: 0;
        margin-bottom: -70px
    }
}

@keyframes animslideup {
    from {
        opacity: 0;
        margin-bottom: -70px
    }

    to {
        opacity: 1;
        margin-bottom: -15px
    }
}

@keyframes animslidedown {
    from {
        opacity: 1;
        margin-bottom: -15px
    }

    to {
        opacity: 0;
        margin-bottom: -70px
    }
}

@-webkit-keyframes animslideup {
    from {
        opacity: 0;
        margin-bottom: -70px
    }

    to {
        opacity: 1;
        margin-bottom: -15px
    }
}

@-webkit-keyframes animslidedown {
    from {
        opacity: 1;
        margin-bottom: -15px
    }

    to {
        opacity: 0;
        margin-bottom: -70px
    }
}

@-moz-keyframes animslideup {
    from {
        opacity: 0;
        margin-bottom: -70px
    }

    to {
        opacity: 1;
        margin-bottom: -15px
    }
}

@-moz-keyframes animslidedown {
    from {
        opacity: 1;
        margin-bottom: -15px
    }

    to {
        opacity: 0;
        margin-bottom: -70px
    }
}

@-o-keyframes animslideup {
    from {
        opacity: 0;
        margin-bottom: -70px
    }

    to {
        opacity: 1;
        margin-bottom: -15px
    }
}

@-o-keyframes animslidedown {
    from {
        opacity: 1;
        margin-bottom: -15px
    }

    to {
        opacity: 0;
        margin-bottom: -70px
    }
}


#cbm-msg-sbmt:disabled {
    opacity: .3;
    cursor: default
}



.cbm-btn.active,
.cbm-btn:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
}


.cbm-sidebox .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.cbm-sidebox label {
    display: inline-block;
    max-width: 100%;
    font-weight: 700;
    clear: none;
    margin: 0;
    padding: 0
}

.cbm-fade {
    opacity: 0;
    -webkit-transition: opacity .15s linear;
    -o-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.cbm-box .cbm-box-backdrop.cbm-fade {
    opacity: 0
}

.cbm-fade.in {
    opacity: 1;
    background: 0 0 !important
}

.cbm-box.cbm-fade.in {
    width: 100%
}

.cbm-sidebox.cbm-fade.in {
    width: 379px
}

.cbm-box .cbm-box-backdrop.in {
    opacity: .5
}

.cbm-sidebox .cbm-box-backdrop.in {
    width: 100%;
    right: 0;
    left: auto
}

.cbm-box .cbm-box-backdrop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 0 !important;
    background-color: #000
}

.cbm-box-backdrop {
    position: absolute !important;
    z-index: 0;
    display: none
}

.cbm-box .cbm-box-backdrop {
    background: 0 0
}

.cbm-box .cbm-box-dialog.md-box-s {
    max-width: 300px
}

.cbm-sidebox .cbm-right {
    position: absolute;
    top: 0;
    right: 0;
    left: auto
}

.cbm-box-title {
    height: 48px;
    font-size: 18px;
    line-height: 48px;
    vertical-align: middle;
    overflow: hidden;
    text-align: center
}

.cbm-box.cbm-fade .cbm-box-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    -o-transition: -o-transform .3s ease-out;
    transition: transform .3s ease-out;
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%)
}

.cbm-box.in .cbm-box-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0)
}

.cbm-box.cbm-fade .cbm-right {
    transform: translateY(0);
    -webkit-transition: -webkit-transform .25s cubic-bezier(.25, .1, .25, 1);
    -moz-transition: -moz-transform .25s cubic-bezier(.25, .1, .25, 1);
    -o-transition: -o-transform .25s cubic-bezier(.25, .1, .25, 1);
    transition: transform .25s cubic-bezier(.25, .1, .25, 1);
    margin: 0;
    height: 100%
}


.cbm-box.in .cbm-right,
.cbm-box.in .cbm-top {
    transform: translateY(0)
}

.cbm-box-content {
    border: 0;
    display: table;
    width: 100%;
    border-spacing: 0;
    outline: 0;
    table-layout: fixed;
    box-shadow: 0 8px 8px rgba(0, 0, 0, .2);
    -webkit-box-shadow: 0 8px 17px rgba(0, 0, 0, .2);
    border-radius: 0;
    position: relative;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box
}

.cbm-sidebox .cbm-box-content {
    height: 100%
}

.cbm-box-top {
    padding: 0 10px;
    min-height: 60px;
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
    background-color: #5553b7;
    color: #fff
}

.cbm-box-top .icon {
    margin-right: 12px
}

.cbm-box-top [class*=blk-] {
    height: 48px;
    line-height: 48px
}

.cbm-box-top.cbm-row {
    margin: 0
}

.cbm-box-top .blk-lg-2 {
    width: 20px
}

.cbm-search-tab-box,
.cbm-sidebox-search .cbm-box-top {
    padding-right: 0
}

.cbm-gm-search-box .cbm-box-top .blk-lg-3 {
    width: 30px;
    height: inherit;
    padding: 14px 0
}

.cbm-sidebox-search .cbm-box-top .blk-lg-5 {
    width: calc(100% - 60px)
}

.cbm-gm-search-box .cbm-box-top .blk-lg-7 {
    width: calc(100% - 50px)
}

.cbm-conversation-header {
    min-height: 35px;
    text-align: left;
    font-size: 15px;
    padding: 0 10px;
    margin: 0;
    vertical-align: middle;
    line-height: 35px !important;
    color: #3b5998;
    border-bottom: 1px solid #ccc;
    overflow: hidden
}


.cbm-sidebox .cbm-msg-form {
    margin: 0
}


.cbm-file-expr .cbm-box-close {
    font-size: 18px !important
}

.cbm-box-close {
    float: right;
    font-weight: 700;
    color: #000;
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    height: 50px;
    outline: 0;
    width: 45px
}

.cbm-box-close svg {
    vertical-align: middle
}

.cbm-box-close span {
    display: block;
    margin: auto
}

.cbm-box-title {
    text-align: left !important;
    margin: 0;
    font-size: 18px;
    vertical-align: middle;
    overflow: hidden
}

.cbm-box-body {
    position: relative;
    padding: 15px
}

.cbm-gm-search-box .cbm-box-body {
    padding: 10px
}

.cbm-box .dl-horizontal dd:after,
.cbm-box .dl-horizontal dd:before,
.cbm-box .cbm-box-ft:after,
.cbm-box .cbm-box-ft:before,
.cbm-box .cbm-form-group:after,
.cbm-box .cbm-form-group:before,
.cbm-box .cbm-row:after,
.cbm-box .cbm-row:before,
.cbm-box .nav:after,
.cbm-box .nav:before,
.cbm-box .navbar-collapse:after,
.cbm-box .navbar-collapse:before,
.cbm-box .navbar-header:after,
.cbm-box .navbar-header:before,
.cbm-box .navbar:after,
.cbm-box .navbar:before,
.cbm-box .pager:after,
.cbm-box .pager:before,
.cbm-box .panel-body:after,
.cbm-box .panel-body:before,
.cbm-sidebox .clearfix:after {
    display: table;
    content: " "
}

.cbm-box .form-horizontal .cbm-form-group:after,
.cbm-box .cbm-row:after,
.cbm-sidebox-ft :after {
    clear: both
}


.cbm-box .blk-lg-1,
.cbm-box .blk-lg-10,
.cbm-box .blk-lg-11,
.cbm-box .blk-lg-12,
.cbm-box .blk-lg-2,
.cbm-box .blk-lg-3,
.cbm-box .blk-lg-4,
.cbm-box .blk-lg-5,
.cbm-box .blk-lg-6,
.cbm-box .blk-lg-7,
.cbm-box .blk-lg-8,
.cbm-box .blk-lg-9 {
    position: relative;
    min-height: 1px
}


.cbm-sidebox .cbm-btn span {
    display: block;
    float: left
}

.cbm-sidebox .cbm-box-body {
    padding: 0;
    position: relative;
    display: table-row;
    height: 100%;
    overflow-y: auto
}

.cbm-box-body .cbm-tab-cell {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch
}

#cbm-message-cell .li {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.cbm-gm-search-box .cbm-box-body .cbm-tab-cell {
    height: 300px
}

.cbm-box-body .cbm-message-inner {
    position: absolute;
    background-color: #fff;
    display: table-row;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    scrollbar-width: thin;
    -webkit-overflow-scrolling: touch
}

.cbm-box-body .cbm-message-inner:focus {
    outline: 0
}

.cbm-display-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: inherit;
    line-height: 22px
}

.cbm-close-btn {
    width: 45px;
    height: 50px
}

.cbm-sidebox .cbm-box-top .blk-lg-2.cbm-close-btn {
    width: 45px
}

.cbm-gm-search-box .cbm-box-body .cbm-message-inner {
    height: 300px;
    background-color: #fff
}

.cbm-sidebox-search .cbm-box-body .cbm-message-inner {
    background-color: #fff;
    margin: 5px 0
}



.cbm-message-inner .blk-lg-12 {
    padding: 0
}

.cbm-box-ft {
    text-align: left;
    padding: 0 15px 10px;
    background-color: #fff;
    width: auto;
    position: relative
}

.cbm-box-ft .cbm-box-form {
    padding: 10px 5px 10px 0;
    border: thin solid #cecbcb;
    border-radius: 4px;
    background-color: #fff;
    position: relative
}

.cbm-box-ft .cbm-row {
    margin: 0
}

.cbm-box-ft-lt .cbm-btn {
    font-size: 16px;
    text-transform: none
}



.cbm-sidebox input,
.cbm-sidebox select,
.cbm-sidebox textarea {
    background-color: #f5f5f5
}

.cbm-sidebox textarea {
    resize: none
}

.cbm-textbox-container {
    width: 100%;
    outline: 0;
    margin: auto;
    background-color: #fff;
    text-align: left
}

.cbm-text-box.cbm-text {
    padding: 5px 5px 5px 10px;
    min-height: 18px;
    max-height: 78px;
    resize: none;
    background-color: #fff;
    width: auto;
    outline: 0 !important;
    margin: 0;
    font-size: 16px;
    overflow: auto;
    line-height: 1.2em;
    overflow-x: hidden;
    display: block !important;
    text-align: left;
    box-shadow: none !important;
    -webkit-user-select: text;
    border: none
}

.cbm-text-box-panel {
    width: 25px !important
}



.cbm-text-box-panel .cbm-blk-12 {
    height: 30px
}

.cbm-blk-2.cbm-text-box-panel .cbm-btn:focus,
.cbm-blk-2.cbm-text-box-panel .cbm-btn:hover {
    outline: 0
}

.cbm-text-box-panel button,
.cbm-text-box-panel label {
    height: 30px !important
}

.cbm-btn-text-panel {
    background: 0 0 !important;
    border: none !important;
    text-shadow: none;
    box-shadow: none;
    font-size: 20px !important;
    width: 25px !important;
    padding: 0 !important
}

.cbm-btn-text-panel:active {
    box-shadow: none !important;
    outline: 0 !important
}

.cbm-btn-text-panel img {
    height: 25px;
    width: 25px;
    margin-top: -3px;
    vertical-align: middle;
    opacity: .7
}

.cbm-text-box-panel.move-right img {
    opacity: 1
}

.cbm-msg-box {
    padding: 8px;
    border: 0;
    border-radius: 3px;
    display: inline-block;
    max-width: 85%;
    min-height: 20px;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
    box-shadow: 0 1px 2px rgba(0, 0, 0, .15)
}


.cbm-ol-icon {
    width: 12px;
    height: 12px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 110px;
    background-color: green
}

.cbm-ol-status.vis {
    display: inline-block !important
}

.cbm-msg-text {
    white-space: pre-wrap;
    word-wrap: break-word;
    position: relative
}

.cbm-msg-content img {
    max-width: 225px !important;
    max-height: 200px !important
}

.cbm-msg-left .cbm-msg-box {
    position: relative;
    float: left;
    color: #333;
    font-size: 14px;
    min-height: 20px;
    padding: 10px 14px 12px;
    max-width: 70%;
    max-width: -moz-calc(92%);
    max-width: calc(92%);
    border-radius: 5px;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-animation-name: slideFromRight;
    animation-name: slideFromRight;
    background: #dcf8c6
}

.cbm-msg-right .cbm-msg-box a {
    color: #fff;
    text-decoration: underline
}

.cbm-msg-left .cbm-msg-box a {
    text-decoration: underline;
    font-weight: bold;

}

.cbm-msg-left .cbm-msg-box a:hover {
    color: #da552f
}

.cbm-msg-right .cbm-msg-box a:hover {
    color: #ff0
}

.cbm-msg-left .cbm-msg-box:after,
.cbm-msg-left .cbm-msg-box:before {
    right: 100%;
    height: 0;
    width: 0;
    position: absolute
}

.cbm-msg-right .cbm-msg-box:after,
.cbm-msg-right .cbm-msg-box:before {
    left: 100%;
    height: 0;
    width: 0;
    position: absolute
}

.cbm-msg-left .cbm-msg-box:after,
.cbm-msg-right .cbm-msg-box:after {
    top: 50%;
    margin-top: -11px
}

.cbm-msg-left .cbm-msg-box:before,
.cbm-msg-right .cbm-msg-box:before {
    top: 50%;
    margin-top: -12px
}

.cbm-msg-left .cbm-msg-box:after,
.cbm-msg-left .cbm-msg-box:before,
.cbm-msg-right .cbm-msg-box:after,
.cbm-msg-right .cbm-msg-box:before {
    border: solid transparent;
    pointer-events: none
}

.cbm-msg-left .cbm-msg-box:after {
    border-color: transparent;
    border-right-color: #dcf8c6;
    border-width: 11px
}

.cbm-msg-right .cbm-msg-box:after {
    border-color: transparent;
    border-left-color: #2a498a;
    border-width: 11px
}

.cbm-msg-left .cbm-msg-box:before {
    border-color: transparent;
    border-right-color: #ddd;
    border-width: 12px
}

.cbm-msg-right .cbm-msg-box:before {
    border-color: transparent;
    border-left-color: #ddd;
    border-width: 12px
}

.cbm-text-xs {
    font-size: 12px
}

.cbm-t-xs {
    margin-top: 4px
}


.cbm-msg-left-muted {
    float: left;
    padding-left: 19px
}

.cbm-msg-avator-bubble .cbm-msg-left-muted {
    margin-left: 0;
    padding: 0;
    width: 100%
}

.cbm-msg-right-muted {
    box-sizing: border-box;
    float: right;
    padding-right: 15px
}

.cbm-msg-avator-bubble .cbm-msg-right-muted {
    position: relative;
    width: 100%;
    text-align: right
}


.cbm-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.cbm-msg-avator-bubble .cbm-msg-avator {
    display: block;
    top: 21px;
    position: absolute;
    margin-top: -21.5px
}

.cbm-msg-avator-bubble.cbm-msg-left .cbm-msg-avator {
    float: left;
    left: 5px;
    margin-left: 3px
}

.cbm-msg-avator-bubble.cbm-msg-right .cbm-msg-avator {
    float: right;
    right: 0;
    margin-right: 3px;
    display: none
}

.cbm-msg-avator img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    vertical-align: middle
}

.cbm-message-status {
    width: 14px;
    height: 14px;
    opacity: 1;
    display: inline-block;
    vertical-align: middle
}

.cbm-message-status svg {
    vertical-align: baseline;
    opacity: .7;
    margin-left: 1px;
    transform: translateY(-1px)
}

.cbm-msg-left .cbm-message-status svg {
    display: none
}

.cbm-message-status.cbm-icon-read {
    opacity: 1
}

.cbm-created-at-time {
    opacity: .4;
    vertical-align: middle;
    font-size: 11px
}


.cbm-box-ft:after {
    clear: both
}

.cbm-box-content.vis {
    display: table !important
}

.cbm-msg-avator {
    display: none
}

.cbm-msg-right .cbm-msg-box {
    float: right !important;
    font-size: 14px;
    margin-right: 8px;
    min-height: 20px;
    padding: 10px 14px 12px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-animation-name: kmOpeningAnimation;
    animation-name: kmOpeningAnimation;
    border-radius: 5px;
    max-width: 70%;
    max-width: -moz-calc(95%);
    max-width: calc(95%);
    display: inline-block;
    padding: 10px 10px 10px;
    position: relative;
    color: rgba(255, 255, 255, .87);
    background-color: #2a498a
}

.cbm-group-inner .bubble.cbm-msg-left .cbm-msg-box:after {
    border-right-color: #fff
}

.cbm-msg-center {
    text-align: center
}

.cbm-msg-center .cbm-msg-box {
    position: relative;
    margin: auto;
    display: inline-block;
    text-align: center;
    color: rgba(69, 90, 100, .95);
    background-color: #fff;
    box-shadow: 0 1px .5px rgba(0, 0, 0, .13);
    border-radius: 4px;
    padding: 6px 12px;
    line-height: 20px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, .4)
}


.cbm-msg-text span.emoji-sizer {
    margin: 1px 0 !important
}


.cbm-search-tab-box.cbm-row,
.cbm-sidebox .cbm-box-top .cbm-row {
    margin: 0
}

.cbm-sidebox .cbm-box-top .blk-lg-10 {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.cbm-sidebox .cbm-box-top .blk-lg-2 {
    width: 20px;
    height: 50px
}

.cbm-sidebox .cbm-tab-title-w-status {
    line-height: 40px;
    height: 30px;
    position: relative
}

.cbm-tab-title {
    color: #fff
}


.cbm-group-inner {
    background: #fafafa
}

.cbm-group-inner .cbm-msg-left .cbm-msg-box {
    padding: 10px;
    color: #333;
    background: #ededed;
    min-width: 312px;
}

.cbm-group-inner .cbm-msg-left .cbm-msg-box:after {
    border-right-color: #ededed
}

.cbm-conversation-name {
    margin-left: 45px;
    font-size: 12px;
    color: #a8a8ac;
    letter-spacing: .3px
}

.cbm-box .cbm-box-sm {
    width: 300px
}

.cbm-group-create-tab .cbm-group-name-box .blk-lg-12:last-child {
    width: calc(100% - 10px);
    padding: 3px 5px;
    background-color: #fff;
    height: 29px;
    border-radius: 4px
}

.cbm-gm-search-box .cbm-box-top .blk-lg-3 img,
.cbm-group-add-member-box img {
    width: 20px;
    height: 20px;
    display: block;
    vertical-align: middle
}



.cbm-icon-send {
    display: inline-block;
    overflow: hidden;
    text-indent: -9999px;
    text-align: left
}

.cbm-icon-send {
    background-position: -10px -368px;
    width: 25px;
    height: 25px
}

.cbm-icon-send svg {
    display: block;
    width: 20px;
    height: 25px
}

.cbm-sidebox {
    height: 580px;
}

/* @media (max-height:900px) {
   
} */

@media (min-width :310px) {
    .cbm-sidebox {
        right: 25px !important;
        bottom: 8px
    }

    .cbm-sidebox.cbm-fade.in {
        width: 300px
    }

    .cbm-box .cbm-box-md,
    .cbm-sidebox .cbm-box-sm {
        width: 300px;
        height: 100% !important
    }

    .cbm-gm-search-box .cbm-box-sm {
        width: 300px
    }

    .cbm-box-dialog {
        width: 600px;
        margin: 30px auto
    }

    .cbm-box .blk-lg-12,
    .cbm-box .blk-lg-2,
    .cbm-box .blk-lg-3 {
        float: left
    }

    .cbm-sidebox-content .cbm-box-top .cbm-tab-individual .blk-lg-2 {
        width: 30px
    }

    .cbm-sidebox-content .cbm-box-top .blk-lg-8 {
        width: calc(100% - 35px)
    }


    .blk-lg-12 {
        width: 100%
    }
}

@media (min-width :510px) {
    .cbm-box .cbm-box-md {
        width: 500px
    }

    .cbm-sidebox.cbm-fade.in {
        width: 470px
    }

    .cbm-box .cbm-box-sm {
        width: 470px;
        height: 600px
    }

    .cbm-gm-search-box .cbm-box-sm {
        width: 379px
    }
}

.cbm-title {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.imscb-header-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between
}

textarea::-webkit-input-placeholder {
    color: #949494 !important
}

textarea:-moz-placeholder {
    color: #949494 !important
}

textarea::-moz-placeholder {
    color: #949494 !important
}

textarea:-ms-input-placeholder {
    color: #949494 !important
}

textarea::placeholder {
    color: #949494 !important
}

#cbm-tab-individual .cbm-name-status-container.cbm-box-title {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start
}

#cbm-tab-individual .cbm-name-status-container.cbm-box-title.padding {
    padding-left: 17px
}

.cbm-name-status-container .cbm-tab-title-w-status,
.cbm-name-status-container .cbm-tab-title-w-typing {
    line-height: 22px;
    height: auto;
    width: 225px
}

.cbm-sidebox.cbm-fade.in.popup-enabled .cbm-name-status-container #cbm-tab-title.cbm-title-width-with-faq,
.cbm-sidebox.cbm-fade.in.popup-enabled .cbm-name-status-container #cbm-tab-title.cbm-title-width-wo-faq-with-close-btn {
    width: 205px
}

.cbm-sidebox.cbm-fade.in.popup-enabled .cbm-name-status-container #cbm-tab-title.cbm-title-width-with-faq-close-btn {
    width: 155px
}

#cbm-tab-title.cbm-tab-title-w-status {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: .7px;
    color: #fff
}

div#cbm-tab-title.cbm-tab-title {
    text-overflow: ellipsis;
    max-width: 140px
}

.cbm-close-sidebox {
    border-radius: 6px;
    transition: background-color .2s ease-in-out
}


.cbm-close-sidebox:hover {
    background-color: rgba(0, 0, 0, .14);
    background-color: #b5b5b5;
    border-radius: 6px
}

.imscb-conversation-container-right {
    margin-left: 38px
}

@media only screen and (max-width:600px) {
    .cbm-sidebox {
        right: 0 !important;
        bottom: 0;
        top: 0;
        left: 0 !important;
        border-radius: 0 !important;
        height: auto;
        max-height: 100% !important
    }

    .cbm-sidebox.cbm-fade.in {
        width: 100%;
        height: auto;
        max-height: 100% !important
    }

    .cbm-box .cbm-box-md,
    .cbm-sidebox .cbm-box-sm {
        width: 100%;
        height: 100% !important
    }
}

@-webkit-keyframes mckSpinnerRotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes mckSpinnerRotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes mckSpinnerDash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px
    }
}

@keyframes mckSpinnerDash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px
    }
}

@-webkit-keyframes mckSpinnerColor {

    0%,
    100% {
        stroke: #d62d20
    }

    40% {
        stroke: #0057e7
    }

    66% {
        stroke: #008744
    }

    80%,
    90% {
        stroke: #ffa700
    }
}

@keyframes mckSpinnerColor {

    0%,
    100% {
        stroke: #d62d20
    }

    40% {
        stroke: #0057e7
    }

    66% {
        stroke: #008744
    }

    80%,
    90% {
        stroke: #ffa700
    }
}

#cbm-sidebox ::-webkit-scrollbar {
    -webkit-appearance: none
}

#cbm-sidebox ::-webkit-scrollbar:vertical {
    width: 7px
}

#cbm-sidebox ::-webkit-scrollbar:horizontal {
    height: 9px
}

#cbm-sidebox ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .2);
    -webkit-border-radius: 4px;
    border-radius: 4px
}

#cbm-sidebox ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, .08)
}

.cbm-flexi {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.cbm-read-icon .cbm-delivery-report--delivered-read,
.cbm-read-icon .cbm-delivery-report--sent {
    fill: #4fc3f7
}

.cbm-sent-icon .cbm-delivery-report--delivered-read {
    display: none
}

.cbm-delivered-icon .cbm-delivery-report--delivered-read {
    display: block
}

.cbm-pending-icon .cbm-delivery-report--delivered-read,
.cbm-pending-icon .cbm-delivery-report--sent {
    display: none
}

.cbm-pending-icon .cbm-delivery-report--pending {
    display: block;
    -ms-transform: matrix(1.2, 0, 0, 1.2, -1, -4);
    -webkit-transform: matrix(1.2, 0, 0, 1.2, -1, -4);
    transform: matrix(1.2, 0, 0, 1.2, -1, -4)
}

.cbm-delivered-icon .cbm-delivery-report--pending,
.cbm-read-icon .cbm-delivery-report--pending,
.cbm-sent-icon .cbm-delivery-report--pending {
    display: none
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    input,
    select,
    textarea {
        font-size: 16px
    }
}

@media (max-width:700px) {

    input[type=color],
    input[type=date],
    input[type=datetime-local],
    input[type=datetime],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=password],
    input[type=search],
    input[type=tel],
    input[type=text],
    input[type=time],
    input[type=url],
    input[type=week],
    select:focus,
    textarea {
        font-size: 16px !important
    }
}

.cbm-agent-image-container {
    /* width: 40px;
    height: 38px;
    margin-right: 8px;
    border-radius: 50%;
    position: relative;
    margin-left: 15px; */
    /* width: 225px; */
    margin-left: 0px;

}

.cbm-agent-image-container .cbm-agent-image {
    /* width: 38px;
    height: 38px;
    object-fit: cover;
    border-radius: 50%;
    vertical-align: baseline; */
    width: 270px;
}

.cbm-agent-image-container .cbm-agent-status-indicator {
    position: absolute;
    bottom: -3px;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: solid 2.5px #5553b7;
    background: #aaa
}

.cbm-agent-image-container .cbm-agent-status-indicator.cbm-status--online {
    background-color: #2bef84
}

.cbm-agent-image-container .cbm-agent-status-indicator.cbm-status--offline {
    background-color: #e2e2e2
}

.cbm-agent-image-svg-container {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background-color: #3289c8;
    display: flex;
    align-items: center;
    justify-content: center
}

.cbm-agent-image-svg-container svg {
    width: 38px;
    height: 38px
}


.cbm-msg-text.cbm-msg-content>div {
    line-height: 20px
}

.imscb-iframe-sidebox-border-radius {
    border-radius: 8px !important;
    border: 1px solid #e5e5e5 !important;
}

@keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale3d(.8, .8, .8)
    }

    to {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1)
    }
}

@-webkit-keyframes fadeInScale {
    from {
        opacity: 0;
        transform: scale3d(.8, .8, .8)
    }

    to {
        opacity: 1;
        transform: scale3d(1.1, 1.1, 1.1)
    }
}

#send-button-wrapper {
    animation-name: fadeInScale;
    -webkit-animation-name: fadeInScale;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(.3, .61, .01, 1.2);
    -webkit-animation-timing-function: cubic-bezier(.3, .61, .01, 1.2)
}

#cbm-autosuggest-search-input.imscb-auto-expand {
    box-sizing: border-box;
    overflow: hidden;
    width: calc(100% - 30px);
    border-radius: 6px;
    border: 0;
    resize: none;
    overflow-y: auto;
    min-height: 30px;
    max-height: 90px;
}

.imscb-clubbing-first.cbm-msg-avator-bubble .cbm-msg-box {
    box-shadow: none
}

.imscb-clubbing-first.cbm-msg-avator-bubble {
    margin-bottom: 3px
}

.imscb-clubbing-last.cbm-msg-avator-bubble {
    margin: 3px 0
}

.imscb-clubbing-first.cbm-msg-avator-bubble .cbm-msg-left-muted,
.imscb-clubbing-first.cbm-msg-avator-bubble .cbm-msg-right-muted {
    display: none
}

.imscb-clubbing-first.cbm-msg-avator-bubble.cbm-msg-left .cbm-msg-avator,
.imscb-clubbing-last.cbm-msg-avator-bubble .cbm-conversation-name {
    display: none
}

.imscb-clubbing-last.cbm-msg-avator-bubble.cbm-msg-left .cbm-msg-avator {
    bottom: 0px;
    top: auto
}

button:active,
button:focus,
button:hover {
    outline: 0 !important
}

div[name=message]:last-child {
    margin-bottom: 30px
}

#cbm-message-cell div[name=message]:first-of-type+.imscb-typing-wrapper {
    margin-top: 15px
}

#cbm-sidebox {
    animation: kmOpeningAnimation .3s cubic-bezier(.25, .1, .25, 1)
}

@media only screen and (width:442px) {


    .cbm-sidebox.cbm-fade.in.popup-enabled {
        height: calc(100% - 100px);
        max-width: 394px !important;
        margin: 10px 34px 0 !important;
        box-shadow: 2px 3px 10px 2px rgba(139, 133, 133, .5)
    }

    .cbm-sidebox.cbm-fade.in.popup-enabled.align-left {
        margin: 10px 12px 0 !important
    }

    .cbm-sidebox.cbm-fade.in.popup-enabled .cbm-close-btn {
        width: 0 !important;
        overflow: hidden
    }
}

.cbm-box-body .cbm-message-inner {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden
}

@keyframes kmOpeningAnimation {
    from {
        opacity: .6;
        transform: translateY(45px)
    }

    to {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes kmScaleAnim {
    from {
        opacity: .6;
        transform: scale(.7)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes kmFadeIn {
    from {
        opacity: .2
    }

    to {
        opacity: 1
    }
}

@-o-keyframes woong {
    0% {
        -webkit-trasform: scale(1.2)
    }

    50% {
        -webkit-transform: scale(1.8);
        opacity: .2
    }

    100% {
        -webkit-transform: scale(2.4);
        opacity: 0
    }
}

@-webkit-keyframes woong {
    0% {
        -webkit-trasform: scale(1.2)
    }

    50% {
        -webkit-transform: scale(1.8);
        opacity: .2
    }

    100% {
        -webkit-transform: scale(2.4);
        opacity: 0
    }
}

@-moz-keyframes woong {
    0% {
        -webkit-trasform: scale(1.2)
    }

    50% {
        -webkit-transform: scale(1.8);
        opacity: .2
    }

    100% {
        -webkit-transform: scale(2.4);
        opacity: 0
    }
}

@keyframes woong {
    0% {
        -webkit-trasform: scale(1.2)
    }

    50% {
        -webkit-transform: scale(1.8);
        opacity: .2
    }

    100% {
        -webkit-transform: scale(2.4);
        opacity: 0
    }
}

body:not(.accesibility) :focus {
    outline: 0
}

body.accesibility :focus {
    outline: 5px auto #3b99fc
}

@-webkit-keyframes imscb-reveal-animation-horizontal {
    from {
        opacity: 1
    }

    to {
        opacity: 1;
        -webkit-clip-path: circle(345px at 315px 35px);
        clip-path: circle(345px at 315px 35px)
    }
}

@-moz-keyframes imscb-reveal-animation-horizontal {
    from {
        opacity: 1
    }

    to {
        opacity: 1;
        clip-path: circle(345px at 315px 35px)
    }
}

@-o-keyframes imscb-reveal-animation-horizontal {
    from {
        opacity: 1
    }

    to {
        opacity: 1;
        clip-path: circle(345px at 315px 35px)
    }
}

@keyframes imscb-reveal-animation-horizontal {
    from {
        opacity: 1
    }

    to {
        opacity: 1;
        -webkit-clip-path: circle(345px at 315px 35px);
        clip-path: circle(345px at 315px 35px)
    }
}

@-webkit-keyframes imscb-scale-animation {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(1.3);
        transform: scale(1.3)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-moz-keyframes imscb-scale-animation {
    0% {
        -moz-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -moz-transform: scale(1.3);
        transform: scale(1.3)
    }

    100% {
        -moz-transform: scale(1);
        transform: scale(1)
    }
}

@-o-keyframes imscb-scale-animation {
    0% {
        -o-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -o-transform: scale(1.3);
        transform: scale(1.3)
    }

    100% {
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes imscb-scale-animation {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }

    50% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3)
    }

    100% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes imscb-slide-in-animation {
    from {
        opacity: 0;
        padding-left: 5px
    }

    to {
        opacity: 1;
        padding-left: 0
    }
}

@-moz-keyframes imscb-slide-in-animation {
    from {
        opacity: 0;
        padding-left: 5px
    }

    to {
        opacity: 1;
        padding-left: 0
    }
}

@-o-keyframes imscb-slide-in-animation {
    from {
        opacity: 0;
        padding-left: 5px
    }

    to {
        opacity: 1;
        padding-left: 0
    }
}

@keyframes imscb-slide-in-animation {
    from {
        opacity: 0;
        padding-left: 5px
    }

    to {
        opacity: 1;
        padding-left: 0
    }
}

@-webkit-keyframes imscb-reveal-animation-vertical {
    from {
        opacity: .6
    }

    to {
        opacity: 1;
        -webkit-clip-path: circle(365px at 335px 50px);
        clip-path: circle(365px at 335px 50px)
    }
}

@-moz-keyframes imscb-reveal-animation-vertical {
    from {
        opacity: .6
    }

    to {
        opacity: 1;
        clip-path: circle(365px at 335px 50px)
    }
}

@-o-keyframes imscb-reveal-animation-vertical {
    from {
        opacity: .6
    }

    to {
        opacity: 1;
        clip-path: circle(365px at 335px 50px)
    }
}

@keyframes imscb-reveal-animation-vertical {
    from {
        opacity: .6
    }

    to {
        opacity: 1;
        -webkit-clip-path: circle(365px at 335px 50px);
        clip-path: circle(365px at 335px 50px)
    }
}


@media only screen and (max-device-width:360px) and (min-device-width:320px) {

    .cbm-sidebox.cbm-fade.in .cbm-name-status-container #cbm-tab-title.cbm-title-width-with-faq,
    .cbm-sidebox.cbm-fade.in .cbm-name-status-container #cbm-tab-title.cbm-title-width-with-faq-close-btn {
        width: 130px !important
    }
}

@media only screen and (min-device-width:365px) and (max-device-width:415px) {

    .cbm-sidebox.cbm-fade.in .cbm-name-status-container #cbm-tab-title.cbm-title-width-with-faq,
    .cbm-sidebox.cbm-fade.in .cbm-name-status-container #cbm-tab-title.cbm-title-width-with-faq-close-btn {
        width: 145px !important
    }

    .cbm-name-status-container #cbm-tab-title {
        width: 270px
    }
}

@-webkit-keyframes imscb-animation-launcher-icon {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1)
    }
}

@-moz-keyframes imscb-animation-launcher-icon {
    0% {
        opacity: 0;
        -moz-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }

    100% {
        opacity: 1;
        -moz-transform: scale(1, 1);
        transform: scale(1, 1)
    }
}

@-o-keyframes imscb-animation-launcher-icon {
    0% {
        opacity: 0;
        -o-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }

    100% {
        opacity: 1;
        -o-transform: scale(1, 1);
        transform: scale(1, 1)
    }
}

@keyframes imscb-animation-launcher-icon {
    0% {
        opacity: 0;
        -webkit-transform: scale(.5, .5);
        -moz-transform: scale(.5, .5);
        -o-transform: scale(.5, .5);
        transform: scale(.5, .5)
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        transform: scale(1, 1)
    }
}

.imscb-conversation-header-title-container {
    /* width: 65%; */
    width: 90%
}

#cbm-tab-individual {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.imscb-faq-and-close-btn-container {
    justify-content: flex-end
}

.cbm-msg-left [data-hidepostcta=true] {
    display: none
}

.cbm-msg-left:last-child [data-hidepostcta=true] {
    display: block;
    visibility: visible
}

.cbm-msg-left.contains-quick-replies-only:not(:last-child) {
    display: none
}

.cbm-msg-left [data-hidepostformsubmit=true] {
    display: none
}

.cbm-msg-left:last-child [data-hidepostformsubmit=true] {
    display: block;
    visibility: visible
}

.imscb-done-button {
    float: right
}

.imscb-room-person-selector-container .imscb-person-selector .imscb-person-number-field:disabled,
.imscb-room-person-selector-container .imscb-room-selector .imscb-room-number-field:disabled {
    background-color: transparent;
    border: none;
    color: #000;
    font-size: 14px;
    text-align: center;
    width: 30px;
    margin-left: 22px
}

.imscb-person-selector,
.imscb-room-selector {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center
}

.imscb-room-person-selector-container input[type=button] {
    height: 30px;
    width: 30px;
    padding: 0;
    margin-left: 10px;
    border: #ddd 1px solid;
    background-color: #fff;
    color: #000;
    border-radius: 50%;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, .12);
    outline: 0;
    cursor: pointer
}

.cbm-msg-box-rich-text-container {
    top: 0;
    float: left;
    margin: 1px 0 0 0;
    color: #7766b5
}

.cbm-msg-box-rich-text-container .imscb-carousel-card-template.imscb-single-card {
    margin-left: 0 !important
}

.imscb-guest-text-lable .imscb-children-text-lable {
    width: 30 px
}

.imscb-children-text-lable span {
    display: block;
    font-size: 12px;
    color: rgba(0, 0, 0, .5)
}

.imscb-card-message-container {
    display: flex;
    margin: 0;
    width: 315px;
    padding-left: 0;
    max-width: 100%;
    transform: translateX(-10px)
}

.imscb-next-card-btn,
.imscb-previous-card-btn {
    position: absolute;
    bottom: 50%;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, .12);
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    border: none;
    outline: 0;
    z-index: 5
}

.imscb-next-card-btn {
    right: 0
}

.imscb-previous-card-btn {
    left: 0
}

.imscb-single-card-message {
    width: 300px !important;
    margin-left: 4px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px 0 rgba(0, 0, 0, .12)
}

.imscb-single-card-message:focus {
    outline: 0
}

.imscb-card-message-header {
    border-radius: 8px;
    position: relative
}

.imscb-card-message-image-continer {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100px;
    background-size: cover;
    background-position-y: 30%;
    object-fit: cover
}

.imscb-image-card-2 {
    /* background-image: url(/images/card-message-img-2.jpg) */
}

.imscb-card-message-image-price-container {
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: 0;
    right: auto;
    background-color: #fff;
    padding: 5px 15px;
    border-radius: 0 3px 3px 0;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5)
}

.imscb-card-message-body {
    height: 67px;
    padding: 0 10px;
    border-bottom: 1px solid #d9d9d9
}

.imscb-card-message-body-title {
    font-size: 16px;
    margin: 10px 0 5px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.imscb-card-message-body-ratings svg {
    width: 16px;
    height: 16px
}

.imscb-star-filled {
    fill: gold
}

.imscb-star-empty {
    fill: #c1c1c1
}

.imscb-card-message-body-address {
    width: 100%;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000
}

.imscb-card-message-body-address-icon svg {
    width: auto;
    height: 16px;
    margin-bottom: -3px;
    fill: #000
}

.card-message-footer {
    width: 100%;
    height: 34px
}

.imscb-card-message-footer-button {
    width: 100%;
    outline: 0;
    border: none;
    background: 0 0;
    height: 35px;
    color: #7766b5 !important;
    font-size: 15px !important;
    cursor: pointer !important
}

.imscb-card-message-img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:after,
.slick-track:before {
    display: table;
    content: ''
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    display: none;
    float: left;
    min-height: 1px
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.tns-nav {
    display: none
}

.tns-controls {
    padding-bottom: 8px
}

.imscb-cta-multi-button-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    flex-wrap: wrap
}

.imscb-quick-rpy-btn {
    width: auto;
    padding: 5px 10px;
    border-radius: 15px;
    min-width: 35px;
    font-size: 14px;
    letter-spacing: normal
}

.imscb-cta-button,
.imscb-cta-button-many,
.imscb-quick-rpy-btn {
    word-break: break-word;
    overflow-wrap: break-word
}

.imscb-cta-multi-button-container .imscb-cta-button-1,
.imscb-cta-multi-button-container .imscb-cta-button-many {
    margin-top: 10px;
    margin-right: 10px
}

.imscb-cta-multi-button-container .imscb-cta-button-2 {
    margin-top: 10px;
    margin-right: 10px;
    padding: 6px 14px
}

.imscb-cta-multi-button-container button a {
    margin: -6px -10px;
    padding: 8px 10px
}

.imscb-cta-button {
    color: #5553b7;
    border: 1px solid #5553b7
}

.imscb-undecorated-link {
    text-decoration: none
}

.imscb-cta-button.imscb-link-button {
    text-align: left;
    font-size: 12px
}

.imscb-cta-button.imscb-link-button span {
    vertical-align: sub;
    margin-left: 5px
}

.imscb-cta-button.imscb-link-button span svg {
    margin-bottom: .1rem
}

.imscb-cta-button.imscb-carousel-card-button,
.imscb-cta-button.imscb-faq-list-link-button {
    border: 1px solid #e7e4e4;
    text-align: center;
    padding-left: 0;
    font-size: 12px
}

.imscb-cta-button.imscb-faq-list-link-button {
    border: none;
    font-size: 14px
}

.imscb-cta-button.imscb-carousel-card-button:hover,
.imscb-cta-button.imscb-faq-list-link-button:hover {
    text-decoration: none
}

.imscb-guest-detail-form {
    border-bottom: 1px solid#eee
}

.imscb-title-select {
    -webkit-appearance: none;
    margin-top: 10px;
    display: inline;
    width: 41.5%;
    font-size: 16px !important;
    border-radius: 18px;
    padding: 6px 5px 6px 10px;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, .5);
    position: relative
}

.imscb-select-title {
    position: relative;
    display: inline
}

.imscb-select-title::after {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #333;
    pointer-events: none
}

.imscb-guest-details-container input {
    width: 91.5%;
    border-radius: 18px;
    outline: 0;
    border: 1px solid rgba(0, 0, 0, .5);
    margin-top: 8px;
    font-size: 16px;
    padding: 6.5px 10px
}

.imscb-input:last-child {
    margin-bottom: 15px !important
}

.imscb-mandatory-field-error {
    font-size: 12px;
    color: #ff4a2a;
    margin-top: 6px
}

.imscb-age-input {
    display: inline !important;
    width: 43% !important;
    margin-left: 5px !important
}

.imscb-guest-button-container {
    margin-top: 15px;
    margin-bottom: 10px
}

.imscb-done-button {
    float: right
}

.imscb-fixed-container {
    width: 96%;
    overflow: hidden;
    overflow: hidden;
    border-radius: 5px
}

.imscb-border-less-container {
    width: 96%;
    overflow: hidden;
    overflow: hidden
}

.imscb-border-less-container button {
    display: inline-block !important
}

.imscb-slick-container .tns-controls button {
    position: absolute;
    padding: 0;
    bottom: 50%;
    background-color: #fff;
    width: 30px;
    height: 30px;
    border-radius: 44px;
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, .12);
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    border: none;
    outline: 0;
    z-index: 5;
    transform: translateY(50%);
    padding: 0
}

.imscb-slick-container .tns-controls button:first-child {
    left: 45px
}

.imscb-slick-container .tns-controls button:last-child {
    right: 0
}

.imscb-slider-multiple-cards-container .tns-controls button:first-child {
    left: 0
}

.imscb-card-room-detail-container {
    margin: 0;
    width: 315px;
    padding-left: 0;
    max-width: 100%;
    transform: translateX(-10px)
}

.message.imscb-blocked-room {
    padding: 0;
    width: 100%
}

.imscb-blocked-room-details {
    padding: 0
}

.imscb-blocked-room-image-container {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100px;
    background-size: cover;
    background-position-y: 30%
}

.imscb-blocked-room-text-container {
    padding-top: 5px
}

.imscb-blocked-room-button-container {
    width: 100%;
    height: 35px
}

.imscb-blocked-room-button-container button {
    width: 100%;
    outline: 0;
    border: none;
    background: 0 0;
    height: 100%;
    font-size: 15px;
    cursor: pointer
}

.imscb-blocked-room-guests,
.imscb-blocked-room-price,
.imscb-blocked-room-room-type,
.imscb-blocked-room-sub-total {
    border-bottom: 1px solid rgba(0, 0, 0, .24);
    padding: 7px 12px;
    display: flex;
    justify-content: space-between
}

.imscb-blocked-room-guests span:first-child,
.imscb-blocked-room-room-type span:first-child {
    font-size: 14px;
    font-weight: 400
}

.imscb-blocked-room-guests span:last-child,
.imscb-blocked-room-price span:last-child,
.imscb-blocked-room-room-type span:last-child,
.imscb-blocked-room-sub-total span:last-child {
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    width: 122px;
    overflow: hidden;
    text-align: right;
    word-wrap: break-word
}

.imscb-blocked-room-sub-total span:last-child {
    font-size: 20px;
    font-weight: 700;
    float: right
}

.imscb-blocked-room-price p {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 5px;
    display: inline-block
}

.imscb-blocked-room-price p>span,
.imscb-blocked-room-sub-total p>span {
    font-size: 12px !important;
    font-weight: 400 !important
}

.imscb-blocked-room-sub-total p {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 5px;
    display: inline-block
}

.imscb-pxinfo-btn-left {
    display: inline !important;
    width: 41.5% !important
}

.imscb-pxinfo-btn-right {
    display: inline !important;
    width: 43% !important;
    margin-left: 5px !important
}

.cbm-away-msg {
    font-size: 14px;
    letter-spacing: .5px;
    text-align: center;
    color: #8f8f8f;
    margin-bottom: 0 !important;
    font-weight: 300;
    line-height: 17px
}

#cbm-away-msg-box {
    border: none;
    padding: 0 25px 20px
}

#cbm-away-msg {
    white-space: pre-line;
    word-break: break-word
}

.cbm-collect-email {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .4px;
    color: #6b6767
}

#cbm-email-error-alert {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .4px;
    color: #f46262
}

#cbm-email-collection-box,
#cbm-email-error-alert-box {
    text-align: center;
    padding: 0 25px 10px
}

#cbm-away-msg-box hr,
#cbm-email-collection-box hr,
#cbm-email-error-alert-box hr {
    border: none;
    margin-top: 0;
    margin-bottom: 10px;
    background-image: linear-gradient(to right, #ddd 40%, rgba(255, 255, 255, 0) 0);
    background-position: left;
    background-size: 14px 1px;
    background-repeat: repeat-x;
    height: 2px
}

.imscb-message.imscb-received.imscb-chat-faq-list {
    padding: 0;
    background: 0 0;
    width: 100%;
    box-sizing: border-box;
    border-radius: 5px
}

.imscb-list-image {
    max-width: 100%;
    object-fit: contain
}

.imscb-faq-list--header_text-img {
    background-color: rgba(103, 103, 103, 0);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, .75)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .75) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .75) 100%)
}

.imscb-faq-list--header_text-img img {
    object-fit: cover;
    width: 100%;
    height: 110px;
    position: relative;
    z-index: 0;
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px
}

.imscb-faq-list--body_list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important
}

.imscb-faq-list--body_list li>a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    border-bottom: solid 1px #e5e5e5;
    color: grey;
    transition: all .3s ease-in-out
}

.imscb-faq-list--body_que-ans {
    padding: 10px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 51px;
    overflow: hidden;
    transition: all .3s ease-in-out
}

.imscb-faq-list--body_que-ans * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.imscb-faq-list--body_list li>a:hover {
    background-color: #efefef;
    color: grey;
    text-decoration: none !important
}

.imscb-faq-list--body_que-ans .imscb-faq-list--body_que {
    font-size: 15px;
    color: #000;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.imscb-faq-list--body_que-ans .imscb-faq-list--body_ans {
    font-size: 14px;
    color: #797474;
    margin: 5px 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.imscb-faq-list--body_img {
    padding: 10px 0 10px 12px;
    height: 50px;
    border-radius: 4px
}

.imscb-faq-list--body_img img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px
}

.imscb-faq-list--body_img:empty .imscb-faq-list--body_que-ans {
    width: 100%
}

.imscb-faq-list--header {
    position: relative
}

.imscb-faq-list--header_text {
    bottom: 2px;
    left: 12px;
    margin: 0;
    margin-bottom: 0 !important;
    padding: 9px 7px !important;
    text-align: center;
    width: calc(100% - 12px);
    color: #303030;
    word-wrap: break-word;
    font-size: 16px;
    border-bottom: 1px solid #edeff1
}

.imscb-faq-list--footer {
    width: 100%;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px
}

.imscb-faq-list--footer_button-container button {
    width: 100%;
    outline: 0;
    border: none;
    background: 0 0;
    border-radius: 4px;
    letter-spacing: 1px;
    font-size: 15px;
    cursor: pointer;
    padding: 12px 0;
    border-radius: 0
}

.imscb-faq-list--footer_button-container button a {
    color: #5c5aa7
}

.imscb-faq-list--header_text-container {
    border: none !important;
    min-height: 10px
}

.imscb-faq-list--footer_button-container button:not(:last-child) {
    border-bottom: 1px solid #e5e5e5 !important
}

.imscb-faq-list--body_list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 0 !important
}

.imscb-faq-list--body_list li>a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    border-bottom: solid 1px #e5e5e5;
    color: grey;
    transition: all .3s ease-in-out
}

.imscb-faq-list--body_que-ans {
    padding: 10px 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 51px;
    overflow: hidden;
    transition: all .3s ease-in-out
}

.imscb-faq-list--body_que-ans * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.imscb-faq-list--body_list li>a:hover {
    background-color: #efefef;
    color: grey;
    text-decoration: none !important
}

.imscb-faq-list--body_que-ans .imscb-faq-list--body_que {
    font-size: 15px;
    color: #000;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.imscb-faq-list--body_que-ans .imscb-faq-list--body_ans {
    font-size: 14px;
    color: #797474;
    margin: 5px 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.imscb-faq-list--body_img {
    padding: 10px 0 10px 12px;
    height: 50px;
    border-radius: 4px
}

.imscb-faq-list--body_img img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px
}

.imscb-faq-list--body_img:empty .imscb-faq-list--body_que-ans {
    width: 100%
}

.imscb-faq-list--header {
    position: relative
}

.imscb-faq-list--header_text {
    bottom: 2px;
    left: 12px;
    margin: 0;
    margin-bottom: 0 !important;
    padding: 9px 7px !important;
    text-align: center;
    width: calc(100% - 12px);
    color: #303030;
    word-wrap: break-word;
    font-size: 16px;
    border-bottom: 1px solid #edeff1
}

.imscb-faq-list--footer {
    width: 100%;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px
}

.imscb-faq-list--footer_button-container button {
    width: 100%;
    outline: 0;
    border: none;
    background: 0 0;
    border-radius: 4px;
    letter-spacing: 1px;
    font-size: 15px;
    cursor: pointer;
    padding: 12px 0;
    border-radius: 0
}

.imscb-faq-list--footer_button-container button a {
    color: #5c5aa7
}

.imscb-faq-list--header_text-container {
    border: none !important;
    min-height: 10px
}

.imscb-faq-list--footer_button-container button:not(:last-child) {
    border-bottom: 1px solid #e5e5e5 !important
}

.imscb-message.imscb-received.imscb-faq-answer {
    padding: 0;
    background: 0 0;
    width: 100%;
    background-color: #e5e5e5
}

.imscb-faq-answer--body {
    border-radius: 5px;
    background-color: #e5e5e5
}

.imscb-faq-answer--body_container {
    padding: 10px
}

.imscb-faq-answer--body_container ol,
.imscb-faq-answer--body_container ul {
    margin-left: 12px
}

.imscb-faq-answer--body_que {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .5px;
    color: #141515
}

.imscb-faq-answer--body_ans {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .5px;
    color: #646262
}

.imscb-faq-answer--footer {
    padding: 10px 10px
}

.imscb-faq-answer--footer_button-text-container {
    display: flex;
    justify-content: center;
    align-items: center
}

.imscb-faq-answer--footer_button-text-container p {
    margin-bottom: 0;
    margin-right: 10px;
    color: #646262;
    font-size: 14px;
    letter-spacing: .5px
}

.imscb-faq-answer--footer_button-text-container button {
    margin-left: 10px;
    margin-right: 10px
}

.imscb-faq-list--header .imscb-faq-list--header_text-container:first-child {
    background-color: #fff
}

.imscb-faq-dialog-button {
    min-width: 40px;
    border-radius: 15px;
    border: 1px solid #5553b7
}

.imscb-faq-answer--body .imscb-faq-answer--body_container {
    color: #646262
}

.imscb-faq-answer--body .imscb-faq-answer--body_container img {
    width: 100%;
    height: auto
}

#cbm-email-collection-box .svg-container,
#cbm-email-error-alert-box .svg-container {
    width: 45px;
    height: 45px;
    border-radius: 40px;
    background-color: #edebeb;
    line-height: 52px;
    margin: 5px auto
}

#cbm-email-collection-box .svg-container svg,
#cbm-email-error-alert-box .svg-container svg {
    margin-top: 16px
}

#cbm-email-error-alert-box .svg-container {
    background-color: #f1a5a5
}

.imscb-template-img {
    width: 100%;
    height: auto;
    border-radius: 10px
}

.imscb-template-img-caption,
.imscb-template-video-caption {
    padding-top: 5px;
    font-weight: 400;
    text-align: center;
    color: #6f6e6e;
    letter-spacing: .3px;
    font-size: 15px
}

.imscb-msg-box .email-message-indicator,
.cbm-msg-box-rich-text-container .email-message-indicator {
    margin-bottom: 15px
}

.email-conversation-indicator span:first-child,
.imscb-msg-box .email-message-indicator span:first-child,
.cbm-msg-box-rich-text-container .email-message-indicator span:first-child {
    margin-right: 10px
}

.imscb-msg-box .email-message-indicator span:first-child svg,
.cbm-msg-box-rich-text-container .email-message-indicator span:first-child svg .email-conversation-indicator span:first-child svg {
    vertical-align: middle
}

.email-conversation-indicator span:last-child,
.imscb-msg-box .email-message-indicator span:last-child,
.cbm-msg-box-rich-text-container .email-message-indicator span:last-child {
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .6px;
    text-align: left;
    color: #414243
}

.cbm-msg-box-rich-text-container .email-message-indicator {
    padding: 5px 14px 10px;
    margin-bottom: -5px;
    background-color: #fff;
    border-radius: 5px 5px 0 0
}

.chat-popup-widget-container .chat-popup-widget-close-btn,
.cbm-msg-preview-visual-indicator-container .cbm-close-btn {
    border-radius: 100px;
    background-color: #a19e9e;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 26px
}

.cbm-cont-msg-wrapper .email-message-indicator .cbm-cont-msg-via-email {
    margin-left: 10px
}

.imscb-carousel-card-template {
    max-width: 240px;
    width: 240px !important;
    margin: 8px 4px 0 10px;
    position: relative
}

.tns-slider .imscb-carousel-card-template {
    margin: 8px 4px 0 6px
}

.imscb-carousel-card-img {
    width: 100%;
    height: 93px;
    object-fit: cover
}

.imscb-carousel-card-overlay-text {
    position: absolute;
    bottom: 30px;
    left: 0;
    background-color: #fff;
    padding: 10px;
    color: #0d0d0e;
    font-weight: 500;
    display: inline-block;
    border-radius: 0 4px 4px 0;
    font-size: 16px;
    border-radius: 0 4px 4px 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .5)
}

.imscb-carousel-card-header {
    position: relative;
    height: 93px
}

.imscb-carousel-card-title {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .6px;
    color: #141313;
    word-break: break-word;
    text-overflow: ellipsis
}

.imscb-carousel-card-sub-title {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .5px;
    color: #565454;
    margin-top: 4px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 20px
}

.imscb-carousel-card-content-wrapper {
    padding-top: 6px;
    padding-left: 9px;
    padding-bottom: 6px;
    border: 1px solid #e7e4e4;
    border-top: 0
}

.imscb-carousel-url-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1
}

.imscb-carousel-card-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.imscb-carousel-card-footer .imscb-cta-button,
.imscb-carousel-card-footer .imscb-cta-multi-button-container button {
    width: 100%;
    height: 36px;
    border-color: #e7e4e4;
    border-bottom: none;
    margin: 0;
    background-color: #fff
}

.imscb-carousel-card-footer {
    border-bottom: 1px outset rgba(255, 255, 255, .4);
    border-radius: 0 0 5px 5px;
    overflow: hidden
}

.imscb-carousel-card-img {
    border-radius: 5px 5px 0 0
}

.imscb-carousel-card-title {
    overflow: hidden;
    white-space: nowrap;
    max-width: 220px;
    text-overflow: ellipsis
}

.imscb-carousel-card-title-extension {
    font-size: 12px;
    letter-spacing: .5px;
    color: #000;
    margin-right: 9px;
    margin-left: 8px;
    margin-top: 4px
}

.imscb-carousel-card-description {
    overflow: hidden;
    position: relative;
    line-height: 14.4px;
    max-height: 43.2px;
    margin-right: 12px;
    padding-right: 12px;
    margin-top: 4px;
    font-weight: 300;
    letter-spacing: .5px;
    color: #797474;
    height: auto;
    white-space: pre-line;
    text-overflow: ellipsis
}

.imscb-carousel-card-description:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0
}

.imscb-carousel-card-description:after {
    content: '';
    position: absolute;
    right: 0;
    width: 12px;
    height: 12px;
    margin-top: 2.4px;
    background: #fff
}

.imscb-carousel-card-overlay-text-without-img {
    position: static;
    background-color: #d6d6d6;
    border-radius: 0 0 4px 0;
    box-shadow: none
}

.imscb-carousel-card-header-with-img {
    border: 1px solid #e7e4e4;
    border-bottom: none;
    border-radius: 5px 5px 0 0
}

.imscb-carousel-card-header-without-img {
    height: 37px;
    border: 1px solid #e7e4e4;
    border-bottom: 0;
    border-radius: 2px
}

.imscb-carousel-card-description-wrapper {
    height: 44px
}

.imscb-carousel-card-info-wrapper-without-header {
    border-top: 1px solid #e7e4e4;
    border-radius: 7px 7px 0 0
}

.imscb-carousel-card-info-wrapper-with-buttons {
    border-bottom: 0
}

.imscb-carousel-card-footer button {
    width: 100% !important;
    box-sizing: border-box
}

.imscb-carousel-card-footer form {
    margin: 0
}

.imscb-list-container {
    margin-top: 8px;
    border: solid 1px #e5e5e5
}

.tns-controls button[data-controls=next] svg {
    transform: rotate(180deg)
}

.tns-controls:focus {
    outline: 0
}

.imscb-cta-multi-button-container button.imscb-quick-replies {
    font-size: 14px;
    border: 1px solid;
    background-color: #fff
}

.imscb-cta-multi-button-container button.imscb-cta-button {
    font-size: 14px
}

.imscb-carousel-card-footer .imscb-cta-multi-button-container button.imscb-quick-replies {
    border-bottom: none;
    text-align: center
}

.imscb-slider-multiple-cards-container {
    margin-left: -65px
}

.imscb-cta-multi-button-container .imscb-quick-replies.imscb-carousel-card-button {
    border-color: #e7e4e4
}

.cbm-form-template-wrapper div {
    margin-bottom: 13px
}

.cbm-form-text-wrapper {
    margin-bottom: 15px
}

.cbm-form-dropdown-wrapper select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: 0 0;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 5px
}

.cbm-radio-group-title {
    color: #000;
    margin-bottom: 5px
}

.cbm-form-radio-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap
}

.cbm-form-radio-wrapper div {
    margin-right: 10px
}

.cbm-form-radio-wrapper input {
    font-size: 11px;
    margin-right: 3px
}

.cbm-form-textarea-wrapper textarea {
    resize: vertical
}

.cbm-form-dropdown-wrapper select,
.cbm-form-text-wrapper input,
.cbm-form-textarea-wrapper textarea {
    border: 1px solid #cad3e3;
    color: #000;
    width: 93%;
    padding: 9px;
    margin-bottom: 3px;
    display: inline-block;
    background-color: #fff;
    font-size: 16px
}

.cbm-form-dropdown-wrapper select:disabled,
.cbm-form-template-container button:disabled,
.cbm-form-text-wrapper input:disabled,
.cbm-form-textarea-wrapper textarea:disabled {
    cursor: not-allowed;
    background: #ddd;
    color: #5c6677
}

.cbm-form-dropdown-wrapper label,
.cbm-form-text-wrapper label,
.cbm-form-textarea-wrapper label {
    margin-bottom: 2px;
    margin-left: 2px
}

.cbm-form-text-wrapper input::placeholder,
.cbm-form-textarea-wrapper textarea::placeholder {
    color: #929090;
    font-size: 12px
}

.cbm-form-text-wrapper input::-webkit-input-placeholder,
.cbm-form-textarea-wrapper textarea::-webkit-input-placeholder {
    color: #929090;
    font-size: 12px
}

.cbm-form-text-wrapper input::-moz-placeholder,
.cbm-form-textarea-wrapper textarea::-moz-placeholder {
    color: #929090;
    font-size: 12px
}

.cbm-form-text-wrapper input:-ms-input-placeholder,
.cbm-form-textarea-wrapper textarea:-ms-input-placeholder {
    color: #929090;
    font-size: 12px
}

.cbm-form-text-wrapper input:-moz-placeholder,
.cbm-form-textarea-wrapper textarea:-moz-placeholder {
    color: #929090;
    font-size: 12px
}

.cbm-form-text-wrapper input:focus {
    border: 1px solid #000;
    outline: 0
}

.cbm-form-template-wrapper {
    border-radius: 3px;
    background-color: #f5f5f5;
    border: 1px solid #e5e5ed;
    padding: 16px;
    margin-top: 5px
}

.cbm-form-template-wrapper form {
    margin: 0
}

.cbm-form-label {
    font-size: 12px;
    letter-spacing: .36px;
    color: #333
}

.cbm-form-template-container button {
    min-width: 23%;
    border-radius: 19px;
    padding: 7px;
    margin-top: 12px;
    background-color: #fff
}

.cbm-form-error-text {
    display: block;
    color: #b7394a;
    margin-left: 2px;
    font-size: 12px
}

.cbm-form-submit-button {
    font-size: 14px !important
}

.cbm-rich-video-container {
    margin-top: 5px
}

.cbm-rich-video-container video {
    object-fit: initial
}

.imscb-template-video-caption-wrapper,
.cbm-rich-video-container video,
.cbm-rich-video-iframe {
    min-width: 160px;
    max-width: 100%
}

.link-preview-wrapper {
    width: auto;
    max-height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background: #f5f5fa;
    border-radius: 3px 3px 3px 3px;
    margin: -6px -6px 3px -6.3px
}

.link-preview-image {
    border-radius: 3px 0 0 3px;
    object-fit: cover;
    width: inherit;
    height: inherit
}

.link-preview-image-div {
    width: 150px;
    height: 80px
}

.link-preview-title {
    font-size: 14px !important;
    margin: 0;
    padding-bottom: 2px;
    color: #122;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.link-preview-div-description {
    font-size: 12px;
    line-height: 14px;
    padding-top: 2px;
    color: #67757e;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical
}

.link-preview-content {
    width: 275px;
    padding-left: 5px;
    margin: 2px 0 2px 0;
    border-radius: 0 2px 2px 0
}

.link-preview-title-width {
    width: 200px
}

.link-preview-wrapper+div {
    width: 400px
}

@media only screen and (max-width:600px) {
    .link-preview-image-div {
        width: 80px;
        height: 80px
    }

    .link-preview-content {
        padding-left: 5px;
        width: auto;
        margin: 2px 0 2px 0;
        border-radius: 0 2px 2px 0
    }

    .link-preview-title-width {
        width: 126px
    }

    .is-link {
        width: inherit
    }

    .link-preview-wrapper+div {
        width: auto
    }
}

.imscb-modal input,
.imscb-modal select,
.imscb-modal textarea {
    height: 36px;
    border-radius: 3px;
    border: solid 1px #a1a1a1
}

.imscb-first-input {
    margin: 36px
}

.imscb-modal textarea {
    resize: none
}

.imscb-modal .imscb-section-title {
    width: 100%;
    height: 57px;
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .8px;
    text-align: center;
    color: #565658;
    margin-top: 48px;
    margin-bottom: 36px
}

.imscb-start-conversation {
    width: 100%;
    height: 40px;
    border-radius: 26px;
    background-color: #716fb3;
    margin-top: 45px;
    color: #fff
}

.imscb-start-conversation.focus,
.imscb-start-conversation:active,
.imscb-start-conversation:focus,
.imscb-start-conversation:hover,
.imscb-start-conversation:visited {
    color: #fbfbfb;
    text-decoration: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
    transition: all .3s ease-in-out;
    outline: 0 !important;
    border: none
}

.imscb-start-conversation[disabled],
.imscb-start-conversation[disabled]:active,
.imscb-start-conversation[disabled]:focus,
.imscb-start-conversation[disabled]:hover,
.imscb-start-conversation[disabled]:visited {
    pointer-events: none;
    outline: 0 !important;
    border: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
    transition: all .3s ease-in-out
}

#imscb-form-chat-login {
    margin: 15px;
    padding-bottom: 35px
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0
}

#imscb-tab-title {
    text-transform: capitalize;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400
}

.imscb-typing-indicator {
    width: 8px;
    height: 8px;
    background: #777;
    display: inline-block;
    border-radius: 50%;
    margin-left: 10px
}

.imscb-typing-indicator {
    animation-name: imscb-typing-anim;
    animation-duration: 1s;
    animation-iteration-count: infinite
}

.imscb-typing-indicator:nth-child(2) {
    animation-delay: .2s
}

.imscb-typing-indicator:nth-child(3) {
    animation-delay: .4s
}


@keyframes imscb-typing-anim {
    0% {
        background: #ddd;
        transform: scale(1)
    }

    50% {
        background: #000;
        transform: scale(1.4)
    }

    100% {
        background: #ddd;
        transform: scale(1)
    }
}

.cbm-sidebox textarea {
    background-color: #ffffff !important;
    padding: 5px 5px 5px 10px;
    min-height: 18px;
    max-height: 78px;
    resize: none;
    background-color: #fff;
    width: auto;
    outline: 0 !important;
    margin: 0;
    font-size: 16px;
    overflow: auto;
    line-height: 1.2em;
    overflow-x: hidden;
    display: block !important;
    text-align: left;
    box-shadow: none !important;
    -webkit-user-select: text;
    border: none;
}

.cbm-sidebox textarea:placeholder,
.cbm-sidebox textarea::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: .6px;
    text-align: left;
    color: #949494 !important
}

.mainAnsWrapper {
    display: flex;
    flex-direction: column;
}

.cbm-msg-text-wrapper {
    display: flex;
    flex-direction: column;
}

.cbm-preview-button {
    margin: 6px auto 4px auto !important;
    padding: 6px 20px;
    cursor: pointer !important;
    color: #0074B0 !important;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #0074B0;
}

.cbm-preview-button:hover {
    color: #fff !important;
    background: #0074B0;
    border: 1px solid #fff;
}

.cbm-csat-text-1 {
    text-align: center;
    font-size: 12px;
    margin-top: 3px;
}

.cbm-csat-text-1>span,
.cbm-csat-text-1>a {
    font-weight: bolder;
}

.cbm-csat-text-1>a {
    color: #5553b7 !important;
}

.cbm-csat-text-1>a:hover {
    color: #323085 !important;
    text-decoration: underline;
}

.cbm-csat-text-1>a.disable-restart-conversation:hover {
    text-decoration: none;
}

.custome-modal-open {
    overflow: auto !important;
    padding-right: 0 !important;
}

.cbm-popup-transition>.modal-content>.modal-body {
    padding: 0px !important;
}

.cbm-csat-dt-dropdown-wrapper {
    padding: 2px 10px;
    font-size: 14px;
}

.cbm-csat-rd-dropdown-wrapper {
    padding: 2px 10px;
    font-size: 14px;
}

.cbm-csat-dt-dropdown-wrapper-header {
    color: #5553b7 !important;
    font-weight: bolder;
    cursor: pointer;
}

.cbm-csat-rd-dropdown-wrapper-header {
    color: #5553b7 !important;
    font-weight: bolder;
    cursor: pointer;
}

.cbm-csat-rd-dropdown-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cbm-tile-wrapper {
    position: relative;
    display: flex;
    z-index: 10000;

}

.cbm-tooltip-bottom {
    position: absolute;
    padding: 6px 4px 3px 11px;
    color: #000;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 2px;
    opacity: 1;
    bottom: 4px;
    border: 1px solid #000;

}

.cbm-tooltiptext {
    text-align: left;
    padding: 5px;
    border-radius: 2px;
    z-index: 1;
    transition: opacity .9s;
    font-family: 'J&J CircularTT-Medium';
    display: flex;
    flex-direction: column;
}

.cbm-tablet {
    padding-left: 10px;
    margin: 0 !important;
    padding-left: 10px !important;
    cursor: pointer;
}

.cntryDiv-tablet {
    margin: 3px 0px;
}

.cbm-rd-tile-wrapper {
    position: relative;
    display: flex;
    z-index: 10000;
    justify-content: flex-end;
    margin-right: 10px;

}

.cbm-rd-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 10px 10px;
}

.cbm-rd-box-close {
    float: right;
    font-weight: 700;
    color: #000;
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    outline: 0;
}

.cbm-rd-header-title {
    color: #5553b7;
    font-size: 16px;
}

.cbm-rd-tooltip-bottom {
    max-height: 385px;
    height: auto;
    width: 442px;
    border: 1px solid #5553b7;
    border-radius: 5px;
    left: -11px;
    padding: 10px;
}

.rd-document-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.rd-document-docTitle{
    color: #0074b0;
    text-decoration: none;
    word-break: break-word;
}
.rd-document-name>a {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 99%;
    min-height: 20px;
    color: #0074b0;
    text-decoration: none;
}

.rd-document-name {
    width: 80%;
}

.rd-document-preview-panel {
    width: 20%;
}

.rd-document-preview-button {
    margin: 4px auto !important;
    /* padding: 6px 20px; */
    cursor: pointer !important;
    color: #0074B0 !important;
    border-radius: 5px;
    background: #fff;
    border: 1px solid #0074B0;
}

.rd-document-number {
    color: #5553b7;
    /* margin-left: 12px; */
}

.rd-document-info-wrapper {
    overflow-y: auto;
}

/* .rd-document-info-wrapper::-webkit-scrollbar {
    display: none;
    width: 0 !important
  } */
div.rd-document-info-wrapper>hr {
    margin: 10px 0 !important;
}

.rd-document-info-wrapper-items {
    display: flex;
    flex-direction: column;
}

.bkl-lg-12-assistant-ans {
    display: flex;
    flex-direction: column;
}

.imscb-conversation-container-right-reaction,
.imscb-recommendation-container-right-reaction {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 2px;
    padding-right: 8px;
}


.imscb-conversation-container-right-reaction>button {
    cursor: pointer;
    outline: 0;
    color: #AAA;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: #ededed;
    margin-left: 2px;
}


.imscb-recommendation-container-right-reaction>button {
    cursor: pointer;
    outline: 0;
    color: #AAA;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 2px 4px 0px;
    font-size: 15px;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: #ededed;
    margin-left: 2px;
}

.imscb-conversation-container-right-reaction>button:focus,
.imscb-recommendation-container-right-reaction>button:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.btn-like-green {
    color: green !important;
}

.btn-unlike-red {
    color: red !important;
}

@media screen and (min-height: 495px) and (max-height: 600px) {
    .cbm-rd-tooltip-bottom {
        max-height: 340px;
    }
}

.rd-document-number-reaction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
#cbm-sidebox-ft > div.cbm-tile-wrapper > div > ul > li,#cbm-sidebox-ft > div.cbm-tile-wrapper > div > ul > li > ul > li{
    margin: 2px 0px;
}

.cbm-show-more-less {
    color: #0074B0;
    font-size: "14px";
    font-weight: "bold";
    text-align: center;
    margin-top: 10px;
}

.cbm-show-more-less:hover {
    cursor: pointer;
    text-decoration: underline;
}