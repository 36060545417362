.uudModalHeader {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    flex-direction: column;
    border-bottom: none;
}

.actionItem-headerPart-icon {
    margin: 0 auto;
}

.actionItem-headerPart-title {
    letter-spacing: var(--unnamed-character-spacing-0);
    text-align: left;
    letter-spacing: 0px;
    color: #0074B0;
    text-transform: capitalize;
    opacity: 1;
}

.wnfy-content-loader-wapper {
    display: flex;
}

.UserDetail-MetaDataProperty-Name {
    text-align: left;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    background: #0074B0 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px 0px 5px;

}

.UserDetail-MetaDataProperty-Disable {
    color: #808080;
    background: #FBFBFB 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #9D9D9D4F;
    opacity: 1;
    padding: 5px;
    margin: 5px 0px;
}

.UserDetail-MetaDataProperty-Even {
    background: #f4f4f4 0% 0% no-repeat padding-box;
}

.UserDetail-MetaDataProperty {
    padding: 5px;
    letter-spacing: var(--unnamed-character-spacing-0);
    box-shadow: 3px 3px 6px #9D9D9D4F;
    text-align: left;
    letter-spacing: 0px;
    color: #0074B0;
    opacity: 1;
    margin: 5px 0px;
    display: flex;
    justify-content: space-between;
}

.UserDetail-MetaDataProperty> .MetaDataProperty > b >label {
    color: #000000;
}
.DropdownIcon-ArrowIcon
{
    border: 1px solid #d5d5d5;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    background: #d5d5d5;
}
.DropdownIcon-ArrowIcon > img{
    margin: auto;
}
.MetaDataProperty{
    display: flex;
}
.MetaDataProperty{
    max-width: 710px;
}
.dropdown-item-wrapper
{
    list-style: none;
    position: absolute;
    z-index: 100000;
    text-align: left;
    font-family: 'J&J CircularTT-Medium';
    font-size: 14px;
    color: #333;
    background-color: #fff;
    border: 2px solid #1DA1E5;
    border-radius: 0 0 5px 5px;
    padding: 0.7% 0 1.2% 0;
    margin-top: -1px;
    width: 750px;
    max-height: 200px;
    overflow-y: auto;
}
.dropdown-item-wrapper-open-top{
    margin-top: -200px;
}
.accessgroup-dropdown-item-wrapper-open-top{
    margin-top: -50px;
}
.dropdown-item-wrapper li:hover {
    background-color: #f2f2f2;
}
.dropdown-item-wrapper li {
    color: #7d7a7a;
    padding-left: 6%;
}
.dropdown-item-label{
    margin-left: 5px;
}
.ul-access-group
{
    list-style: none;
}