.action-items-mobile-wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    cursor: pointer;
}
.action-items-mobile-inner-wrapper{
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: space-between;
    top:25px;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    /* border: 0.1px solid #4F565B; */
    /* border-radius: 3px; */
    padding:5px 0;
    right: 0;
    font-size:14px;
    z-index: 100;
    border: 0.20000000298023224px solid #0074B0;
    border-radius: 6px;
    opacity: 1;
    width: 150px;
}
.action-item-text-mobile{
    color: #333;
    margin: 0 0 0 10px;
}
.action-items-mobile-items-wrapper{
    display: flex;
    align-items: center;
    padding: 2px 0;
    cursor: pointer;
    margin: 0 5px;
}
.action-items-mobile-items-wrapper a {
    text-decoration: none;
    color:#333;
    font-weight: normal;
}
.action-items-mobile-items-wrapper a:hover{
    text-decoration: none;
    color:#333;
    font-weight: normal;
}
.action-items-mobile-items-wrapper a:visited{
    text-decoration: none;
    color:#333;
}
.quick-action-item{
    display: flex !important;
    /* flex-wrap: wrap; */
    align-items: center;
    margin: 0 0 0 10%;
}
.action-items-inner-wrapper:hover{
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 4px;
    opacity: 1;
    background: #C7C7C7;
}
.action-items-inner-wrapper-header{
    padding:8% 3%;
    cursor: pointer;
    transition: 0.15s;
    width: 100%;
    text-align: center;
}
.quick-action-divider-mobile{
    border-top: 0.20000000298023224px solid #707070;
    opacity: 0.46;
    /* margin: 2% 0; */
}
.resultDiv-left-outerwrapper{
    width: 5%;
    float: left;
    padding: 1% 0 0 0;
}
@media screen and (min-width: 1700px){
    .resultDiv-left-outerwrapper{
        padding: 0.7% 0 0 0;
    }
}
@media screen and (min-width: 1281px) and (max-width: 1360px){
    .results-div-action-bar-wrapper{
        width: 17%;
    }
    .resultDiv-left-outerwrapper{
        width: 6%;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1280px){
    /* @media screen and (min-width: 1000px) and (max-width: 1360px){ */
    .results-div-action-bar-wrapper{
        width: 21%;
    }
    .resultDiv-left-outerwrapper{
        width: 6%;
    }
}
@media screen and (min-width: 1000px) and (max-width: 1030px){
    .results-div-action-bar-wrapper{
        width: 22%;
    }
    .resultDiv-left-outerwrapper{
        width: 6%;
    }
}

@media screen and (min-width: 600px) and (max-width: 999px){
    .results-div-action-bar-wrapper{
        width: auto;
    }
    .resultDiv-left-outerwrapper{
        width: 6%;
    }
}
@media screen and (max-width: 599px){
    .results-div-action-bar-wrapper{
        width: auto;
    }
    .resultDiv-left-outerwrapper{
        width: 6%;
    }
}

.authoring-popup-transition > .modal-content > .modal-body, .answering-popup-transition > .modal-content > .modal-body, .authoring-gpt-popup-transition > .modal-content > .modal-body{
    padding: 0px !important;
 }


 .authoring-popup-transition.modal-xl, .answering-popup-transition.modal-xl, .authoring-gpt-popup-transition.modal-xl{
    max-width: calc(100vw - 20px);
 }