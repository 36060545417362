.selectedWords{
    display: flex;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0.5rem;
    text-align: left;
}
.label-1 {
    font-size: 14px;
    font-weight: 600;
    color: rgb(50, 49, 48);
    box-sizing: border-box;
    box-shadow: none;
    margin: 0px;
    display: block;
    padding: 5px 0px;
    overflow-wrap: break-word;
    margin-bottom: 2px;
}
.phoneme-text-container{
    margin-left: 2px;
    display: flex;
}
.fieldGroup-563 {
    box-shadow: none;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    border: 1px solid rgb(96, 94, 92);
    border-radius: 2px;
    background: rgb(255, 255, 255);
    cursor: text;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    width: 35rem;
}
.phoneme-preview-audio-button{
    display: inline-block;
    margin-top: 2px
}
.box-container{
    /*border: 2px solid #0078D4;
    margin-top: 1.5rem;
    text-align: center;
    min-height: 200px;
    max-height: 200px;*/
    border: .01rem solid #dcdcdc;
    min-height: 35vh ;
    max-height: 35vh;
    margin-top: .9375rem;
    overflow: auto;
    width: 100%;
    text-align: center;

}
.Sp-content{
    margin-top: 0.75rem;
}
.error-check{
    margin-left: 20px;
    margin-top: 5px; 
    color: red;
}
.button-pho{
    border: 1px solid rgb(0, 116, 176);
    background-color: #ffff;
    border-radius: 0.3rem;
    margin: 5px;
    padding: 5px;
    
}
.button-phoMany{
    border: 1px solid rgb(0, 116, 176);
    background-color: #ffff;
    border-radius: 0.3rem;
    margin: 5px;
    padding: 4px 0px 4px 0px ;
    width: 40%;
    
}
.refresh-button{
    width: 9%;
    background: transparent;
    margin: 10px 0px 0px 0px;
}
.button-phoMany div:hover{
    background-image: linear-gradient( to bottom right, #FFFF, rgb(230, 243, 252) , #FFFF);
}


