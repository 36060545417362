.authoring-wrapper {
  width: 100%;
  height: calc(100%);
}

.authoring-summery-wrapper {
  height: 100%;
}

.authoring-summery-result-wrapper {
  height: 43%;
}

.authoring-result-panel {
  height: calc(100% - 25px);
  border: 1px solid #bacef3;
  ;
  border-radius: 5px;
}

.authoring-summery-text-to-edit-wrapper {
  height: 15%;
}

.authoring-text-to-edit-panel {
  height: calc(100% - 25px);
}

.authoring-text-to-edit-panel>.txt-authoring-summarize {
  width: 100%;
  margin: 0;
  height: 100%;
  font-size: 14px;
  resize: none;
  border-width: 1px;
  border-radius: 5px;
  border-color: #bacef3;
  ;
}

.authoring-summery-comment-send-save-notebook-wrapper {
  height: 20%;
}

.authoring-summery-comment-send {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: calc(100% - 28px);
}

.authoring-GPT-summery-comment-send {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.authoring-command-wrapper-panel {
  background-color: #E7E6E6;
  padding: 1%;
  height: calc(100% - 25px);
  border-radius: 5px;
  border: 1px solid #bacef3;
}

.authoring-comment-panel {
  width: 73%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.authoring-comment-gtp-panel {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.authoring-comment-gtp-panel>textarea,
.authoring-comment-panel>textarea,
.authoring-gpt-comment-panel>textarea {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 14px;
  resize: none;
  /* border-width: 1px; */
  /* border-radius: 5px; */
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  /*font-weight: 600;*/
}

.authoring-send-gtp-panel {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-top: 5px;
}

.authoring-send-panel {
  width: 27%;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.authoring-send-panel>button {
  width: 90%;
  padding: 3px;
}


.no-margin-bottom {
  margin-bottom: 0 !important;
}


/* Messages */
.messages-panel img.img-circle {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.medium-image {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.img-circle {
  border-radius: 50%;
}

.messages-panel {
  width: 100%;
  height: calc(100vh - 150px);
  min-height: 460px;
  background-color: #fbfcff;
  display: inline-block;
  border-top-left-radius: 5px;
  margin-bottom: 0;
}

.messages-panel img.img-circle {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.messages-panel .tab-content {
  border: none;
  background-color: transparent;
}

.message-body {
  background-color: #fbfcff;
  height: 100%;
  width: calc(100% - 305px);
  float: right;
}

.message-body .message-top {
  display: inline-block;
  width: 100%;
  position: relative;
  min-height: 53px;
  height: auto;
  background-color: #fff;
  border-bottom: 1px solid rgba(205, 211, 237, 0.5);
}

.message-body .message-top .new-message-wrapper {
  width: 100%;
}

.message-body .message-top .new-message-wrapper>.form-group {
  width: 100%;
  padding: 10px 10px 0 10px;
  height: 50px;
}

.message-body .message-top .new-message-wrapper .form-group .form-control {
  width: calc(100% - 50px);
  float: left;
}

.message-body .message-top .new-message-wrapper .form-group a {
  width: 40px;
  padding: 6px 6px 6px 6px;
  text-align: center;
  display: block;
  float: right;
  margin: 0;
}

.message-body .message-top>.btn {
  height: 53px;
  line-height: 53px;
  padding: 0 20px;
  float: right;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0;
  font-size: 15px;
  opacity: 0.9;
}

.message-body .message-top>.btn:hover,
.message-body .message-top>.btn:focus,
.message-body .message-top>.btn.active {
  opacity: 1;
}

.message-body .message-top>.btn>i {
  margin-right: 5px;
  font-size: 18px;
}

.new-message-wrapper {
  position: absolute;
  max-height: 400px;
  top: 53px;
  background-color: #fff;
  z-index: 105;
  padding: 20px 15px 30px 15px;
  border-bottom: 1px solid #cfdbe2;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.25);
  transition: 0.5s;
  display: none;
}

.new-message-wrapper.closed {
  opacity: 0;
  max-height: 0;
}

.message-chat {
  width: 100%;
  overflow: hidden;
}

.chat-body {
  width: calc(100%);
  height: calc(100% - 2px);
  /* height: 100%; */
  background-color: #fbfcff;
  /* margin-bottom: 30px; */
  padding: 5px 5px 5px 5px;
  overflow-y: scroll;
  margin: 1px 0px;
  border-radius: 5px;
}

/* .chat-body::-webkit-scrollbar {
  display: none;
  width: 0 !important
} */

.message {
  position: relative;
  width: 100%;
}

.message br {
  clear: both;
}

.message .message-body {
  position: relative;
  /* width: auto; */
  width: 100%;
  max-width: calc(100% - 60px);
  float: left;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dbe3e8;
  margin: 0 10px 10px 10px;
  /* color: #788288; */
  color: #212529;
}

.message:after {
  content: "";
  position: absolute;
  top: 11px;
  left: 38px;
  float: left;
  z-index: 100;
  border-top: 10px solid transparent;
  border-left: none;
  border-bottom: 10px solid transparent;
  border-right: 13px solid #fff;
}

.message:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 37px;
  float: left;
  z-index: 99;
  border-top: 11px solid transparent;
  border-left: none;
  border-bottom: 11px solid transparent;
  border-right: 13px solid #0074B0;
}

.message .medium-image {
  float: left;
  margin-left: 5px;
}

.message .message-info {
  width: 100%;
  height: 15px;
}

.message .message-info>h5>i {
  font-size: 11px;
  font-weight: 700;
  margin: 0 2px 0 0;
  color: #a2b8c5;
}

.message .message-info>h5 {
  color: #a2b8c5;
  margin: 5px 0 0 0;
  font-size: 12px;
  float: right;
  padding-right: 10px;
}

.message .message-info>h4 {
  font-size: 14px;
  font-weight: 600;
  margin: 7px 13px 0 10px;
  color: #65addd;
  float: left;
}

.message hr {
  margin: 4px 2%;
  width: 96%;
  opacity: 0.75;
}

.message .message-text {
  text-align: left;
  padding: 3px 5px 3px 5px;
  font-size: 14px;
  overflow-x: auto;
  scrollbar-width: thin;
}

.message .message-text::-webkit-scrollbar {
  height: 10px !important
}

.message.my-message .message-body {
  float: right;
  margin: 0 10px 10px 10px;
}

.message.my-message:after {
  content: "";
  position: absolute;
  top: 11px;
  left: auto;
  right: 38px;
  float: left;
  z-index: 100;
  border-top: 10px solid transparent;
  border-left: 13px solid #fff;
  border-bottom: 10px solid transparent;
  border-right: none;
}

.message.my-message:before {
  content: "";
  position: absolute;
  top: 10px;
  left: auto;
  right: 37px;
  float: left;
  z-index: 99;
  border-top: 11px solid transparent;
  border-left: 13px solid #dbe3e8;
  border-bottom: 11px solid transparent;
  border-right: none;
}

.message.my-message .medium-image {
  float: right;
  margin-left: 5px;
  margin-right: 5px;
}

.message.my-message .message-info>h5 {
  float: left;
  padding-left: 5px;
  padding-right: 0;
}

.message.my-message .message-info>h4 {
  float: right;
}

.message.info .message-body {
  /* background-color: #0074B0; */
  border: 1px solid #0074B0;
  color: #000000;
}

/* .message.info:after,
.message.info:before {
  border-right: 13px solid #0074B0;
} */

.message.success .message-body {
  background-color: #0ec8a2;
  border: 1px solid #0ec8a2;
  color: #fff;
}

.message.success:after,
.message.success:before {
  border-right: 13px solid #0ec8a2;
}

.message.warning .message-body {
  background-color: #ff9e2a;
  border: 1px solid #ff9e2a;
  color: #fff;
}

.message.warning:after,
.message.warning:before {
  border-right: 13px solid #ff9e2a;
}

.message.danger .message-body {
  background-color: #f95858;
  border: 1px solid #f95858;
  color: #fff;
}

.message.danger:after,
.message.danger:before {
  border-right: 13px solid #f95858;
}

.message.dark .message-body {
  /* background-color: #314557; */
  border: 1px solid #314557;
  color: #fff;
}

.message.dark:after,
.message.dark:before {
  border-right: 13px solid #314557;
}

.message.info .message-info>h4,
.message.success .message-info>h4,
.message.warning .message-info>h4,
.message.danger .message-info>h4,
.message.dark .message-info>h4 {
  color: #fff;
}

.message.info .message-info>h5,
.message.info .message-info>h5>i,
.message.success .message-info>h5,
.message.success .message-info>h5>i,
.message.warning .message-info>h5,
.message.warning .message-info>h5>i,
.message.danger .message-info>h5,
.message.danger .message-info>h5>i,
.message.dark .message-info>h5,
.message.dark .message-info>h5>i {
  color: #a2b8c5;
  opacity: 0.9;
}





@media screen and (max-width: 767px) {
  .messages-panel {
    min-width: 0;
    display: inline-block;
  }

  .message-body {
    width: 100%;
    margin: 20px 0;
    border: 1px solid #dce2e9;
    background-color: #fff;
  }

  .message .message-body {
    max-width: calc(100% - 60px);
  }

  .message-body .chat-body {
    background-color: #fff;
    width: 100%;
  }

  .message-body .message-top>.btn {
    border-radius: 0;
    width: 100%;
  }

}

.z-index-authoring,.z-index-prompt-templete-list {
  z-index: 10004 !important;
}

.z-index-authoring-backdrop, .z-index-z-index-prompt-templete-list-backdrop {
  z-index: 10003 !important;
}

.authoring-command-dropdown {
  width: 100%;
  border-radius: 3px;
  /* border-width: 1px; */
  padding: 3px;
  font-size: 14px;
  margin: 0px 0px 5px 0px;
  /* border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133)); */
  border: none;
}

.authoring-summery-comment-save-notebook {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 6.5%;
}

.message-text>table {
  margin-top: 10px;
  font-size: 0.9em;
  /* min-width: 400px; */
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
  width: 100%;
}

.message-text>table>thead tr {
  color: #ffffff;
  text-align: center;
  font-weight: bold;
}

.message-text>table,
.message-text>table th,
.message-text>table td {
  border: 1px solid #A9A9A9;
  border-collapse: collapse;
}

.message-text>table th,
.message-text>table td {
  padding: 10px 10px;
  color: #000000;
}

.message-text>table th {
  text-align: center;
}

.authoring-gpt-body {
  display: flex;
  flex-direction: row;
}

.authoring-gpt-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  border: 1px solid #d3d3d3;
  padding: 5px 5px;
  z-index: 1001;
  right: 0;
  background: #fff;
  overflow-y: auto;
  width: 100%;
}

.authoring-gpt-left {
  width: 50%;
  height: 100%;
  padding: 2px;
  padding-right: 5px;
  text-align: left;
}

.authoring-gpt-right {
  width: 50%;
  height: 100%;
  padding: 2px;
  padding-left: 5px;
  text-align: left;
}

.authoring-gpt-summery-text-to-edit-wrapper {
  height: 72%;
}

.authoring-gpt-text-to-edit-panel {
  height: calc(100% - 62px);
}


.authoring-gpt-summery-comment-send-save-notebook-wrapper {
  height: 28%;
}

.authoring-gpt-command-dropdown {
  width: 250px;
  border-radius: 3px;
  border-width: 1px;
  padding: 3px;
  font-size: 14px;
  margin: 5px 0px 0px 0px;
  border: none;
  font-weight: 600;
}

div.authoring-gpt-command-dropdown-panel-wrapper>div.authoring-gpt-summery-comment>div>select>option {
  font-weight: 600;
}

.authoring-gpt-summery-comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  margin-bottom: 5px;
}

.authoring-gpt-summery-comment>textarea,
.authoring-gpt-text-to-edit-panel>textarea,
.authoring-gpt-summery-note-send>textarea,
.authoring-gpt-summery-citation-send>textarea {
  width: 100%;
  margin: 0;
  height: 100%;
  font-size: 14px;
  resize: none;
  border-width: 1px;
  border-radius: 5px;
  border: 1px solid #bacef3;
  font-weight: 600;
}

.authoring-gpt-summery-citation-send {
  border-width: 1px;
  border-radius: 5px;
  border: 1px solid #bacef3;
}

.authoring-gpt-summery-citation-send>textarea {
  border: none
}

.authoring-gpt-summery-note-wrapper {
  height: 20%;
}

.authoring-gpt-summery-note-send {
  height: calc(100% - 25px);
}

.authoring-gpt-summery-result-wrapper {
  height: 64%;
}

.authoring-gpt-result-panel {
  height: calc(100% - 25px);
  border: 1px solid #bacef3;
  border-radius: 5px;
  font-weight: 600;
}

.authoring-gpt-summery-citation-wrapper {
  height: 20%;
}

.authoring-gpt-summery-citation-send {
  height: calc(100% - 25px);
  display: flex;
  flex-direction: column;
}

.authoring-gpt-summery-comment-save-notebook {
  height: 17%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: #E7E6E6;
  border-radius: 5px;
  border: 1px solid #bacef3;
}

.authoring-gpt-popup-transition>.modal-content>div.modal-header {
  border-bottom: none;
}

.authoring-gpt-header {
  text-align: center;
  width: 100%;
  margin-bottom: 2px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 3px;
}

.authoring-gpt-body {
  height: calc(100% - 58px);
}

.authoring-summery-note-wrapper {
  height: 15%;
}

.authoring-summery-note-send {
  height: calc(100% - 25px);
}

.authoring-result-label>span,
.authoring-text-to-edit-label>span,
.authoring-command-label>span,
.authoring-note-label>span,
.authoring-text-to-edit-label>.authoring-all-text-select>span {
  color: #203864;
  font-size: 15px;
}

.authoring-summery-note-send>textarea {
  width: 100%;
  margin: 0;
  height: 100%;
  font-size: 14px;
  resize: none;
  border-width: 1px;
  border-radius: 5px;
  border-color: #bacef3;
}

.btn-authoring-reset {
  cursor: pointer;
  background: #0074B0 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  padding: 4px 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 24px;
  color: #FFFFFF;
  font-size: 14px;
}

.btnReset {
  margin: 0 auto;
  cursor: pointer;
  background: #0074B0 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  padding: 4px 5px;
  width: 140px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 24px;
  color: #FFFFFF;
}

.btnReset>svg,
.btn-authoring-reset>svg {
  margin-right: 5px;
}

.btnReset>span {
  margin-top: 2px;
}

/* .btnReset>span,
.btn-authoring-reset>span {
  margin-top: 2px;
} */

.authoring-summery-result-wrapper,
.authoring-summery-text-to-edit-wrapper,
.authoring-summery-comment-send-save-notebook-wrapper,
.authoring-summery-note-wrapper {
  margin-bottom: 2px;
}

.authoring-text-to-edit-label,
.authoring-note-label,
.authoring-gpt-text-to-edit-label,
.authoring-gpt-note-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.authoring-gpt-text-to-edit-label,
.authoring-gpt-note-label,
.authoring-gpt-command-label,
.authoring-gpt-result-label,
.authoring-gpt-citation-label {
  color: #203864;
  font-size: 15px;
  font-weight: 600;
}

.authoring-gpt-result-label,
.authoring-gpt-text-to-edit-label {
  height: 26px;
}

.btn-authoring-gpt {
  width: 150px !important;
  font-weight: 500;
  margin-right: 0 !important;
}

.btn-authoring-summery-gpt {
  width: 200px !important;
  font-weight: 500;
  margin: 0 !important;
  margin-top: 5px !important;
}

.btn-like-expandAll {
  margin-bottom: 1px;
  height: 24px;
  margin-top: 1px;
}

.btn-like-expandAll>img {
  width: 12px;
}

.btn-like-expandAll:hover {
  background: #c5c5c5;
}

.authoring-all-text-select {
  display: flex;
  margin-left: 15px;
  margin-right: auto;
  margin-bottom: 1px;
}

.authoring-all-text-select>span {
  margin-left: 3px
}

.authoring-all-text-select>input {
  margin-bottom: 3.5px;
}

.message-show-more-less {
  color: #0074B0;
  font-size: "14px";
  font-weight: "bold";
  text-align: center;
  margin-right: 25%;
}

.message-show-more-less:hover {
  cursor: pointer;
  text-decoration: underline;
}

.authoring-summery-btn-gpt-restart {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  /* width: 25%; */
  align-items: flex-end;
}

.authoring-summery-citation-seprater {
  border-left: 1px solid #203864;
  margin: 0px 5px;
}

.authoring-gtp-add-to-command {
  display: flex;
  flex-direction: column;
  width: 115px;
}


@media screen and (min-height: 495px) and (max-height: 599px) {


  .authoring-summery-result-wrapper {
    height: 35.5%;
  }

  .authoring-summery-comment-send-save-notebook-wrapper {
    height: 27%;
  }

  .authoring-send-panel>button {
    width: 95%;
    padding: 3px;
    height: 18px;
  }

  .add-to-command-user-template {
    font-size: 13px;
  }

  .authoring-gpt-summery-result-wrapper {
    height: 64%;
  }

  .authoring-gpt-summery-text-to-edit-wrapper {
    height: 70%;
  }

  .authoring-gpt-summery-comment-send-save-notebook-wrapper {
    height: 30%
  }

  .authoring-gtp-add-to-command {
    width: 160px;
  }

  .authoring-gpt-summery-comment-save-notebook {
    height: 15.5%;
  }
}

@media screen and (min-height: 600px) and (max-height: 699px) {

  .authoring-summery-result-wrapper {
    height: 35.5%;
  }

  .authoring-summery-comment-send-save-notebook-wrapper {
    height: 27%;
  }

  .authoring-send-panel>button {
    width: 95%;
  }

  .authoring-gpt-summery-result-wrapper {
    height: 66%;
  }

  .authoring-gpt-summery-text-to-edit-wrapper {
    height: 72%;
  }

  .authoring-gpt-summery-comment-send-save-notebook-wrapper {
    height: 31%;
  }

  .authoring-gtp-add-to-command {
    width: 160px;
  }

}

@media screen and (min-height: 700px) and (max-height: 799px) {

  .authoring-summery-result-wrapper {
    height: 35.5%;
  }

  .authoring-summery-comment-send-save-notebook-wrapper {
    height: 27%;
  }

  .authoring-send-panel>button {
    width: 95%;
  }

  .authoring-gpt-summery-result-wrapper {
    height: 66%;
  }

  .authoring-gpt-summery-comment-send-save-notebook-wrapper {
    height: 29%;
  }

  .authoring-gpt-summery-text-to-edit-wrapper {
    height: 74%;
  }

  .authoring-gtp-add-to-command {
    width: 160px;
  }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
  .authoring-gtp-add-to-command {
    width: 200px;
  }

  .authoring-gpt-command-dropdown,
  .authoring-summery-select-notebook-txt {
    width: 400px;
  }

  .btn-authoring-summery-gpt,.btnSelectNotebook,
  .btn-authoring-gpt-btn-clearall,
  .btn-authoring-gpt-btn-expandall {
    width: 200px!important
  }
}

@media screen and (min-width: 1801px) and (max-width: 1900px) {
  .authoring-gtp-add-to-command {
    width: 170px;
  }

  .authoring-gpt-command-dropdown,
  .authoring-summery-select-notebook-txt {
    width: 440px;
  }

  .btn-authoring-summery-gpt,.btnSelectNotebook,
  .btn-authoring-gpt-btn-clearall,
  .btn-authoring-gpt-btn-expandall {
    width: 200px!important
  }
}
@media screen and (min-width: 1901px) and (max-width: 2000px) {
  .authoring-gtp-add-to-command {
    width: 170px;
  }

  .authoring-gpt-command-dropdown,
  .authoring-summery-select-notebook-txt {
    width: 440px;
  }

  .btn-authoring-summery-gpt,.btnSelectNotebook,
  .btn-authoring-gpt-btn-clearall,
  .btn-authoring-gpt-btn-expandall {
    width: 210px!important
  }
}
@media screen and (min-width: 2001px){
  .authoring-gtp-add-to-command {
    width: 170px;
  }

  .authoring-gpt-command-dropdown,
  .authoring-summery-select-notebook-txt {
    width: 440px;
  }

  .btn-authoring-summery-gpt,.btnSelectNotebook,
  .btn-authoring-gpt-btn-clearall,
  .btn-authoring-gpt-btn-expandall {
    width: 210px!important
  }
}
.message-info-add-to-notes {
  text-align: center;
}

.add-to-notes-action-text,
.add-to-notebook-action-text,
.add-to-command-action-text,
.add-to-command-user-template,
.add-to-citation-action-text {
  color: #0074B0;
  text-decoration: none;
  cursor: pointer;
}

.add-to-notebook-action-text {
  margin-left: 5%;
}

.add-to-command-action-text,
.add-to-citation-action-text {
  background: #fff;
  padding: 0px 2px 0px 2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.add-to-command-gpt-action-save {
  width: 100%;
  text-align: center;
}

.add-to-command-action-save {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.add-to-notes-action-text:hover,
.add-to-notebook-action-text:hover,
.add-to-command-action-text:hover,
.add-to-command-user-template:hover,
.add-to-citation-action-text>span:hover {
  text-decoration: underline;
  color: #0074B0;
}

.authoring-gpt-command-dropdown-panel {
  width: 100%;
}

.authoring-gpt-command-dropdown-panel-wrapper {
  background-color: #E7E6E6;
  padding: 1%;
  border-radius: 5px;
  border: 1px solid #bacef3;
  height: calc(100% - 25px);
}

.authoring-gpt-summery-zoom-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.authoring-gpt-send-panel {
  width: 30%;
}

.authoring-gpt-comment-panel {
  height: calc(100% - 35px);
}



.message-info-gpt-add-to-notes {
  height: 25px !important;
}
.authoring-gpt-popup-transition> .modal-content > .modal-body> div{
  height: 100%;
}