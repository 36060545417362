@media screen and (max-width: 999px) {

    .searchSelect button,
    .searchSelect button:hover,
    .searchSelect button:focus,
    .searchSelect button:active {
        padding: 0px 0px 0px 5px;
        width: 165px;
        height: 37px;
        line-height: 36px;
    }

    .bannerImage {
        background: url(../../assets/bg-img-tabletView.png) no-repeat 0 0;
        padding: 25px 0 45px;
        position: relative;
        background-size: 100% 100%;
    }

    .search-input-wrapper-tabletView {
        font-family: 'J&J CircularTT-Medium';
    }

    .search-input-innerWrapper {
        width: 100%;
        display: flex;
        margin: auto;
        justify-content: center;
    }

    .input-box-wrapper-tabletView {
        position: relative;
        height: 37px;
        display: inline-block;
        /* width:53%; */
        width: 60%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 4px;
    }

    .input-search-box-tabletView {
        height: 37px;
        width: 100%;
        font-family: 'J&J CircularTT-Medium';
        outline: none;
        font-size: 17px;
        float: left;
        position: relative;
        color: #333;
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        padding: 1% 12% 1% 1%;
        letter-spacing: 0.02px;
        border: none;
        padding-right: 80px;
        border-radius: 4px;
        /* background: url('../../assets/search-icon.svg') no-repeat 6px 9px; */
        /* padding-left: 30px; */
        /* background-size: 17px 17px; */
    }

    /* .icon-wrapper{
        height: 37px;
    } */
    /* start */
    .icon-wrapper {
        height: 37px;
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;
        /* width: 105px; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        /* top: 11.5%; */
        background-color: transparent;
        padding: 0;
    }

    .search-icon-wrapper-mobileView {
        cursor: pointer;
        background: #0074B0 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        padding: 5%;
        height: 100%;
        margin-left: 10%;
        width: 40%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .magnifyingGlass-mobileView {
        width: 65%;
    }

    /* end */
    .fielded-search-icon-wrapper-tabletView {
        height: 30px;
        margin: auto 0;
        margin-left: 1%;
        display: flex;
        flex-direction: row;
        align-content: stretch;
        justify-content: center;
        align-items: center;
        /* padding: 0% 0.5%; */
        /*  background: white; */
        border-radius: 3px;
    }

    .img-wrapper-tabletView {
        height: 37px;
        display: flex;
        align-items: stretch;
    }

    .searchSelect ul {
        width: 143px;
    }

    .input-search-cancel-icon {
        width: 14px;
        height: 14px;
        /* margin: 0 0 0 10px; */
    }

    .suggestions-list-wrapper {
        top: 37px;
    }

    .search-button-wrapper {
        /* margin: 2.5px 0 2px 0; */
        /* margin: auto; */
    }
}

@media screen and (max-width: 599px) {
    .searchInput-container {
        margin: 2% 1%;
        position: relative;
    }

    .input-search-box-mobileView {
        width: 100%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #1DA1E5;
        /* border: 1px solid #AAA5A5; */
        border-radius: 4px;
        opacity: 1;
        /* font-size: 20px; */
        height: 40px;
        padding: 0 15% 0 1%;
        padding-right: 80px;
        color: #333;
        font-family: 'J&J CircularTT-Medium';
    }

    .suggestions-list-wrapper {
        top: -1px;
    }

    .icon-wrapper {
        height: 40px;
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;
        /* width: 105px; */
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        /* top: 11.5%; */
        background-color: transparent;
        padding: 0;
    }

    .input-search-cancel-icon {
        width: 14px;
        height: 14px;
        margin: 0;
    }

    .vline {
        border-left: 1px solid #707070;
        opacity: 0.44;
        height: 25px;
        margin: 0;
    }

    .search-icon-wrapper-mobileView {
        cursor: pointer;
        background: #0074B0 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        padding: 5%;
        height: 100%;
        margin-left: 15%;
        width: 40%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .magnifyingGlass-mobileView {
        width: 65%;
    }

    .header-sepration-line {
        /* background: #FF8000; */
        height: 2px !important;
        /* margin: 4% 2% 0% 2%; */
        /* background: #0d80b5; */
        background: rgb(0, 116, 176);
        margin: 4% 0% 0% 0%;
        opacity: 1;
    }

    .header-wrapper-mobileView {
        background: #B5EDFF;
        padding: 2% 0% 0%;
        /* background: #dbf7fd; */
        /* background: transparent linear-gradient(90deg, rgba(125, 161, 200, 0.2) 0%, rgba(122, 170, 217, 0.2) 100%) 0% 0% no-repeat padding-box;  */
        /* background: #A1D2E6; */
        /* background: #CAEDFE; */
        /* background: #E2F6FF; */
        /* background: #CEEEFF; */
    }
}

.bannerImage-MIAs {
    background: url(../../assets/bg-img-tabletView.png) no-repeat 0 0;
    padding: 25px 0 45px;
    position: relative;
    background-size: 100% 100%;
    padding-bottom: 0;
}

/* Zoom Issue Css */
@media screen and (min-width: 1000px) and (max-width: 1200px) {
    .search-input-wrapper {
        margin-left: -70%;
    }



    .clear-search-button-wrapper {
        margin-left: 58%;
    }
}

@media screen and (min-width: 1140px) and (max-width: 1220px) {
    .search-header-links-component {
        width: 15%;
    }
}

@media screen and (min-width: 1070px) and (max-width: 1140px) {
    .search-header-links-component {
        width: 16%;
    }
}

@media screen and (min-width: 1005px) and (max-width: 1070px) {
    .search-header-links-component {
        width: 17%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1005px) {
    .search-header-links-component {
        width: 18%;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
    .search-input-gen-ai-button-wrapper {
        margin-left: 0;
    }

    .bannerImage-left {
        width: 12%;
        font-size: 12px;
        margin: 0 25px;
        margin-top: -60px;
    }

    .gen-ai-answer-btn {
        width: 90px;
    }
    .input-box-wrapper {
        width: 44%;
    }
    .fielded-search-icon-wrapper {
        margin-left: 44.5%;
    }
    .clear-search-button-wrapper {
        margin-left: 49%;
    }
}
@media screen and (min-width: 1101px) and (max-width: 1200px) {
    .search-input-gen-ai-button-wrapper {
        margin-left: 0;
    }

    .bannerImage-left {
        width: 13%;
        margin: 0 25px;
        margin-top: -60px;
    }

    .gen-ai-answer-btn {
        width: 95px;
    }
    .input-box-wrapper {
        width: 47.5%;
    }
    .fielded-search-icon-wrapper {
        margin-left: 48%;
    }
    .clear-search-button-wrapper {
        margin-left: 52%;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1300px) {
    .search-input-gen-ai-button-wrapper {
        margin-left: 0;
    }
    .bannerImage-left {
        width: 17.5%;
        margin: 0px 15px;
        margin-top: -60px;
    }
    .answering-banner-message{
        margin-bottom: 10px;
    }
    .gen-ai-answer-btn {
        width: 95px;
    }
    .input-box-wrapper {
        width: 47.5%;
    }
    .fielded-search-icon-wrapper {
        margin-left: 48%;
    }
    .clear-search-button-wrapper {
        margin-left: 52%;
    }
}
@media screen and (min-width: 1301px) and (max-width: 1400px) {
    .search-input-gen-ai-button-wrapper {
        margin-left: 0;
    }
    .bannerImage-left {
        width: 16%;
        margin: 0 20px;
        margin-top: -70px;
    }
    .answering-banner-message{
        margin-bottom: 5px;
    }
    .input-box-wrapper {
        width: 47.5%;
    }
    .fielded-search-icon-wrapper {
        margin-left: 48%;
    }
    .clear-search-button-wrapper {
        margin-left: 52%;
    }
}
@media screen and (min-width: 1401px) and (max-width: 1500px) {
    .bannerImage-left {
        width: 15%;
        margin: 0 25px;
        margin-top: -60px;
    }
    .answering-banner-message{
        margin-bottom: 5px;
    }
}
@media screen and (min-width: 1501px) and (max-width: 1600px) {
    .bannerImage-left {
        width: 14%;
        margin: 0 30px;
        margin-top: -60px;
    }
}
@media screen and (min-width: 1601px) and (max-width: 1700px) {
    .bannerImage-left {
        width: 13%;
        margin: 0 30px;
        margin-top: -60px;
    }
}
@media screen and (min-width: 1701px) and (max-width: 1800px) {
    .bannerImage-left {
        width: 13%;
        margin: 0 30px;
        margin-top: -60px;
    }
}
@media screen and (min-width: 1801px) and (max-width: 1900px) {
    .bannerImage-left {
        width: 12%;
        margin: 0 50px;
        margin-top: -60px;
    }
}
@media screen and (min-width: 1901px) and (max-width: 2000px) {
    .bannerImage-left {
        width: 11%;
        margin: 0 60px;
        margin-top: -60px;
    }
}
@media screen and (min-width: 2001px) and (max-width: 2100px) {
    .bannerImage-left {
        width: 11%;
        margin: 0 80px;
        margin-top: -60px;
    }
}
@media screen and (min-width: 2101px)  {
    .bannerImage-left {
        width: 220px;
        margin: 0 4%;
        margin-top: -60px;
    }
}
.bannerMessage-tablet {
    margin-bottom: 0px !important;
    color: #000000;
    font-size: 12px;
    background-color: #ffe597;
    padding: 5px;
    top: 4%;
}

.bannerMessage {
    margin-bottom: 0px !important;
    color: #000000;
    width: 35%;
    font-size: 12px;
    position: absolute;
    right: 2%;
    background-color: #ffe597;
    padding: 5px;
    top: 8%;
    line-height: 12px;
}

div.global-feature-tile.global-feature-authoring-tile>a {
    text-decoration: none !important;
}