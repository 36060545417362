  .histogram-description {
    margin-top: 15px;
    margin-left: 20px;
  }

  .bar-diagram {
    margin: 10px 10px -10px 10px;
    text-align: center;
  }

  .slider-content {
    margin: 0px 10px 15px 10px;
  }

  .slider-content .input-range__slider {
    appearance: none;
    background: white;
    border: 2px solid #F57F20;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 0.75rem;
    margin-left: -0.45rem;
    margin-top: -0.45rem;
    outline: none;
    position: absolute;
    top: 50%;
    touch-action: none;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    box-shadow: 0 0 0 1px white;
    width: 0.75rem;
  }

  .input-range__slider:active {
    transform: scale(1.3);
  }

  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }

  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    transform: none;
  }

  .input-range__slider-container {
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  .input-range__label--min,
  .input-range__label--max {
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: 1rem;
  }

  .input-range__label-container {
    visibility: hidden;
    left: -50%;
    position: relative;
  }

  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }

  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.10rem;
    position: absolute;
    right: 0;
  }

  .input-range__track--active {
    background: #F57F20;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }

  .year-range-display-container {
    margin-top: 5px;
  }

  .selected-year-range-display {
    text-align: center;
    display: table;
    margin-left: 8px;
  }

  .background-track {
    margin-top: 7px;
    z-index: -2
  }

  .input-range-container {
    z-index: 5;
    margin-top: -3px
  }