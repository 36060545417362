.wnfy-global-features-wrapper-mobile{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: 'J&J CircularTT-Medium';
    color:#333;
    padding:2% 1%;
    margin:1% 0;
    height: 110px;
}
.wnfy-global-features-wrapper-tablet{
    display: flex;
    position: relative;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-family: 'J&J CircularTT-Medium';
    color:#333;
    padding:2% 1.5%;
    margin:0 0 1% 0;
    box-shadow: 0px 3px 6px #00000029;
    background: #fff 0% 0% no-repeat padding-box;
}
.wnfy-action-items-button-wrapper-tablet{
    border-radius: 4px;
    background: #F4F4F4;
    height: 30px;
    width: 100%; 
    border:1px solid #F4F4F4;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding:0 2%;
}
.wnfy-products-button-wrapper-tablet{
    border-radius: 4px;
    background: #F4F4F4;
    height: 30px;
    width: 100%; 
    border:1px solid #F4F4F4;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding:0 2%;
  }
  .wnfy-products-list-wrapper-tablet{
    display: flex;
    flex-direction: column;
    padding: 1%;
    position: absolute;
    background: #fff;
    color: #333;
    width: 100%;
    top:32px;
    text-align: left;
    font-size:14px;
    border : 1px solid rgba(112, 112, 112, 0.19);
    z-index:100;
    cursor: pointer;
  }
  .wnfy-dropdown-button-wrapper-tablet{
      display: flex;
      flex-direction: column;
      width: 48%;
      position: relative;
  }
  .wnfy-products-item-text{
      padding: 2% 4%;;
  }
  .wnfy-action-items-button-wrapper-mobile{
    width: 100%;
    height: 40px;
    border: 1px solid #1DA1E5;
    background-color:#F2FBFF;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding:0 2%;
    cursor: pointer;
  }
  .wnfy-dropdown-button-wrapper-mobile{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }
  .wnfy-products-list-wrapper-mobile{
    display: flex;
    flex-direction: column;
    padding: 1%;
    position: absolute;
    background: #fff;
    color: #333;
    width: 100%;
    top:42px;
    text-align: left;
    font-size:14px;
    border : 1px solid rgba(112, 112, 112, 0.19);
    z-index:100;
    cursor: pointer;
  }