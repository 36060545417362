.noAlertText{
    color: #0074B0;
    font-size: 16px;
    display: block;
    margin: 0 0 0 15px;
    font-weight: 500;
}
.manage-personal-alerts-wrapper th {
    /* color: #0074B0; */
    color: #333;
    background: none;
    width: 20%;
    font-size: 14.5px;
}
.personalAlertsLink{
    text-decoration: none;
    color: #0074B0;
    cursor: pointer;
}
.personalAlertsLink:hover{
    text-decoration: underline;
}
.personal-alerts-delete-icon{
    cursor: pointer;
}
.manage-personal-alerts-wrapper tbody tr {
    /* justify-content: space-between; */
    background: #F8F8F8 0% 0% no-repeat padding-box;
    margin: 5px 5px;
    padding: 0 5px;
}
.manage-personal-alerts-wrapper {
    list-style: none;
    padding: 0 10px;
    width: 100%;
    border-spacing: 0 5px;
    border-collapse: separate;
}
textarea:focus-visible{
    outline: none;
}
.create-alert-input-box{
    width: 80%;
    height: 33px;
    border-radius: 5px;
    border: none;
    color: #333;
    padding-left: 1%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    margin: 0 0 0 30px;
}
.create-alert-text-area{
    width: 95%;
    min-height: 85px;
    border-radius: 5px;
    border: none;
    color: #333;
    padding: 1% 0 0 1%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
}
.errorInvalidUrl{
    color: red;
    font-size: 14px;
    margin: 0 0 0 115px;
}
.personal-alert-feq-value{
    display: inline;
    margin: 0 0 0 40px;
}
.reading-list-action-text{
    /* color: #212529; */
    color: #0074B0;
    text-decoration: none;
}
.reading-list-deaction-text{
    /* color: #212529; */
    color: #000000;
    text-decoration: none;
}
.searchNameRow {
    width: 95%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.create-alert-input-box-tablet {
    width: 100%;
    height: 33px;
    border-radius: 5px;
    border: none;
    color: #333;
    padding-left: 1%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    /* margin: 0 0 0 30px; */
}
.searchText-tablet{
    width: 27%;
    font-size: 14.5px;
}
.create-alert-input-container-tablet {
    width: 100%;
}
.errorInvalidUrl-tablet{
    color: red;
    font-size: 14px;
    position: absolute;
}
.reading-list-button-wrapper-tablet{
    padding: 4%;
    text-align: center;
    /* float: right; */
}
.action-button-saperation-line-mobile {
    border-bottom: 1px solid #707070;
    opacity: 0.44;
    margin: 12px;
    width: 100%;
}
.create-alert-input-box-mobile{
    width: 95%;
    height: 33px;
    border-radius: 5px;
    border: none;
    color: #333;
    padding-left: 1%;
    background: #F8F8F8 0% 0% no-repeat padding-box;
}
.errorInvalidUrl-mobile{
    color: red;
    font-size: 14px
}
.reading-list-button-wrapper-mobile {
    padding: 4%;
}
.alert-active-features-button-mobile{
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    background: #7fb1da;
    width: 100%;
}
.alert-features-buttons-mobile{
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    background: white;
    padding: 1% 4%;
    color: #0074B0;
    cursor: pointer;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}
.alerts-active-features-button-tablet{
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    background: #7fb1da;
}
.alerts-features-buttons-tablet{
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    background: white;
    padding: 1% 4%;
    color: #0074B0;
    cursor: pointer;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}
.create-alert-fields{
    font-size: 14.5px;
}
.alert-active-features-button {
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    background: linear-gradient(252deg,#7CC7BF,#61779C);
}
.alert-features-buttons {
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    background: white;
    padding: 1% 4%;
    color: #0074B0;
    cursor: pointer;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}
.alert-features-buttons:hover {
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 4px;
    opacity: 1;
    padding: 1% 4%;
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    background: linear-gradient(252deg,#7CC7BF,#61779C);
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}
.noAlertText-mobile {
    color: #0074B0;
    font-size: 16px;
    display: block;
    font-weight: 500;
    text-align: center;
}
.actionItem-headerPart-mobile {
    font-family: 'J&J CircularTT-Medium';
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 75px;
}
@media screen and (min-width:650px) and (max-width:700px){
    .active-features-button{
        font-size: 13px;
    }
}
@media screen and (min-width:620px) and (max-width:649px){
    .active-features-button{
        font-size: 12px;
    }
}
@media screen and (min-width:600px) and (max-width:619px){
    .active-features-button{
        font-size: 11px;
    }
}