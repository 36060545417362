.doc-preview-wrapper {
    display: flex;
    flex-flow: column;
    border: 1px solid #d3d3d3;
    padding: 10px 15px;
    margin: 0 10px 10px;
    z-index: 900;
    /* position: absolute; */
    right: 0;
    background: #fff;
    /* width: 800px; */
    height: 590px;
    overflow-y: auto;
    position: fixed;
    top: 65px;
    /* top: 15%; */
    flex-direction: row;
    width: 70%;
}

.doc-preview-title-wrapper {
    font-size: 14px;
    color: #333;
    font-family: 'J&J CircularTT-Medium';
    padding: 0px 5px 5px;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.doc-preview-loader-wrapper {
    height: 634px;
    /* width: 490px; */
    align-items: center;
    justify-content: center;
    display: flex;
}

.doc-preview-pagination-wrapper {
    font-size: 14px;
    color: #333;
    font-family: 'J&J CircularTT-Medium';
    display: flex;
    width: 30%;
    justify-content: space-evenly;
    margin: 15px 0;
    cursor: pointer;
}

.doc-preview-inner-wrapper-ppt {
    border: 1px solid #d3d3d3;
    overflow-y: auto;
    height: 390px;
}

.doc-preview-inner-wrapper {
    border: 1px solid #d3d3d3;
    /* overflow-y: scroll; */
    overflow-y: auto;
    height: 475px;
    /* width: 530px; */
}

.doc-preview-inner-wrapper-answering {
    border: 1px solid #d3d3d3;
    overflow-y: auto;
    height: calc(100% - 76px);

}

.doc-preview-wrapper-left {
    width: 22%;
    margin-right: 10px;
}

.answering-doc-preview-wrapper-left {
    width: 40%;
    margin-right: 10px;
    overflow-x: auto;
}

.doc-preview-wrapper-right {
    width: 78%;
}

.answering-doc-preview-wrapper-right {
    width: 60%;
}

.doc-preview-input-box-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: left;
}

.doc-preview-input-search-box {
    width: 100%;
    height: 30px;
    border: 2px solid #d8d8d8 !important;
    font-family: 'J&J CircularTT-Medium';
    outline: none;
    font-size: 15px;
    float: left;
    position: relative;
    color: #333;
    /* box-shadow: 0px 3px 6px #00000029; */
    opacity: 1;
    border-radius: 4px;
    padding: 1% 12% 1% 1%;
    letter-spacing: 0.02px;
    /* border: none; */
    margin-top: 5px;
    padding-right: 50px;
}

.doc-preview-icon-wrapper {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    right: 0;
    top: 0;
    width: 70px;
    background-color: transparent;
    padding: 0;
    height: 30px;
    margin-top: 5px;
    /* justify-content: space-around; */
    /* padding-left: 7px; */
    /* right: 1px; */
}

.doc-preview-input-search-cancel-icon {
    cursor: pointer;
    width: 12px;
    height: 12px;
    margin: 0 6%;
}

.doc-preview-input-box-title-wrapper {
    text-align: left;
}

.doc-preview-search-icon-wrapper-webView {
    cursor: pointer;
    background: #0074B0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    padding: 5%;
    height: 100%;
    margin-left: 10%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.doc-preview-warning-msg {
    text-align: left;
    color: red;
    margin-left: 2px;
    font-size: 14px;
}

.doc-preview-search-results-title {
    margin-top: 10px;
    text-align: left;
}

.doc-preview-keword-title {
    margin: 5px 0px;
    text-align: left;
    display: flex;
}

.doc-preview-keword-title>img {
    margin-bottom: 4px;
    margin-right: 10px;
}

.doc-preview-search-results-split {
    border-bottom: 2px solid #d8d8d8;
    width: 98%;
    margin-top: 10px;
}

.apexcharts-menu-icon {
    display: none;
}

#chart>div>div>svg>foreignObject {
    display: none;
}

.doc-preview-page-count-results-wrapper {
    max-height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
}

.doc-preview-keword-color-box {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin-right: 3px;

}

.doc-preview-OrderBy-wrapper {
    display: flex;
    font-size: 14px;
}

.doc-preview-OrderBy-wrapper>label {
    margin: 0 5px;
}

.doc-preview-OrderBy-wrapper>label>input[type="radio"] {
    margin: 1px 2px;
    vertical-align: middle;
}

.showSearch {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -72px -24px;
    /* margin-right: 50px !important; */
}

.documentViewerbutton:hover,
.documentzoomPercentagebutton:hover {
    background-color: #F0F0F0;

}

.documentViewerbutton:active {
    background-color: #E0E0E0;
}

.documentViewerbutton {
    width: 25px;
    height: 25px;
    margin: auto 1px;
    background-color: #fff;
}

.doc-preview-controle-wrapper {
    display: flex;
    justify-content: space-between;
}

.doc-preview-search-zoom-wrapper {
    margin: auto 0;
}

.collapse-button {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: 0px -72px;
}

.doc-preview-zoom-wrapper {
    display: flex;
    margin: auto 0;
}

.zoomIn {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -24px -24px;
}

.zoomOut {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -48px -24px;
}


.doc-preview-zoom-inner {
    width: 100px;
}

.documentzoomPercentagebutton {
    padding: 5px;
    background-color: #fff;
    text-align: center;
}




.slider:hover {
    opacity: 1;
    /* Fully shown on mouse-over */
}

.slider {
    -webkit-appearance: none;
    /* Override default CSS styles */
    appearance: none;
    width: 100%;
    /* Full-width */
    height: 7px;
    /* Specified height */
    background: #e6e6e6;
    /* Grey background */
    outline: none;
    /* Remove outline */
    /* opacity: 0.7;  Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s;
    /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border: 1px solid #ccc;
    /* box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%); */
    border-radius: 30px;
    margin-top: 11px;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Override default look */
    appearance: none;
    width: 15px;
    /* Set a specific slider handle width */
    height: 15px;
    /* Slider handle height */
    border-radius: 30px;

    cursor: pointer;
    /* Cursor on hover */
    background: #fff;
    border: 1px solid #ccc;
    /* transform: translate3d(-50%, -50%, 0); */
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%);
}


.slider::-moz-range-thumb {
    width: 15px;
    /* Set a specific slider handle width */
    height: 15px;
    /* Slider handle height */
    border-radius: 30px;

    cursor: pointer;
    /* Cursor on hover */
    background: #fff;
    border: 1px solid #ccc;
    /* transform: translate3d(-50%, -50%, 0); */
    box-shadow: 0 1px 3px rgb(0 0 0 / 40%), 0 -1px 3px rgb(0 0 0 / 40%);
}


.zoomFitWidth {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -48px -72px;
}

.zoomFitPage {
    background-image: url(../../assets/document-preview-icons.png);
    background-repeat: no-repeat;
    background-position: -72px -72px;
}

.react-pdf__Page__canvas,
.react-pdf__Page__textContent {
    margin: 0 auto;
}

/* Zoom Issue Css */
@media screen and (min-height: 740px) {
    .doc-preview-wrapper {
        top: 150px;
    }
}

@media screen and (min-height: 716px) and (max-height: 740px) {
    .doc-preview-wrapper {
        top: 135px;
    }
}

@media screen and (min-height: 691px) and (max-height: 715px) {
    .doc-preview-wrapper {
        top: 110px;
    }
}

@media screen and (min-height: 676px) and (max-height: 690px) {
    .doc-preview-wrapper {
        top: 100px;
    }
}

@media screen and (min-height: 660px) and (max-height: 675px) {
    .doc-preview-wrapper {
        top: 90px;
    }
}

@media screen and (min-height: 611px) and (max-height: 659px) {
    .doc-preview-wrapper {
        top: 60px;
    }
}

@media screen and (min-height: 600px) and (max-height: 610px) {
    .doc-preview-wrapper {
        top: 110px;
    }
}

@media screen and (min-height: 500px) and (max-height: 610px) {
    .doc-preview-inner-wrapper {
        height: 360px;
    }

    .doc-preview-inner-wrapper-ppt {
        height: 330px;
    }

    .doc-preview-wrapper {
        height: 500px;
    }
}

@media screen and (min-height: 450px) and (max-height: 500px) {
    .doc-preview-inner-wrapper {
        height: 320px;
    }

    .doc-preview-inner-wrapper-ppt {
        height: 290px;
    }

    .doc-preview-wrapper {
        height: 420px;
    }

    .doc-preview-loader-wrapper {
        align-items: baseline;
    }
}

@media screen and (min-height: 385px) and (max-height: 450px) {
    .doc-preview-inner-wrapper {
        height: 250px;
    }

    .doc-preview-inner-wrapper-ppt {
        height: 220px;
    }

    .doc-preview-wrapper {
        height: 340px;
    }

    .doc-preview-loader-wrapper {
        align-items: baseline;
    }
}

@media screen and (min-height: 355px) and (max-height: 385px) {
    .doc-preview-inner-wrapper {
        height: 220px;
    }

    .doc-preview-inner-wrapper-ppt {
        height: 190px;
    }

    .doc-preview-wrapper {
        height: 320px;
    }

    .doc-preview-loader-wrapper {
        align-items: baseline;
    }
}

@media screen and (max-height: 355px) {
    .doc-preview-inner-wrapper {
        height: 200px;
    }

    .doc-preview-inner-wrapper-ppt {
        height: 200px;
    }

    .doc-preview-wrapper {
        height: 300px;
    }

    .doc-preview-loader-wrapper {
        align-items: baseline;
    }
}

@media screen and (min-width: 1240px) and (max-width: 1300px) {
    .doc-preview-OrderBy-wrapper {
        font-size: 13px;
    }
}

@media screen and (min-width: 1180px) and (max-width: 1240px) {
    .doc-preview-OrderBy-wrapper {
        font-size: 12px;
    }

    .doc-preview-wrapper-left,
    .answering-doc-preview-wrapper-left {
        font-size: 14px;
    }
}

@media screen and (min-width: 1125px) and (max-width: 1180px) {
    .doc-preview-OrderBy-wrapper {
        font-size: 11px;
    }

    .doc-preview-wrapper-left,
    .answering-doc-preview-wrapper-left {
        font-size: 13px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1125px) {
    .doc-preview-OrderBy-wrapper {
        font-size: 10px;
    }

    .doc-preview-wrapper-left,
    .answering-doc-preview-wrapper-left {
        font-size: 12px;
    }
}

.nlp-doc-preview-loader-wrapper {
    /* height: 634px; */
    /* width: 490px; */
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

/* .react-pdf__Page__canvas{
    margin: 0 auto;
}
.react-pdf__Page__textContent{
    margin: 0 auto;
}
.react-pdf__Page__textContent{
    top: 49% !important;
} 

.react-pdf__Page__svg {
    margin: 0 auto;
  }*/

/* .react-pdf__Page__canvas {
    position: absolute !important;
     top: 50% !important;
     left: 50% !important;
     transform: translate(-50%, 0%) !important;
 }
 
 .react-pdf__Page__textContent {
     position: absolute !important;
     top: 50% !important;
     left: 50% !important;
     transform: translate(-50%, 0%) !important;
 } */
.btnExportText {
    width: 100%;
    margin: 5px 0px;
    cursor: pointer;
    background: #0074B0 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    color: #FFFFFF;
}

.txtExportText {
    width: 100%;
    margin: 5px 0px;
    height: 200px;
}

.doc-preview-with-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cbm-tablet-Highlight {
    margin: 0 !important;
    padding-left: 5px !important;
    cursor: pointer;
}

.nlp-dp-chart-outer-wrapper {
    font-family: 'J&J CircularTT-Medium';
}

.nlp-dp-chart-inner-wrapper {
    color: #333;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    flex-direction: column;
}

.nlp-dp-chart-inner-wrapper-desktop {
    color: #333;
    padding: 2px 0% 0 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    flex-direction: column;

}

.nlp-dp-chart-main-header-desktop {
    color: white;
    font-size: 14px;
    cursor: default;
}

.nlp-dp-chart-inner-wrapper-desktop {
    border-bottom: 1px solid #EFEFEF;
    height: 24px;
    padding: 4px 4px;
    text-align: left;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #0074B0 0% 0% no-repeat padding-box;
}

.nlp-dp-chart-more-wrapper {
    float: right;
    padding: 0.0% 0.3% 0 0.0%;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    /* padding: 0px 30px; */
    /* padding: 0% 1.5% 0% 0%; */
    flex-direction: row;
    /* width: 20%; */
    width: 25%;
    justify-content: space-between;
}

.nlp-dp-chart-more-wrapper {
    width: auto;
}


.nlp-dp-chart-more-wrapper img {
    width: 11px;
    height: 14px;
}


.nlp-dp-chart-content-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2px;
    overflow-y: auto;
    max-height: 264px;
    /* height: 264px; */
}

.nlp-dp-no-chart-content-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2px;
    overflow-y: auto;
    max-height: 40px;
    height: 40px;
}

.rd-document-margin-info-wrapper {
    margin-top: 10px;
    border: 2px solid #EFEFEF;
    padding: 5px;
    border-radius: 5px;
}

.doc-preview-chart-results-split {
    border-bottom: 2px solid #d8d8d8;
    margin-top: 10px;
}

.answering-doc-preview-wrapper-tablet-left {
    width: 50%;
    margin-right: 10px;
    position: absolute;
    z-index: 1000;
    background: #fff;
    height: calc(100vh - 23px);
    border: 2px solid #EFEFEF;
    padding: 10px;

}

.answering-doc-preview-wrapper-tablet-right {
    width: 100%;
}
.answering-doc-preview-wrapper-tablet-close{
   
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
    /* visibility: hidden; */
}
.answering-doc-preview-wrapper-tablet-open{
    visibility: visible;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}
@keyframes slide-in {
    0% { -webkit-transform: translateX(-108%); }
    100% { -webkit-transform: translateX(0%); }
 }
 
 @-webkit-keyframes slide-in {
      0% {transform: translateX(-108%); }
     100% { transform: translateX(0%); }
 }
 
 @keyframes slide-out {
     0% { transform: translateX(0%); }
     100% { transform: translateX(-108%); }
 }
 
 @-webkit-keyframes slide-out {
     0% { -webkit-transform: translateX(0%); }
     100% { -webkit-transform: translateX(-108%); }
 }
 .doc-preview-title-wrapper-span{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 90%;
    min-height: 20px;
 }