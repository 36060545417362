.gen-ai-document-preview-wrapper {
    display: flex;
    flex-flow: column;
    border: 1px solid #d3d3d3;
    padding: 10px 15px;
    z-index: 1001;
    right: 0;
    background: #fff;
    overflow-y: auto;
    top: 65px;
    flex-direction: column;
    width: 100%;
}
.gen-ai-document-window-preview-wrapper {
    display: flex;
    flex-flow: column;
    padding: 10px 10px;
    z-index: 1001;
    right: 0;
    background: #fff;
    overflow-y: auto;
    flex-direction: column;
    width: 100%;
}
.gen-ai-document-window-preview-wrapper{
    height: 100vh;
}
.gen-ai-document-preview-wrapper {
    height: calc(100vh - 10px);
}

.gen-ai-document-preview-title-wrapper {
    font-size: 14px;
    color: #333;
    font-family: 'J&J CircularTT-Medium';
    padding: 0px 5px 5px;
    text-align: left;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.gen-ai-document-preview-title-a {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* width: 90%; */
    min-height: 20px;
}
.gen-ai-document-window-preview-panel{
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.gen-ai-document-preview-panel {
    height: calc(100% - 26px);
}

.gen-ai-document-preview-error-panel {
    height: calc(100% - 26px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
}